import { useRouter } from "next/router"
import { useQueryClient } from "react-query"

import * as SessionStore from "@/domains/session/store"

export function useLogout() {
  const router = useRouter()
  const client = useQueryClient()

  return async () => {
    await router.replace("/")
    SessionStore.reset()
    client.invalidateQueries()
  }
}
