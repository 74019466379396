import { i18n } from "@lingui/core"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { Currency, CurrencyCode } from "@/domains/currencies/model"

export type Rates = Record<CurrencyCode, number>

interface Money<Currency extends CurrencyCode> {
  amount: number
  currency: Currency
}

export type Eur = number & { readonly Uuid: unique symbol }

export const Eur = pipe(
  D.number,
  D.refine((value): value is Eur => true, "Eur")
)

export const add = (a: Eur, b: Eur): Eur => (a + b) as Eur

export const currencyRatesOf = <C extends CurrencyCode>(
  currencies: Array<Currency<C>>
): Rates =>
  currencies.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.isoCode4217]: curr.rateOneEuro,
    }),
    {}
  )

export function create<C extends CurrencyCode>({
  eur,
  currency,
  rate,
}: {
  eur: Eur
  currency: C
  rate: number
}): Money<C> {
  return { amount: eur * rate, currency }
}

export function display<Currency extends CurrencyCode>(
  money: Money<Currency>,
  options?: Intl.NumberFormatOptions
): string {
  return i18n.number(money.amount, {
    style: "currency",
    currency: money.currency,
    currencyDisplay: "narrowSymbol",
    maximumFractionDigits: 0,
    ...options,
  })
}

export const sum =
  <C extends CurrencyCode>(currency: C) =>
  (monies: Array<Money<C>>): Money<C> =>
    monies.reduce(
      (prev, curr) => ({
        amount: prev.amount + curr.amount,
        currency: prev.currency,
      }),
      { amount: 0, currency }
    )
