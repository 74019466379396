import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import { VariantProps } from "@stitches/react"
import { ComponentProps, ReactNode, forwardRef } from "react"
import CaretIcon from "remixicon-react/ArrowDownSLineIcon"
import CheckIcon from "remixicon-react/CheckLineIcon"

import { CSS, keyframes, styled } from "@/stitches"
import { Text } from "@/ui/text"

import { ButtonBase } from "./button"

// -----------------------------------------------------------------------------
// Primitives
// -----------------------------------------------------------------------------

const scaleIn = keyframes({
  "0%": { opacity: 0, transform: "scale(0.8)" },
  "100%": { opacity: 1, transform: "scale(1)" },
})

const TriggerButtonStyled = styled(ButtonBase, {
  borderWidth: 1,
  borderColor: "$accent9",
  gap: "$2",
  py: "calc(1em * var(--density-factor))",
  px: "calc(1.5em * var(--density-factor))",
  variants: {
    variant: {
      none: {},
    },
    size: {
      sm: { fontSize: "$sm" },
      md: {},
    },
    density: {
      dense: {
        "--density-factor": 0.75,
      },
      normal: {
        "--density-factor": 1,
      },
    },
  },
  defaultVariants: {
    variant: "none",
    size: "md",
    density: "normal",
  },
})

const CaretStyled = styled(CaretIcon, {
  transition: "transform 120ms ease-out",

  "[data-state=open] &": {
    transform: "rotate(180deg)",
  },
})

const ItemStyled = styled(DropdownMenuPrimitive.Item, {
  position: "relative",
  transition: "background-color 120ms",
  outline: "none",
  display: "flex",
  alignItems: "center",
  mr: "auto",
  width: "100%",
  px: "calc(1.5em * var(--density-factor))",
  py: "calc(1em * var(--density-factor))",
  "&:focus": {
    bgColor: "$gray4",
  },
  "&[data-state=checked]": {
    bgColor: "$accent9",
    color: "$accent1",
    fontWeight: "$bold",
  },
  variants: {
    inactive: {
      true: {},
      false: {
        cursor: "pointer",
        "&:not([data-state=checked]):hover": {
          bgColor: "$gray6",
        },
      },
    },
  },
  defaultVariants: {
    inactive: false,
  },
})

const ContentStyled = styled(DropdownMenuPrimitive.Content, {
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  bgColor: "$popoverBg",
  border: "1px solid $popoverBorder",
  color: "$gray12",
  py: "0.5em",
  boxShadow: "$shadow1",
  transformOrigin: "var(--radix-dropdown-menu-content-transform-origin)",
  animation: `${scaleIn} 120ms ease-out`,
  zIndex: "$dropdown",

  variants: {
    size: {
      sm: { fontSize: "$sm" },
      md: {},
    },
    density: {
      dense: { "--density-factor": 0.75 },
      normal: { "--density-factor": 1 },
    },
  },
  defaultVariants: {
    size: "md",
    density: "normal",
  },
})

export const SeparatorStyled = styled(DropdownMenuPrimitive.Separator, {
  height: "1px",
  bgColor: "$divider",
  my: "$2",
})

const RadioItemStyled = styled(DropdownMenuPrimitive.RadioItem, ItemStyled)

const ItemIndicatorStyled = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: "absolute",
  left: 12,
})

const RadioGroupStyled = styled(DropdownMenuPrimitive.RadioGroup, {
  variants: {
    showIndicator: {
      true: {
        [`& ${RadioItemStyled}`]: {
          pl: "3em",
        },
      },
      false: {
        [`& ${ItemIndicatorStyled}`]: {
          display: "none !important",
        },
      },
    },
  },
  defaultVariants: {
    showIndicator: false,
  },
})

// -----------------------------------------------------------------------------
// Composed components
// -----------------------------------------------------------------------------

type TriggerButtonProps = VariantProps<typeof TriggerButtonStyled> & {
  children: ReactNode
  label?: string
  css?: CSS
}

function TriggerButton({ children, label, ...props }: TriggerButtonProps) {
  return (
    <DropdownMenuPrimitive.Trigger asChild>
      <TriggerButtonStyled {...props}>
        {label && (
          <Text as="p" size="xs" color="muted">
            {label}
          </Text>
        )}
        {children}
        <Caret size="1.5em" />
      </TriggerButtonStyled>
    </DropdownMenuPrimitive.Trigger>
  )
}

function Content(props: ComponentProps<typeof ContentStyled>) {
  return (
    <DropdownMenuPrimitive.Portal>
      <ContentStyled {...props} />
    </DropdownMenuPrimitive.Portal>
  )
}

const RadioItem = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof RadioItemStyled>
>(function RadioItemForwarded({ children, ...props }, ref) {
  return (
    <RadioItemStyled {...props} ref={ref}>
      <ItemIndicatorStyled>
        <CheckIcon size="1.5em" />
      </ItemIndicatorStyled>
      {children}
    </RadioItemStyled>
  )
})

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const Root = styled(DropdownMenuPrimitive.Root)
export const Trigger = styled(DropdownMenuPrimitive.Trigger)
export const Item = ItemStyled
export const Label = styled(DropdownMenuPrimitive.Label)
export const Group = styled(DropdownMenuPrimitive.Group)
export const Caret = CaretStyled
export const RadioGroup = RadioGroupStyled

export { TriggerButton, Content, RadioItem }
