import { styled } from "@/stitches"

export const Prose = styled("div", {
  lineHeight: "$normal",

  variants: {
    color: {
      primary: {
        color: "$gray12",
      },
      muted: {
        color: "$gray11",
        "h1, h2, h3, h4, h5, h6": {
          color: "$gray12",
        },
        strong: {
          color: "$gray12",
        },
      },
    },

    size: {
      inherit: {},
      lg: { fontSize: "$lg" },
    },

    spacing: {
      none: {},
      default: {
        "& > * + *, li > * + *": {
          mt: "$4",
        },
        "h1, h2, h3, h4, h5, h6": {
          "&:not(:first-child)": {
            mt: "$8",
          },
        },
      },
    },

    headings: {
      none: {},
      default: {
        h1: {
          fontSize: "$3xl",
          fontWeight: "$bold",
        },

        h2: {
          fontSize: "$2xl",
          fontWeight: "$bold",
        },

        h3: {
          fontSize: "$lg",
          fontWeight: "$bold",
        },

        h4: {
          fontWeight: "$bold",
        },
      },
    },

    lists: {
      none: {},
      default: {
        "ul li": {
          position: "relative",
          ml: "1.5em",
          "&::before": {
            content: "–",
            position: "absolute",
            left: "-1.5em",
          },
        },
      },
    },

    listsSpacing: {
      default: {
        "li:not(:last-child)": {
          mb: "$4",
        },
      },
      compact: {},
    },

    links: {
      none: {},
      default: {
        a: {
          color: "$accent9",
          textDecoration: "underline",
          textDecorationColor: "$accentA6",
          textUnderlineOffset: "0.25em",
          transition: "color 120ms",

          "&:not(:disabled):hover": {
            color: "$accent12",
          },
        },
      },
    },

    emphases: {
      none: {},
      default: {
        em: {
          fontWeight: "$bold",
        },

        strong: {
          fontWeight: "$bold",
        },
      },
    },
  },

  defaultVariants: {
    size: "inherit",
    spacing: "default",
    headings: "default",
    lists: "default",
    listsSpacing: "default",
    links: "default",
    emphases: "default",
  },
})
