import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { LocalizedString } from "@/domains/localized-strings/model"
import { UrlSlug } from "@/domains/url-slug"
import { Uuid } from "@/lib/uuid"

export type CountryId = Uuid & { readonly CountryId: unique symbol }

export const CountryId = pipe(
  Uuid,
  D.refine((id): id is CountryId => true, "CountryId")
)

export const Country = D.struct({
  id: CountryId,
  name: LocalizedString,
  urlSlug: D.nullable(UrlSlug),
})

export interface Country extends D.TypeOf<typeof Country> {}

export const hrefOf = (country: Pick<Country, "id" | "urlSlug">) =>
  `/countries/${country.urlSlug ?? country.id}`
