import { ComponentProps } from "react"

import { styled } from "@/stitches"

const Svg = styled("svg", {
  variants: {
    variant: {
      normal: {
        fill: "$accent9",
      },
      monochrome: {
        fill: "inherit",
      },
    },
  },
  defaultVariants: {
    variant: "normal",
  },
})

export function Monogram(props: ComponentProps<typeof Svg>) {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" {...props}>
      <path
        d="M300.6 0v159.7c-92.9 17.5-143.4 72.9-137.5 150.5 5.3 70.2 63 125.4 134 128.4 75.8 3.2 130.6-49.4 145.2-141.2H600v300.9H441.7c-.6-10-1.3-19.5-1.9-29.4-141.7 57-267.5 38.2-369.2-78.4C-9.3 398.9-22 267.1 35.7 161.2 92 57.7 181.1 5.3 300.6 0zM600 0v158.8H440.8V0H600z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  )
}
