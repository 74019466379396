import Link from "next/link"

import { styled } from "@/stitches"
import { FullLogo } from "@/ui/logo/full-logo"
import { Monogram } from "@/ui/logo/monogram"

const Wrapper = styled("a", {
  alignSelf: "stretch",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  px: "$6",
  "@menu0": {
    width: "10rem",
  },
  "@menu1": {
    borderRight: "1px solid $divider",
  },
  "@menu2": {
    px: "$8",
    width: "12rem",
  },
  "@menu3": {
    px: "$12",
    width: "var(--left-column-width)",
  },
})

export function HeaderLogo() {
  return (
    <Link href="/" passHref>
      <Wrapper>
        <Monogram
          css={{
            display: "block",
            width: "2rem",
            "@menu0": {
              display: "none",
            },
          }}
        />
        <FullLogo
          css={{
            display: "none",
            width: "100%",
            "@menu0": {
              display: "block",
            },
          }}
        />
      </Wrapper>
    </Link>
  )
}
