import * as SelectPrimitive from "@radix-ui/react-select"
import React, { ComponentProps, ReactNode } from "react"
import ChevronDownIcon from "remixicon-react/ArrowDownSLineIcon"
import ChevronUpIcon from "remixicon-react/ArrowUpSLineIcon"
import CheckIcon from "remixicon-react/CheckLineIcon"

import { css, styled } from "@/stitches"

import * as Input from "./input"

// -----------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------

export const triggerStyle = css({
  all: "unset",
  display: "inline-flex",
  alignItems: "center",
  gap: "$2",
  px: "calc(1.5em * var(--density-factor))",
  border: "1px solid $gray8",
  whiteSpace: "nowrap",
  minHeight: "calc(3.5em * var(--density-factor))",
  boxSizing: "border-box",
  transition: "background-color 120ms, border-color 120ms, box-shadow 120ms",

  "&:disabled": Input.disabledStyle,

  "&:focus": {
    borderColor: "$accent9",
    boxShadow: "$ring",
  },

  "&:not(:disabled)[data-placeholder]": {
    color: "$gray11",
  },

  variants: {
    variant: {
      none: {},
    },
    size: {
      sm: { fontSize: "$sm" },
      md: { fontSize: "$md" },
    },
    density: {
      dense: { "--density-factor": 0.75 },
      normal: { "--density-factor": 1 },
    },
    isPlaceholder: {
      true: {
        color: "$gray11",
      },
    },
  },

  defaultVariants: {
    variant: "none",
    size: "md",
    density: "normal",
  },
})

export const addonBeforeStyle = css({
  mr: "$2",
  ml: "-$2",
})

export const caretStyle = css({
  ml: "auto",
  mr: "-$2",
})

export const itemStyle = css({
  all: "unset",
  display: "flex",
  alignItems: "center",
  pr: "calc(1.5em * var(--density-factor))",
  pl: "calc(2em * var(--density-factor))",
  py: "calc(0.75em * var(--density-factor))",
  position: "relative",
  userSelect: "none",
  transition: "background-color 120ms, color 120ms",
  cursor: "pointer",

  "&[data-disabled]": {
    color: "$gray9",
    pointerEvents: "none",
  },

  "&:focus, &:hover": {
    backgroundColor: "$gray6",
  },

  variants: {
    placeholderItem: {
      true: {
        color: "$gray11",
      },
    },
  },
})

export const itemIndicatorStyle = css({
  position: "absolute",
  left: "0.25rem",
  width: "1.5rem",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
})

// -----------------------------------------------------------------------------
// Primitives
// -----------------------------------------------------------------------------

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, triggerStyle)

const StyledCaret = styled(SelectPrimitive.Icon, caretStyle)

const StyledAddonBefore = styled("span", addonBeforeStyle)

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: "hidden",
  bgColor: "$popoverBg",
  border: "1px solid $popoverBorder",
  boxShadow: "$shadow1",
  zIndex: "$select",

  variants: {
    density: {
      dense: { "--density-factor": 0.75 },
      normal: { "--density-factor": 1 },
    },
  },

  defaultVariants: {
    density: "normal",
  },
})

const StyledViewport = styled(SelectPrimitive.Viewport, {
  py: "$3",
})

const StyledItem = styled(SelectPrimitive.Item, itemStyle)

const StyledLabel = styled(SelectPrimitive.Label, {
  pr: "calc(1.5rem * var(--density-factor))",
  pl: "calc(2rem * var(--density-factor))",
  py: "calc(0.75rem * var(--density-factor))",
  textTransform: "uppercase",
  letterSpacing: "$uppercase",
  fontSize: "$xs",
  fontWeight: "$medium",
  color: "$gray11",
})

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  backgroundColor: "$divider",
  my: "$3",
})

const StyledItemIndicator = styled(
  SelectPrimitive.ItemIndicator,
  itemIndicatorStyle
)

const scrollButtonStyles = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 25,
  backgroundColor: "$gray2",
  cursor: "default",
})

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
)

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
)

// -----------------------------------------------------------------------------
// Composites
// -----------------------------------------------------------------------------

type SelectTriggerProps = ComponentProps<typeof StyledTrigger> & {
  children?: ReactNode
  addonBefore?: ReactNode
  isPlaceholder?: boolean
}

function SelectTrigger({
  children,
  addonBefore,
  ...props
}: SelectTriggerProps) {
  return (
    <StyledTrigger {...props}>
      {addonBefore && <StyledAddonBefore>{addonBefore}</StyledAddonBefore>}
      <SelectPrimitive.Value placeholder={children} />
      <StyledCaret>
        <ChevronDownIcon size="1.25em" />
      </StyledCaret>
    </StyledTrigger>
  )
}

function SelectContent(props: ComponentProps<typeof StyledContent>) {
  return (
    <SelectPrimitive.Portal>
      <StyledContent {...props}>
        <StyledScrollUpButton>
          <ChevronUpIcon size="1.25em" />
        </StyledScrollUpButton>
        <StyledViewport>{props.children}</StyledViewport>
        <StyledScrollDownButton>
          <ChevronDownIcon size="1.25em" />
        </StyledScrollDownButton>
      </StyledContent>
    </SelectPrimitive.Portal>
  )
}

function SelectItem(props: ComponentProps<typeof StyledItem>) {
  return (
    <StyledItem {...props}>
      <SelectPrimitive.ItemText>{props.children}</SelectPrimitive.ItemText>
      <StyledItemIndicator>
        <CheckIcon size="1.25em" />
      </StyledItemIndicator>
    </StyledItem>
  )
}

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

export const Root = SelectPrimitive.Root
export const Trigger = SelectTrigger
export const Content = SelectContent
export const Group = SelectPrimitive.Group
export const Item = SelectItem
export const Label = StyledLabel
export const Separator = StyledSeparator
