import { I18n } from "@lingui/core"
import { useQuery } from "react-query"

import { useI18n } from "@/lib/i18n"

import { CountryOption } from "./model"

export const getCountries = async (i18n: I18n): Promise<Array<CountryOption>> =>
  import(`locales/${i18n.locale}/countries.ts`).then(
    (module) => module.countries
  )

export function useCountriesQuery() {
  const { i18n } = useI18n()

  return useQuery({
    queryKey: ["countries", i18n.locale],
    queryFn: () => getCountries(i18n),
    staleTime: Infinity,
  })
}
