import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { useQuery } from "react-query"

import { expectJson, useHttpClient } from "@/lib/http"

import { Currency, CurrencyCode, findByCode } from "./model"

export function useCurrenciesApi() {
  const client = useHttpClient()

  return {
    get: () => client.get(`aig/currencies`).then(expectJson(D.array(Currency))),
  }
}

export function useCurrenciesQuery() {
  const api = useCurrenciesApi()

  return useQuery({
    queryKey: ["currencies"],
    queryFn: api.get,
  })
}

export function useCurrencyQuery(code: CurrencyCode) {
  const api = useCurrenciesApi()

  return useQuery({
    queryKey: ["currencies"],
    queryFn: api.get,
    select: (currencies) => {
      const curr = pipe(currencies, findByCode(code))

      if (!curr) {
        throw new Error(`Currency with code ${code} not found`)
      }

      return curr
    },
  })
}
