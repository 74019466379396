import { either } from "fp-ts"
import { Either } from "fp-ts/Either"

/**
 * Takes `right` if `Either` is `right`,
 * Throws error with value
 */
export const getOrThrow =
  <E, A>(errorFromLeft?: (left: E) => Error) =>
  (e: Either<E, A>): A | never => {
    if (either.isRight(e)) {
      return e.right
    }

    if (errorFromLeft) {
      throw errorFromLeft(e.left)
    }

    throw e.left
  }
