import { useStore } from "effector-react"

import { ColorScheme } from "@/domains/color-scheme/model"
import { CurrencyCode } from "@/domains/currencies/model"
import { LengthUnit } from "@/domains/units/model"
import {
  useMeQuery,
  useUpdateUserSettingsMutation,
} from "@/domains/users/query"

import { $settings, setColorScheme, setCurrency, setLengthUnit } from "./store"

export function useSettings() {
  const settings = useStore($settings)
  const { data: me } = useMeQuery()
  const { mutate } = useUpdateUserSettingsMutation()

  const changeCurrency = (currency: CurrencyCode) => {
    setCurrency(currency)

    if (me) {
      mutate({
        ...me.settings,
        currency,
      })
    }
  }

  const changeLengthUnit = (lengthUnit: LengthUnit) => {
    setLengthUnit(lengthUnit)

    if (me) {
      mutate({
        ...me.settings,
        lengthUnit,
      })
    }
  }

  const changeColorScheme = (colorScheme: ColorScheme) => {
    setColorScheme(colorScheme)

    if (me) {
      mutate({
        ...me.settings,
        colorScheme,
      })
    }
  }

  return {
    ...settings,
    changeCurrency,
    changeLengthUnit,
    changeColorScheme,
  }
}
