import { useStore } from "effector-react"

import { styled } from "@/stitches"
import { IconButton } from "@/ui/button"
import { Stack } from "@/ui/stack"

import { nav } from "../../../domains/auth/register/store"
import { Step } from "./model"

const DotButton = styled(IconButton, {
  "& svg": {
    stroke: "$accent9",
  },
  "&:disabled svg": {
    stroke: "$gray9",
  },
  variants: {
    active: {
      true: {
        "& svg": {
          fill: "$accent9",
        },
      },
      false: {
        "& svg": {
          fill: "transparent",
        },
      },
    },
  },
  defaultVariants: {
    active: false,
  },
})

export function StepNav({ steps }: { steps: Array<Step> }) {
  const currentIndex = useStore(nav.$currentIndex)

  return (
    <Stack>
      {steps.map((_, index) => {
        const previousStep = steps[index - 1]
        const previousForm = previousStep?.form
        const isEnabled = previousForm?.formState?.isSubmitSuccessful ?? true

        const onClick = () => {
          nav.goAt(index)
        }

        return (
          <DotButton
            type="button"
            size="xs"
            key={index}
            active={index === currentIndex}
            onClick={onClick}
            disabled={!isEnabled}
          >
            <svg width="0.5em" height="0.5em" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3" />
            </svg>
          </DotButton>
        )
      })}
    </Stack>
  )
}
