import { Trans, t } from "@lingui/macro"
import { useStore } from "effector-react"
import { either } from "fp-ts"
import { pipe } from "fp-ts/function"
import { useRouter } from "next/router"
import { ReactNode } from "react"
import CheckIcon from "remixicon-react/CheckLineIcon"

import { AddressesFormSchema } from "@/domains/addresses/form"
import {
  ContactDetailsFormSchema,
  CredentialsFormSchema,
  RegisterFormSchema,
} from "@/domains/auth/register/form"
import { useRegisterMutation } from "@/domains/auth/register/query"
import {
  $form,
  nav,
  setAddresses,
  setContactDetails,
  setCredentials,
} from "@/domains/auth/register/store"
import { getDefaultPhone, phoneToString } from "@/domains/phone/model"
import { defaultNotificationSettings, fullNameOf } from "@/domains/users/model"
import { $settings } from "@/domains/website-settings/store"
import { tryGet } from "@/lib/array"
import { useForm } from "@/lib/form"
import { Box } from "@/ui/box"
import { Button } from "@/ui/button"
import { Callout } from "@/ui/callout"
import * as Dialog from "@/ui/dialog"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"

import { $dialog, closeDialog, openDialog } from "../dialog-store"
import { CredentialsView } from "./1-credentials"
import { ContactDetailsView } from "./2-contact-details"
import { AddressesView } from "./3-address"
import { PreferencesView } from "./4-preferences"
import { Step } from "./model"
import { Slide } from "./slide"
import { StepNav } from "./step-nav"

function useDialogState() {
  const isOpen = useStore($dialog.map((dialog) => dialog === "register"))
  const onOpenChange = (open: boolean) =>
    open ? openDialog("register") : closeDialog()

  return { isOpen, onOpenChange }
}

function useSteps() {
  const settings = useStore($settings)
  const { locale } = useRouter()

  const forms = {
    credentials: useForm(CredentialsFormSchema, {
      reValidateMode: "onChange",
    }),
    contactDetails: useForm(ContactDetailsFormSchema, {
      defaultValues: {
        phone: getDefaultPhone(),
      },
    }),
    addresses: useForm(AddressesFormSchema, {
      defaultValues: {
        deliveryAddress: { isSameAsInvoiceAddress: true },
      },
    }),
  }

  const registerMutation = useRegisterMutation({
    onFailure(error) {
      if (error.type === "user_exists") {
        nav.goAt(0)
        forms.credentials.setError("email", {
          message: t({
            id: "registerDialog.userAlreadyExists",
            message: "Uživatel s tímto e-mailem již existuje",
          }),
        })
      }
    },
  })

  const finalSubmit = () => {
    /* eslint-disable-next-line effector/no-getState */
    const result = RegisterFormSchema.safeParse($form.getState())

    if (result.success) {
      const { credentials, contactDetails, invoiceAddress, deliveryAddress } =
        result.data
      registerMutation.mutate({
        email: credentials.email,
        password: credentials.password,
        firstName: contactDetails.firstName,
        lastName: contactDetails.lastName,
        phone: phoneToString(contactDetails.phone),
        settings: {
          ...settings,
          locale,
          notifications: defaultNotificationSettings,
        },
        customData: {
          invoiceAddress,
          deliveryAddress,
        },
      })
    } else {
      /* eslint-disable-next-line */
      console.error(result.error)
      // TODO
    }
  }

  const steps: Array<Step> = [
    {
      title: t({
        id: "registerDialog.credentials",
        message: "Přihlašovací údaje",
      }),
      content: <CredentialsView form={forms.credentials} />,
      form: forms.credentials,
      submit: forms.credentials.handleSubmit((data) => {
        setCredentials(data)
        nav.goNext()
      }),
    },
    {
      title: t({
        id: "registerDialog.contactDetails",
        message: "Kontaktní údaje",
      }),
      content: <ContactDetailsView form={forms.contactDetails} />,
      form: forms.contactDetails,
      submit: forms.contactDetails.handleSubmit((data) => {
        setContactDetails(data)
        forms.addresses.setValue("invoiceAddress.recipient", fullNameOf(data))
        forms.addresses.setValue("deliveryAddress.recipient", fullNameOf(data))
        nav.goNext()
      }),
    },
    {
      title: t({
        id: "registerDialog.invoiceDetails",
        message: "Fakturační údaje",
      }),
      content: <AddressesView form={forms.addresses} />,
      form: forms.addresses,
      submit: forms.addresses.handleSubmit((data) => {
        setAddresses(data)
        nav.goNext()
      }),
    },
    {
      title: t({
        id: "registerDialog.preferredSettings",
        message: "Preferované nastavení",
      }),
      content: <PreferencesView />,
      submit: (event) => {
        event.preventDefault()
        finalSubmit()
      },
    },
  ]

  const stepIndex = useStore(nav.$currentIndex)
  const isLastStep = useStore(nav.$isLast)
  const step = pipe(steps, tryGet(stepIndex))

  return { steps, step, stepIndex, isLastStep, registerMutation, forms }
}

export function RegisterDialog({ children }: { children: ReactNode }) {
  const { steps, step, stepIndex, isLastStep, registerMutation, forms } =
    useSteps()
  const { isOpen, onOpenChange } = useDialogState()
  const registrationComplete =
    registerMutation.isSuccess && either.isRight(registerMutation.data)

  const description = !registrationComplete
    ? `${t({ id: "step", message: "Krok" })} ${stepIndex + 1}/${nav.length}: ${
        step.title
      }`
    : undefined

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content size="sm" position="right" asChild>
        <form onSubmit={step.submit}>
          <Dialog.Header description={description}>
            <Trans id="registerDialog.title">Registrace</Trans>
          </Dialog.Header>
          {registerMutation.isSuccess ? (
            <Dialog.Body>
              <Callout
                title={t({
                  id: "registerDialog.registrationAlmostComplete",
                  message: "Téměř hotovo!",
                })}
                icon={<CheckIcon size="2em" />}
              >
                <p>
                  <Trans id="registerDialog.verificationMailSentToAddress">
                    Poslali jsme vám ověřovací e-mail na adresu{" "}
                    {forms.credentials.getValues("email")}
                  </Trans>
                </p>
              </Callout>

              <Box as="p" css={{ mt: "$4" }}>
                <Text as="small" color="muted" size="sm">
                  <Trans id="registerDialog.didntReceiveEmail?">
                    Nic nepřišlo? Zkuste zkontrolovat složku s nevyžádanou
                    poštou.
                  </Trans>
                </Text>
              </Box>
            </Dialog.Body>
          ) : (
            <>
              <Dialog.Body compact>
                <Box
                  css={{
                    position: "relative",
                    height: "28rem",
                  }}
                >
                  <Slide>{step.content}</Slide>
                </Box>
              </Dialog.Body>
              <Dialog.Footer>
                <Stack justify="between" align="center">
                  <Button
                    type="submit"
                    variant={isLastStep ? "solid" : "outline"}
                    loading={registerMutation.isLoading}
                  >
                    {isLastStep ? (
                      <Trans id="registerDialog.submitButton.label">
                        Registrovat
                      </Trans>
                    ) : (
                      <Trans id="registerDualog.continueButton.label">
                        Pokračovat
                      </Trans>
                    )}
                  </Button>
                  <StepNav steps={steps} />
                </Stack>
                <Box as="p" css={{ mt: "$6" }}>
                  <Trans id="registerDialog.loginInstead">
                    Již máte účet?{" "}
                    <Text
                      as="button"
                      color="accent"
                      link
                      underline
                      onClick={() => openDialog("login")}
                    >
                      Přihlašte se
                    </Text>
                  </Trans>
                </Box>
              </Dialog.Footer>
            </>
          )}
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}
