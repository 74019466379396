import { ReactNode, createContext, useContext } from "react"

import {
  CurrencyCode,
  defaultCurrencyCode,
  useCurrenciesQuery,
} from "@/domains/currencies"
import { useSettings } from "@/domains/website-settings/lib"
import { useI18n } from "@/lib/i18n"

import { Eur, Rates, currencyRatesOf } from "."

type CurrencyContextValue = {
  currency?: CurrencyCode
  rates?: Rates
}

const CurrencyContext = createContext<CurrencyContextValue | null>(null)

export function CurrencyProvider({
  children,
  ...contextValue
}: { children: ReactNode } & CurrencyContextValue) {
  return (
    <CurrencyContext.Provider value={contextValue}>
      {children}
    </CurrencyContext.Provider>
  )
}

type UseMoneyOptions = {
  rates?: Rates
  currency?: CurrencyCode | "default"
}

/**
 * Returns a function to format money in specified currency and with specified rates.
 */
export function useMoney(props: UseMoneyOptions) {
  const currenciesQuery = useCurrenciesQuery()
  const context = useContext(CurrencyContext)
  const settings = useSettings()
  const { i18n } = useI18n()

  return (value: Eur, options?: Intl.NumberFormatOptions) => {
    if (!currenciesQuery.data) {
      return null
    }

    /**
     * Priority:
     *
     * 1. From props
     * 2. From nearest context provider
     * 3. From global currency settings
     */
    const currency =
      props.currency === "default"
        ? defaultCurrencyCode
        : props.currency ?? context?.currency ?? settings.currency

    /**
     * Priority:
     *
     * 1. From props
     * 2. From nearest context provider
     * 3. From current currency rates
     */
    const rates = {
      ...currencyRatesOf(currenciesQuery.data),
      ...context?.rates,
      ...props.rates,
    }

    const rate = rates[currency]

    if (!rate) {
      throw new Error("Unknown rate")
    }

    return i18n.number(value * rate, {
      style: "currency",
      currency,
      currencyDisplay: "narrowSymbol",
      maximumFractionDigits: 0,
      ...options,
    })
  }
}

type MoneyViewProps = UseMoneyOptions & { value: Eur }

/**
 * Component to display money value.
 */
export function MoneyView({ value, ...options }: MoneyViewProps) {
  const formatMoney = useMoney(options)

  return <>{formatMoney(value)}</>
}
