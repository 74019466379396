import { useStore } from "effector-react"
import MailIcon from "remixicon-react/MailLineIcon"
import MailSendIcon from "remixicon-react/MailSendLineIcon"
import { z } from "zod"

import { useGetResetTokenMutation } from "@/domains/auth/reset-password"
import { useForm } from "@/lib/form"
import { Button } from "@/ui/button"
import { Callout } from "@/ui/callout"
import * as Dialog from "@/ui/dialog"
import { FormField } from "@/ui/form/field"
import { Input } from "@/ui/input"

import { $dialog, closeDialog, openDialog } from "./dialog-store"

const schema = z.object({
  email: z.string().nonempty().email(),
})

export function ResetPasswordDialog() {
  const isOpen = useStore($dialog.map((dialog) => dialog === "resetPassword"))
  const onOpenChange = (open: boolean) =>
    open ? openDialog("login") : closeDialog()

  const form = useForm(schema)
  const mutation = useGetResetTokenMutation()

  const onSubmit = form.handleSubmit(({ email }) => {
    mutation.mutate({ email })
  })

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Content size="xs" asChild>
        {mutation.isSuccess ? (
          <div>
            <Dialog.Header>Zapomenuté heslo</Dialog.Header>
            <Dialog.Body>
              <Callout
                variant="info"
                icon={<MailSendIcon size="1.5em" />}
                title="Poslali jsme vám odkaz k obnově hesla"
              >
                Podívejte se do e-mailové schránky {form.getValues("email")}
              </Callout>
            </Dialog.Body>
          </div>
        ) : (
          <form onSubmit={onSubmit}>
            <Dialog.Header description="Zadejte váš e-mail a my vám pošleme odkaz na obnovu hesla">
              Zapomenuté heslo
            </Dialog.Header>
            <Dialog.Body>
              <FormField
                form={form}
                name="email"
                label="E-mail"
                input={(props) => (
                  <Input
                    {...props}
                    type="email"
                    autoComplete="email"
                    addonBefore={<MailIcon size="1.25em" />}
                  />
                )}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                type="submit"
                css={{ alignSelf: "start", mt: "$4" }}
                loading={mutation.isLoading}
              >
                Poslat žádost o obnovení hesla
              </Button>
            </Dialog.Footer>
          </form>
        )}
      </Dialog.Content>
    </Dialog.Root>
  )
}
