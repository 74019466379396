import { i18n } from "@lingui/core"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import Link from "next/link"
import React from "react"
import CloseIcon from "remixicon-react/CloseLineIcon"
import FacebookIcon from "remixicon-react/FacebookBoxFillIcon"
import InstagramIcon from "remixicon-react/InstagramFillIcon"
import MenuIcon from "remixicon-react/MenuLineIcon"

import { keyframes, styled } from "@/stitches"
import { Box } from "@/ui/box"
import { IconButton } from "@/ui/button"
import { Container } from "@/ui/container"
import * as Dialog from "@/ui/dialog"
import { Stack } from "@/ui/stack"
import { LinkOrDownload } from "@/views/_layout/link-or-download"

import { HeaderLogo } from "./header-logo"
import { footerItems, menuItems, useItemIsActive } from "./items"
import { MenuItemView } from "./menu-item-view"
import { SettingsMenu } from "./settings-menu"

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translateY(-10%)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
})

const Content = styled(DialogPrimitive.Content, {
  left: 0,
  right: 0,
  top: 0,
  bg: "$gray1",
  color: "$gray12",
  width: "100%",
  position: "fixed",
  maxHeight: "100vh",
  overflowY: "auto",
  zIndex: "$modal",
})

const Bar = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  pr: "$2",
  height: "var(--menu-height)",
  borderBottom: "1px solid $divider",
})

const MenuContent = styled("div", {
  "@animation": {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
})

export function MenuDialog({
  open,
  onOpenChange,
}: Pick<DialogPrimitive.DialogProps, "open" | "onOpenChange">) {
  const isItemActive = useItemIsActive()

  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
      <DialogPrimitive.Trigger asChild>
        <IconButton
          css={{
            "@menu2": { display: "none" },
          }}
        >
          <MenuIcon size="1.5em" />
        </IconButton>
      </DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <Dialog.Overlay />
        <Content>
          <Bar>
            <HeaderLogo />
            <DialogPrimitive.Close asChild>
              <IconButton>
                <CloseIcon size="1.5em" />
              </IconButton>
            </DialogPrimitive.Close>
          </Bar>
          <MenuContent>
            <Container size="sm">
              <Stack gap={6} direction="column" css={{ py: "$12" }}>
                <nav>
                  <ul>
                    {menuItems.map((item) => (
                      <li key={item.href}>
                        <Link href={item.href} passHref>
                          <MenuItemView
                            as="a"
                            css={{ display: "block", py: "$3" }}
                            active={isItemActive(item.href)}
                          >
                            {i18n._(item.title)}
                          </MenuItemView>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
                <nav>
                  <Stack as="ul" wrap rowGap={4}>
                    {footerItems.map((item) => (
                      <Box
                        as="li"
                        key={item.href}
                        css={{
                          "&:not(:last-of-type)::after": {
                            content: "",
                            position: "relative",
                            display: "inline-block",
                            borderRight: "1px solid $divider",
                            height: "1.25em",
                            mt: "-1.25em",
                            mx: "$3",
                            top: "0.25em",
                          },
                        }}
                      >
                        <LinkOrDownload
                          href={item.href}
                          type={item.type}
                          textProps={{
                            variant: "label",
                            color: "muted",
                            link: true,
                            nowrap: true,
                          }}
                        >
                          {i18n._(item.title)}
                        </LinkOrDownload>
                      </Box>
                    ))}
                  </Stack>
                </nav>
                <Stack align="center" css={{ gap: "$6", fill: "$accent9" }}>
                  <FacebookIcon size="2em" />
                  <InstagramIcon size="2em" />
                  <Box css={{ "@menu2": { display: "none" } }}>
                    <SettingsMenu />
                  </Box>
                </Stack>
              </Stack>
            </Container>
          </MenuContent>
        </Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
