import { t } from "@lingui/macro"
import { Controller } from "react-hook-form"
import UserIcon from "remixicon-react/UserLineIcon"

import { ContactDetailsFormSchema } from "@/domains/auth/register/form"
import { Form } from "@/lib/form"
import { FieldBase } from "@/ui/field-base"
import { FormField } from "@/ui/form/field"
import { Input } from "@/ui/input"
import { PhoneInput } from "@/ui/phone-input"
import { Stack } from "@/ui/stack"

export function ContactDetailsView({
  form,
}: {
  form: Form<ContactDetailsFormSchema>
}) {
  return (
    <Stack direction="column" gap={4}>
      <FormField
        form={form}
        name="firstName"
        label={t({
          id: "registerDialog.contactDetails.firstName",
          message: "Jméno",
        })}
        input={(props) => (
          <Input
            {...props}
            addonBefore={<UserIcon size="1.25em" />}
            autoComplete="given-name"
          />
        )}
      />
      <FormField
        form={form}
        name="lastName"
        label={t({
          id: "registerDialog.contactDetails.lastName",
          message: "Příjmení",
        })}
        input={(props) => (
          <Input
            {...props}
            addonBefore={<UserIcon size="1.25em" />}
            autoComplete="family-name"
          />
        )}
      />
      <FieldBase
        label={t({
          id: "registerDialog.contactDetails.phone",
          message: "Telefonní číslo",
        })}
        error={
          form.formState.errors.phone?.nationalNumber?.message ||
          form.formState.errors.phone?.countryCallingCode?.message
        }
      >
        {(inputProps) => (
          <Controller
            control={form.control}
            name="phone"
            render={({ field: { ref, ...field } }) => (
              <PhoneInput innerRef={ref} {...inputProps} {...field} />
            )}
          />
        )}
      </FieldBase>
    </Stack>
  )
}
