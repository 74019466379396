import { VariantProps } from "@stitches/react"

import { styled } from "@/stitches"

export type BadgeProps = VariantProps<typeof Badge>

export const Badge = styled("span", {
  display: "grid",
  placeContent: "center",
  borderRadius: 100,
  flexShrink: 0,
  lineHeight: 1,

  variants: {
    size: {
      xs: {
        fontSize: "$xs",
        width: "1rem",
        height: "1rem",
        fontWeight: "$bold",
      },
      sm: {
        fontSize: "$xs",
        width: "1.25rem",
        height: "1.25rem",
        fontWeight: "$black",
      },
      md: {
        fontSize: "$sm",
        width: "1.5rem",
        height: "1.5rem",
        fontWeight: "$black",
      },
    },
    color: {
      accent: {
        bgColor: "$accent9",
        color: "$gray1",
      },
      muted: {
        bgColor: "$gray8",
        color: "$gray12",
      },
      info: {
        bgColor: "$blue9",
        color: "white",
      },
    },
    border: {
      true: {
        boxShadow: "0 0 0 3px $colors$gray1",
      },
    },
  },

  defaultVariants: {
    size: "sm",
    color: "accent",
  },
})
