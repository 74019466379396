import { apply } from "fp-ts"
import { Apply1 } from "fp-ts/Apply"
import { pipe } from "fp-ts/function"
import {
  QueryObserverIdleResult,
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverResult,
  QueryObserverSuccessResult,
} from "react-query"

// -----------------------------------------------------------------------------
// Typeclass instance
// -----------------------------------------------------------------------------

export const URI = "QueryObserverResult"

export type URI = typeof URI

declare module "fp-ts/HKT" {
  interface URItoKind<A> {
    readonly QueryObserverResult: QueryObserverResult<A>
  }
}

export const Apply: Apply1<URI> = {
  URI,
  map: (ma, f) => pipe(ma, map(f)),
  ap: (fab, fa) => pipe(fab, ap(fa)),
}

// -----------------------------------------------------------------------------
// Combinators
// -----------------------------------------------------------------------------

/**
 * Utility to transform `data` value within `QueryObserverResult`
 */
export const map =
  <A, B, E = unknown>(f: (a: A) => B) =>
  (result: QueryObserverResult<A, E>): QueryObserverResult<B, E> => {
    if (result.isSuccess || result.isRefetchError) {
      return {
        ...result,
        data: f(result.data),
      } as
        | QueryObserverSuccessResult<B, E>
        | QueryObserverRefetchErrorResult<B, E>
    }

    return result as
      | QueryObserverIdleResult<never, E>
      | QueryObserverLoadingErrorResult<never, E>
      | QueryObserverLoadingResult<never, E>
  }

export const ap =
  <A, B, E = unknown>(fa: QueryObserverResult<A, E>) =>
  (fab: QueryObserverResult<(a: A) => B>): QueryObserverResult<B, E> => {
    if (
      (fab.isSuccess || fab.isRefetchError) &&
      (fa.isSuccess || fa.isRefetchError)
    ) {
      return {
        ...fab,
        data: fab.data(fa.data),
      } as
        | QueryObserverSuccessResult<B, E>
        | QueryObserverRefetchErrorResult<B, E>
    }

    return fab as
      | QueryObserverIdleResult<never, E>
      | QueryObserverLoadingErrorResult<never, E>
      | QueryObserverLoadingResult<never, E>
  }

export const sequenceT = apply.sequenceT(Apply)
export const sequenceS = apply.sequenceS(Apply)
