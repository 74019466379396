import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { UploadedImageFile } from "@/domains/files/model"

type ArtworkImageType = string & { readonly ImageType: unique symbol }

const ArtworkImageType = pipe(
  D.string,
  D.refine<string, ArtworkImageType>(
    (_): _ is ArtworkImageType => true,
    "ImageType"
  )
)

export const ArtworkImage = D.struct({
  file: UploadedImageFile,
  type: D.nullable(ArtworkImageType),
})

export interface ArtworkImage extends D.TypeOf<typeof ArtworkImage> {}

const imageTypes: Record<string, string> = {
  front: "Obraz",
  frame: "Obraz v rámu",
  signature: "Signatura",
  back: "Zadní strana",
  detail: "Detail",
}

export function displayImageType(
  imageType: ArtworkImageType | null
): string | undefined {
  if (imageType === null) {
    return undefined
  }

  return imageTypes[imageType as string]
}
