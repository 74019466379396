import { ComponentProps, ReactNode, forwardRef } from "react"
import CaretIcon from "remixicon-react/ArrowDownSLineIcon"

import { CSS, styled } from "@/stitches"

import * as Input from "./input"

const Caret = styled("span", {
  position: "absolute",
  top: "50%",
  right: 16,
  transform: "translateY(-50%)",
  pointerEvents: "none",
})

const SelectStyled = styled("select", {
  padding: "var(--spacing-y) var(--spacing-x)",
  paddingRight: "calc(var(--spacing-x) + $space$6)",
})

const Option = styled("option", {
  color: "initial",
})

export type SelectOption = {
  display?: ReactNode
  value: string
}

export type SelectProps = Omit<ComponentProps<typeof SelectStyled>, "size"> &
  Input.InputRootProps & {
    onValueChange?: (value: string) => void
    options?: Array<SelectOption>
    css?: CSS
    addonBefore?: ReactNode
  }

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  function SelectForwarded(props, ref) {
    const {
      // Variant props
      variant,
      size,
      state,
      css,
      // Select props
      options = [],
      addonBefore,
      onValueChange,
      // Input props
      ...inputProps
    } = props

    return (
      <Input.Root {...{ variant, size, state, css }}>
        {addonBefore && (
          <Input.Addon position="start">{addonBefore}</Input.Addon>
        )}
        <SelectStyled
          {...inputProps}
          {...(onValueChange
            ? { onChange: (event) => onValueChange(event.target.value) }
            : {})}
          ref={ref}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.display ?? option.value}
            </Option>
          ))}
        </SelectStyled>
        <Caret>
          <CaretIcon size="1.25em" />
        </Caret>
      </Input.Root>
    )
  }
)
