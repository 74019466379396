import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

// -----------------------------------------------------------------------------
// Functions
// -----------------------------------------------------------------------------

/**
 * Returns current datetime. Doesn't do anything special but this
 * function's name is more descriptive than just calling `new Date`
 */
export function now(): Date {
  return new Date()
}

/**
 * Returns current datetime without the time part.
 */
export function today(): Date {
  const currentDatetime = now()
  return new Date(
    currentDatetime.getFullYear(),
    currentDatetime.getMonth(),
    currentDatetime.getDate()
  )
}

// -----------------------------------------------------------------------------
// Decoders
// -----------------------------------------------------------------------------

const DateFromISOString: D.Decoder<string, Date> = {
  decode: (string) => {
    const date = new Date(string)
    return isNaN(date.getTime()) ? D.failure(string, "") : D.success(date)
  },
}

export const DateFromUnknown = pipe(D.string, D.parse(DateFromISOString.decode))
