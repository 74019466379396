import { array, boolean, option } from "fp-ts"
import { Option } from "fp-ts/Option"
import { constant, pipe } from "fp-ts/function"

export const tryGet =
  <A>(index: number) =>
  (array: Array<A>): A | never => {
    const item = array[index]

    if (!item) {
      throw new Error("Array item undefined")
    }

    return item
  }

export const sum = (array: Array<number>): number =>
  array.reduce((a, b) => a + b, 0)

export const dropAt =
  (i: number) =>
  (n: number) =>
  <A>(xs: Array<A>): Option<Array<A>> =>
    pipe(
      array.isOutOfBound(i, xs),
      boolean.fold(
        () =>
          pipe(
            array.copy(xs),
            (ys) => {
              ys.splice(i, n)
              return ys
            },
            option.some
          ),
        constant(option.none)
      )
    )
