import { i18n } from "@lingui/core"
import { Trans } from "@lingui/macro"
import Link from "next/link"
import SearchIcon from "remixicon-react/Search2LineIcon"
import UserIcon from "remixicon-react/UserLineIcon"

import { useSession } from "@/domains/session/store"
import { useBooleanState } from "@/lib/hooks/use-boolean-state"
import { styled } from "@/stitches"
import { Box } from "@/ui/box"
import { Button, IconButton } from "@/ui/button"

import { openDialog } from "./dialog-store"
import { HeaderLogo } from "./header-logo"
import { menuItems, useItemIsActive } from "./items"
import { LoginDialog } from "./login-dialog"
import { MenuDialog } from "./menu-dialog"
import { MenuItemView } from "./menu-item-view"
import { RegisterDialog } from "./register-dialog"
import { SettingsMenu } from "./settings-menu"
import { UserMenu } from "./user-menu"

const searchEnabled = false

const Divider = styled("div", {
  borderRight: "1px solid $divider",
  height: 16,
})

const HeaderWrapper = styled("div", {
  display: "flex",
  alignItems: "stretch",
  borderBottom: "1px solid $divider",
  transition: "background-color 120ms",
  height: "var(--menu-height)",
  variants: {
    absolute: {
      true: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      },
    },
    dialogOpen: {
      true: {},
      false: {},
    },
  },
})

export function Header({ absolute }: { absolute: boolean }) {
  const menu = useBooleanState()
  const session = useSession()
  const isItemActive = useItemIsActive()

  const searchSection = (
    <>
      <IconButton>
        <SearchIcon size="1.5em" />
      </IconButton>
      <Divider
        css={{
          display: "none",
          "@menu1": { display: "block" },
        }}
      />
    </>
  )

  const authSection = session.authenticated ? (
    <UserMenu />
  ) : (
    <>
      <IconButton
        onClick={() => openDialog("login")}
        css={{
          "@menu1": { display: "none" },
        }}
      >
        <UserIcon size="1.5em" />
      </IconButton>
      <Box
        css={{
          display: "none",
          gap: "$1",
          "@menu1": { display: "flex" },
          "@menu3": { gap: "$2" },
        }}
      >
        <RegisterDialog>
          <Button
            density={{
              "@initial": "dense",
              "@menu3": "normal",
            }}
          >
            <Trans id="header.register">Registrace</Trans>
          </Button>
        </RegisterDialog>
        <LoginDialog>
          <Button
            variant="ghost"
            density={{
              "@initial": "dense",
              "@menu3": "normal",
            }}
          >
            <Trans id="header.login">Přihlášení</Trans>
          </Button>
        </LoginDialog>
      </Box>
    </>
  )

  const settingsMenu = (
    <>
      <Divider css={{ display: "none", "@menu2": { display: "block" } }} />
      <Box css={{ display: "none", "@menu2": { display: "block" } }}>
        <SettingsMenu />
      </Box>
    </>
  )

  const menuDialogSection = (
    <>
      <Divider
        css={{
          display: "none",
          "@menu1": { display: "block" },
          "@menu2": { display: "none" },
        }}
      />
      <MenuDialog open={menu.isOn} onOpenChange={menu.set} />
    </>
  )

  return (
    <HeaderWrapper dialogOpen={menu.isOn} absolute={absolute}>
      <HeaderLogo />
      <Box
        as="nav"
        css={{
          display: "none",
          px: "$3",
          "@menu2": { display: "flex" },
          "@menu3": { px: "$6" },
        }}
      >
        {menuItems.map((item, i) => (
          <Link href={item.href} passHref key={i}>
            <Box
              as="a"
              css={{
                px: "$3",
                display: "flex",
                alignItems: "stretch",
                "@menu3": {
                  px: "$4",
                },
              }}
            >
              <MenuItemView as="span" active={isItemActive(item.href)} border>
                <Box as="span" css={{ my: "auto" }}>
                  {i18n._(item.title)}
                </Box>
              </MenuItemView>
            </Box>
          </Link>
        ))}
      </Box>
      <Box
        css={{
          display: "flex",
          alignItems: "center",
          ml: "auto",
          pr: "$2",
          "@menu1": { gap: "$1" },
          "@menu3": { gap: "$2" },
        }}
      >
        {searchEnabled && searchSection}
        {authSection}
        {settingsMenu}
        {menuDialogSection}
      </Box>
    </HeaderWrapper>
  )
}
