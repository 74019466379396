import { either, record } from "fp-ts"
import { isBoolean } from "fp-ts/boolean"
import { isNumber } from "fp-ts/number"
import { isString } from "fp-ts/string"
import * as D from "io-ts/Decoder"
import ky from "ky"

import { env } from "@/config/env"
import { Session, useSession } from "@/domains/session/store"

export function createClient({ session }: { session?: Session } = {}) {
  const headers: Record<string, string> = {
    Accept: "application/json",
  }

  if (session?.authenticated) {
    headers["Authorization"] = `Bearer ${session.token}`
  }

  if (!process.browser) {
    // If request is performed on server side, we need to set origin manually,
    // because it is required for tenant resolution.
    headers["Origin"] = env.ORIGIN
  }

  return ky.extend({
    prefixUrl: [env.API_URL, "api"].join("/"),
    retry: 0, // Retrying is handled by react-query
    headers,
  })
}

export function useHttpClient() {
  // Here we can inject dependencies using hooks (ie. configuration, token, etc.)
  const session = useSession()

  return createClient({ session })
}

export class UnexpectedResponseError extends Error {
  constructor(
    decodeError: D.DecodeError,
    actualValue: unknown,
    response: Response
  ) {
    super(`Received an unexpected response from request ${response.url}:
${D.draw(decodeError)}`)
    this.name = "UnexpectedResponseError"
  }
}

/**
 * Validates whether response's JSON body matches expected data shape.
 *
 * Usage:
 *
 *     client.get("/aig/authors").then(expectJson(D.array(Author)))
 */
export const expectJson =
  <A>(decoder: D.Decoder<unknown, A>) =>
  async (response: Response): Promise<A> => {
    const value = await response.json()
    const result = decoder.decode(value)

    if (either.isLeft(result)) {
      throw new UnexpectedResponseError(result.left, value, response)
    }

    return result.right
  }

/**
 * Convert object to `FormData`.
 */
export const toFormData = (
  data: Record<
    string,
    string | Blob | FileList | Array<File> | Array<string> | undefined
  >
): FormData => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    const value = data[key]

    if (value instanceof FileList) {
      Array.from(value).forEach((file) => {
        formData.append(`${key}[]`, file)
      })
      return
    }

    if (Array.isArray(value)) {
      value.forEach((file) => {
        formData.append(`${key}[]`, file)
      })
      return
    }

    if (value !== undefined) {
      return formData.append(key, value)
    }
  })
  return formData
}

/**
 *
 */
export const createParams = (
  params: Record<string, string | number | boolean | undefined>
): URLSearchParams => {
  const query = new URLSearchParams()
  record.toArray(params).forEach(([key, value]) => {
    if (isString(value)) {
      return query.append(key, value)
    }
    if (isNumber(value)) {
      return query.append(key, String(value))
    }
    if (isBoolean(value) && value) {
      return query.append(key, value ? "1" : "0")
    }
  })
  return query
}
