import { Store, createDomain, sample } from "effector"

type BoundaryBehavior = "cycle" | "stop"

type Config = {
  length: number
  boundaryBehavior: BoundaryBehavior
  domainName?: string
}

type SequentialNavigation = {
  length: number
  $currentIndex: Store<number>
  $currentStep: Store<number>
  $isFirst: Store<boolean>
  $isLast: Store<boolean>
  $canGoNext: Store<boolean>
  $canGoPrev: Store<boolean>
  $lastDirection: Store<number>
  goNext: () => void
  goPrev: () => void
  goAt: (index: number) => void
}

function isOutOfBound(index: number, length: number): boolean {
  return index < 0 || index >= length
}

function clampToBounds(index: number, length: number): number {
  if (index < 0) return 0
  if (index >= length) return length - 1
  return index
}

function getNewIndex(
  index: number,
  length: number,
  { boundaryBehavior }: { boundaryBehavior: BoundaryBehavior }
) {
  if (isOutOfBound(index, length)) {
    switch (boundaryBehavior) {
      case "cycle":
        const mod = index % length
        return mod < 0 ? length + mod : mod
      case "stop":
        return clampToBounds(index, length)
    }
  }

  return index
}

export function createSequentialNav({
  length,
  boundaryBehavior,
  domainName,
}: Config): SequentialNavigation {
  const domain = createDomain(domainName)

  const goNext = domain.createEvent<void>()
  const goPrev = domain.createEvent<void>()
  const goAt = domain.createEvent<number>()

  const _getNewIndex = (index: number) =>
    getNewIndex(index, length, { boundaryBehavior })

  const $index = domain
    .createStore<{ last: number; current: number }>({ last: 0, current: 0 })
    .on(goNext, ({ current }) => ({
      last: current,
      current: _getNewIndex(current + 1),
    }))
    .on(goPrev, ({ current }) => ({
      last: current,
      current: _getNewIndex(current - 1),
    }))
    .on(goAt, ({ current }, newIndex) => ({
      last: current,
      current: _getNewIndex(newIndex),
    }))

  const $currentIndex = $index.map(({ current }) => current)

  const $lastDirection = $index.map(({ last, current }) =>
    Math.sign(current - last)
  )

  const $currentStep = $index.map(({ current }) => current + 1)

  const $isFirst = sample({
    source: $index,
    fn: ({ current }) => current === 0,
  })

  const $isLast = sample({
    source: $index,
    fn: ({ current }) => current === length - 1,
  })

  const $canGoNext = $isLast.map(
    (isLast) => boundaryBehavior === "cycle" || !isLast
  )

  const $canGoPrev = $isFirst.map(
    (isFirst) => boundaryBehavior === "cycle" || !isFirst
  )

  return {
    length,
    $currentIndex,
    $currentStep,
    $isFirst,
    $isLast,
    $canGoNext,
    $canGoPrev,
    $lastDirection,
    goNext,
    goPrev,
    goAt,
  }
}
