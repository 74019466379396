import * as D from "io-ts/Decoder"

import { LocalizedString } from "@/domains/localized-strings/model"
import { UrlSlug } from "@/domains/url-slug"

export const Tag = D.struct({
  id: D.string,
  name: LocalizedString,
  urlSlug: D.nullable(UrlSlug),
})

export type Tag = D.TypeOf<typeof Tag>

export const hrefOf = (tag: Pick<Tag, "id" | "urlSlug">) =>
  `/tags/${tag.urlSlug ?? tag.id}`
