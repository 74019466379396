import * as CollapsiblePrimitive from "@radix-ui/react-collapsible"
import CaretIcon from "remixicon-react/ArrowDownSLineIcon"

import { keyframes, styled } from "@/stitches"

const open = keyframes({
  from: { height: 0, opacity: 0 },
  to: { height: "var(--radix-collapsible-content-height)", opacity: 1 },
})

const close = keyframes({
  from: { height: "var(--radix-collapsible-content-height)", opacity: 1 },
  to: { height: 0, opacity: 0 },
})

export const Caret = styled(CaretIcon, {
  transition: "transform 120ms ease-out",
  width: "1.5rem",
  height: "1.5rem",

  [`[data-state="closed"] &`]: {
    transform: "rotate(-90deg)",
  },
})

export const Collapsible = styled(CollapsiblePrimitive.Collapsible)

export const Root = styled(CollapsiblePrimitive.Root, {
  ".line-vertical": {
    transformOrigin: "center",
    transform: "rotate(-90deg)",
    transition: "transform 120ms",
  },

  '&[data-state="closed"] .group:hover .line-vertical': {
    transform: "rotate(-70deg)",
  },

  '&[data-state="open"] .line-vertical': {
    transform: "none",
  },

  variants: {
    bordered: {
      true: {
        "&:first-of-type": {
          borderTop: "1px solid $divider",
        },
        borderBottom: "1px solid $divider",
      },
    },
  },
})

export const Trigger = styled(CollapsiblePrimitive.Trigger, {
  "&:disabled": {
    cursor: "default",
  },
})

export const Content = styled(CollapsiblePrimitive.Content, {
  position: "relative",
  overflow: "hidden",
  '&[data-state="open"]': {
    animation: `${open} 120ms ease-out`,
  },
  '&[data-state="closed"]': {
    animation: `${close} 120ms ease-out`,
  },
  variants: {
    compensateOverflow: {
      true: {
        px: "$1",
        pb: "$1",
        mx: "-$1",
        mb: "-$1",
      },
    },
  },
})

export function Indicator() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="1rem">
      <line
        x1="0"
        y1="12"
        x2="24"
        y2="12"
        strokeWidth={4}
        stroke="currentColor"
      />
      <line
        className="line-vertical"
        x1="0"
        y1="12"
        x2="24"
        y2="12"
        strokeWidth={4}
        stroke="currentColor"
      />
    </svg>
  )
}
