import { pipe } from "fp-ts/function"
import { z } from "zod"

import {
  DeliveryAddressFormSchema,
  InvoiceAddressFormSchema,
} from "@/domains/addresses/form"
import { PasswordSchema, requirePasswordsSame } from "@/domains/password/form"
import { phone } from "@/domains/phone/form"

export const CredentialsFormSchema = pipe(
  z.object({
    email: z.string().nonempty().email(),
    password: PasswordSchema,
    passwordConfirm: z.string(),
    acceptTermsAndConditions: z.literal(true),
    acceptAuctionRules: z.literal(true),
    acceptTermsOfUse: z.literal(true),
  }),
  requirePasswordsSame((data) => data.password === data.passwordConfirm, {
    path: ["passwordConfirm"],
  })
)

export type CredentialsFormSchema = z.TypeOf<typeof CredentialsFormSchema>

export const ContactDetailsFormSchema = z.object({
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  phone: phone(),
})

export type ContactDetailsFormSchema = z.TypeOf<typeof ContactDetailsFormSchema>

export const RegisterFormSchema = z.object({
  credentials: CredentialsFormSchema,
  contactDetails: ContactDetailsFormSchema,
  invoiceAddress: InvoiceAddressFormSchema,
  deliveryAddress: DeliveryAddressFormSchema,
})

export type RegisterFormSchema = z.TypeOf<typeof RegisterFormSchema>
