import { either } from "fp-ts"
import { Either } from "fp-ts/Either"
import { HTTPError } from "ky"
import { UseMutationResult, useMutation } from "react-query"

import { env } from "@/config/env"
import { verificationUrl } from "@/domains/auth/verify-email"
import { openErrorToast } from "@/domains/toasts/store"
import { useHttpClient } from "@/lib/http"

import { UserCustomData, UserSettings } from "../../users/model"

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------

export type RegisterData = {
  email: string
  password: string
  firstName: string
  lastName: string
  phone: string
  settings: UserSettings
  customData: UserCustomData
}

export type RegisterFailure = { type: "user_exists" }

function useRegisterApi() {
  const client = useHttpClient()

  return {
    register: (data: RegisterData): Promise<Either<RegisterFailure, void>> =>
      client
        .post("register", {
          json: {
            client_id: env.CLIENT_ID,
            client_secret: env.CLIENT_SECRET,
            verificationUrl: verificationUrl(),
            appCode: env.APP_CODE,
            ...data,
          },
        })
        .then(() => either.right(undefined))
        .catch((error) => {
          if (error instanceof HTTPError) {
            switch (error.response.status) {
              case 409:
                return either.left({ type: "user_exists" })
            }
          }

          return Promise.reject(error)
        }),
  }
}

// -----------------------------------------------------------------------------
// Query
// -----------------------------------------------------------------------------

type UseRegisterOptions = {
  onFailure: (error: RegisterFailure) => void
}

export function useRegisterMutation({
  onFailure,
}: UseRegisterOptions): UseMutationResult<
  Either<RegisterFailure, void>,
  unknown,
  RegisterData
> {
  const api = useRegisterApi()

  return useMutation(api.register, {
    onError(error) {
      openErrorToast(error)
    },
    onSuccess(result) {
      if (either.isLeft(result)) {
        onFailure(result.left)
      }
    },
  })
}
