import * as PopoverPrimitive from "@radix-ui/react-popover"
import { ReactNode, useState } from "react"
import ChevronDownIcon from "remixicon-react/ArrowDownSLineIcon"
import CheckIcon from "remixicon-react/CheckLineIcon"
import Search2LineIcon from "remixicon-react/Search2LineIcon"

import { CSS, styled } from "@/stitches"
import * as Popover from "@/ui/popover"
import * as Select from "@/ui/select"

import { Input } from "./input"

// -----------------------------------------------------------------------------
// View
// -----------------------------------------------------------------------------

const StyledTrigger = styled(Popover.Trigger, Select.triggerStyle, {
  gap: "$4",
  variants: {
    placeholder: {
      true: {
        color: "$gray11",
      },
    },
  },
})

const StyledTriggerContent = styled("span", {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})

const StyledAddonBefore = styled("span", Select.addonBeforeStyle)

const StyledIcon = styled("span", Select.caretStyle)

const StyledContent = styled(Popover.ContentBase, {
  display: "flex",
  flexDirection: "column",
  '&[data-side="top"]': { flexDirection: "column-reverse" },

  variants: {
    density: {
      dense: { "--density-factor": 0.75 },
      normal: { "--density-factor": 1 },
    },
  },

  defaultVariants: {
    density: "normal",
  },
})

const StyledItemList = styled("ul", {
  maxHeight: "24rem",
  py: "$2",
  overflowY: "auto",
})

const StyledItem = styled("button", Select.itemStyle, {
  width: "100%",
  boxSizing: "border-box",
  pr: 0,
})

const StyledItemIndicator = styled("span", Select.itemIndicatorStyle)

function AutocompleteTrigger(props: {
  disabled?: boolean
  children: ReactNode
  css?: CSS
  placeholder?: ReactNode
  addonBefore?: ReactNode
}) {
  const { disabled = false, css } = props

  return (
    <StyledTrigger {...{ disabled, css }} isPlaceholder={!props.children}>
      {props.addonBefore && (
        <StyledAddonBefore>{props.addonBefore}</StyledAddonBefore>
      )}
      <StyledTriggerContent>
        {props.children ?? props.placeholder}
      </StyledTriggerContent>
      <StyledIcon>
        <ChevronDownIcon size="1.25em" />
      </StyledIcon>
    </StyledTrigger>
  )
}

type AutocompleteItemProps = {
  children: ReactNode
  selected: boolean
  onClick: () => void
}

function AutocompleteItem(props: AutocompleteItemProps) {
  return (
    <StyledItem onClick={() => props.onClick()}>
      {props.selected && (
        <StyledItemIndicator>
          <CheckIcon size="1.25em" />
        </StyledItemIndicator>
      )}
      {props.children}
    </StyledItem>
  )
}

type AutocompleteBase<Item> = {
  items: Array<Item>
  filterPredicate: (search: string, item: Item) => boolean
  keyOf: (item: Item) => string
  viewOf: (item: Item) => ReactNode
  children?: ReactNode
}

export type SingleAutocompleteProps<Item> = AutocompleteBase<Item> & {
  multiselect: false
  value: Item | null
  onValueChange: (value: Item) => void
}

export type MultiAutocompleteProps<Item> = AutocompleteBase<Item> & {
  multiselect: true
  value: Array<Item>
  onValueChange: (value: Array<Item>) => void
}

export type AutocompleteProps<Item> =
  | SingleAutocompleteProps<Item>
  | MultiAutocompleteProps<Item>

function AutocompleteRoot<Item>(props: AutocompleteProps<Item>) {
  const { items, keyOf } = props
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")

  const filteredItems = items.filter((item) =>
    props.filterPredicate(search, item)
  )

  const onSelect = (selectedItem: Item) => {
    if (props.multiselect) {
      if (props.value.map(keyOf).includes(keyOf(selectedItem))) {
        props.onValueChange(
          props.value.filter((item) => keyOf(item) !== keyOf(selectedItem))
        )
      } else {
        props.onValueChange([...props.value, selectedItem])
      }
    } else {
      props.onValueChange(selectedItem)
      setOpen(false)
      setSearch("")
    }
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      {props.children}
      <PopoverPrimitive.Portal>
        <StyledContent align="start" sideOffset={5}>
          <Input
            type="text"
            css={{
              width: "100%",
              flexGrow: 4,
              "[data-side='bottom'] &:not(:focus-within)": {
                borderLeft: 0,
                borderRight: 0,
                borderTop: 0,
                borderBottomColor: "$divider",
              },
              "[data-side='top'] &:not(:focus-within)": {
                borderLeft: 0,
                borderRight: 0,
                borderBottom: 0,
                borderTopColor: "$divider",
              },
            }}
            addonAfter={<Search2LineIcon size="1.25em" />}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <StyledItemList>
            {filteredItems.map((item) => (
              <AutocompleteItem
                key={keyOf(item)}
                onClick={() => onSelect(item)}
                selected={
                  props.multiselect
                    ? props.value.map(keyOf).includes(keyOf(item))
                    : props.value !== null && keyOf(props.value) === keyOf(item)
                }
              >
                {props.viewOf(item)}
              </AutocompleteItem>
            ))}
          </StyledItemList>
        </StyledContent>
      </PopoverPrimitive.Portal>
    </Popover.Root>
  )
}

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

/**
 * Anatomy:
 *
 * - Root
 *   - Trigger (1) / or Popover.Trigger for custom styling
 *   - Content (1)
 *     - SearchInput (1)
 *     - ItemList (1)
 *       - Item (n)
 */

export const Root = AutocompleteRoot
export const Trigger = AutocompleteTrigger
