import * as D from "io-ts/Decoder"

import { Eur } from "@/domains/money"
import { DateFromUnknown } from "@/lib/date"
import { Uuid } from "@/lib/uuid"

export const Bid = D.struct({
  amount: Eur,
  id: Uuid,
  createdAt: DateFromUnknown,
  currentPrice: Eur,
  userId: Uuid,
  userSimpleId: D.string,
  isLimitBid: D.boolean,
})

export type Bid = D.TypeOf<typeof Bid>
