import { i18n } from "@lingui/core"
import { t } from "@lingui/macro"
import RulerIcon from "remixicon-react/RulerLineIcon"

import { LengthUnit, lengthUnitOptions } from "@/domains/units/model"
import { Field } from "@/ui/form/field2"
import * as Select from "@/ui/select"

export function LengthUnitSelect({
  value,
  onValueChange,
}: {
  value: LengthUnit
  onValueChange: (unit: LengthUnit) => void
}) {
  return (
    <Field
      label={t({
        id: "settings.lengthUnits",
        message: "Jednotky",
      })}
      inputProps={{ name: "currency" }}
      renderInput={(props) => (
        <Select.Root {...props} value={value} onValueChange={onValueChange}>
          <Select.Trigger addonBefore={<RulerIcon size="1.25em" />} />
          <Select.Content>
            {lengthUnitOptions.map((option) => (
              <Select.Item key={option.value} value={option.value}>
                {i18n._(option.display)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      )}
    />
  )
}
