import * as D from "io-ts/Decoder"

import { LocalizedString } from "@/domains/localized-strings/model"

export const Technique = D.struct({
  id: D.string,
  name: LocalizedString,
})

export type Technique = D.TypeOf<typeof Technique>
