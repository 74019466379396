import { option } from "fp-ts"
import { Option } from "fp-ts/Option"
import { pipe } from "fp-ts/function"
import parsePhoneNumber from "libphonenumber-js"

export type Phone = {
  countryCallingCode: string
  nationalNumber: string
}

export const phoneFromString = (phone: string): Option<Phone> =>
  pipe(
    parsePhoneNumber(phone),
    option.fromNullable,
    option.map((parsed) => ({
      countryCallingCode: "+" + parsed.countryCallingCode,
      nationalNumber: parsed.nationalNumber,
    }))
  )

export const phoneToString = (phone: Phone): string =>
  `${phone.countryCallingCode}${phone.nationalNumber}`

// TODO determine calling code according to locale
export const getDefaultPhone = (): Phone => ({
  countryCallingCode: "+420",
  nationalNumber: "",
})
