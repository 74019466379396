import { t } from "@lingui/macro"

import { CountrySelect } from "@/domains/country-options/view/select"
import { Form } from "@/lib/form"
import { Field } from "@/ui/form/field2"
import { Grid } from "@/ui/grid"

import { AddressesFormSchema } from "../form"

export function InvoiceAddressForm({
  form,
}: {
  form: Form<AddressesFormSchema>
}) {
  return (
    <Grid gap={4} columns={{ "@initial": 1, "@md": 6 }}>
      <Field
        label={t({
          id: "invoiceAddressForm.name",
          message: "Jméno a příjmení",
        })}
        inputProps={{ ...form.register("invoiceAddress.recipient") }}
        error={form.formState.errors.invoiceAddress?.recipient?.message}
        css={{ "@md": { gridColumn: "span 6" } }}
      />
      <Field
        label={t({
          id: "invoiceAddressForm.street",
          message: "Ulice",
        })}
        inputProps={{ ...form.register("invoiceAddress.street") }}
        error={form.formState.errors.invoiceAddress?.street?.message}
        css={{ "@md": { gridColumn: "span 4" } }}
      />
      <Field
        label={t({
          id: "invoiceAddressForm.houseNumber",
          message: "Číslo popisné",
        })}
        inputProps={{
          ...form.register("invoiceAddress.houseNumber"),
        }}
        error={form.formState.errors.invoiceAddress?.houseNumber?.message}
        css={{ "@md": { gridColumn: "span 2" } }}
      />
      <Field
        label={t({
          id: "invoiceAddressForm.city",
          message: "Město",
        })}
        inputProps={{ ...form.register("invoiceAddress.city") }}
        error={form.formState.errors.invoiceAddress?.city?.message}
        css={{ "@md": { gridColumn: "span 4" } }}
      />
      <Field
        label={t({
          id: "invoiceAddressForm.postalCode",
          message: "PSČ",
        })}
        inputProps={{ ...form.register("invoiceAddress.zip") }}
        error={form.formState.errors.invoiceAddress?.zip?.message}
        css={{ "@md": { gridColumn: "span 2" } }}
      />
      <Field
        label={t({
          id: "invoiceAddressForm.country",
          message: "Země",
        })}
        inputProps={{ ...form.register("invoiceAddress.country") }}
        renderInput={(inputProps) => <CountrySelect {...inputProps} />}
        error={form.formState.errors.invoiceAddress?.country?.message}
        css={{ "@md": { gridColumn: "span 6" } }}
      />
    </Grid>
  )
}
