import { z } from "zod"

const AddressFormSchema = z.object({
  recipient: z.string().nonempty(),
  street: z.string().nonempty(),
  houseNumber: z.string().nonempty(),
  city: z.string().nonempty(),
  zip: z.string().nonempty(),
  country: z.string().nonempty(),
})

const FirmAddressFormSchema = AddressFormSchema.extend({
  company: z.string().nonempty(),
  cin: z.string().nonempty(),
  vat: z.string().nonempty(),
})

export const InvoiceAddressFormSchema = z.discriminatedUnion("isFirm", [
  z.object({
    isFirm: z.literal(false),
    ...AddressFormSchema.shape,
  }),
  z.object({
    isFirm: z.literal(true),
    ...FirmAddressFormSchema.shape,
  }),
])

export type InvoiceAddressFormSchema = z.TypeOf<typeof InvoiceAddressFormSchema>

export const DeliveryAddressFormSchema = z.discriminatedUnion(
  "isSameAsInvoiceAddress",
  [
    z.object({ isSameAsInvoiceAddress: z.literal(true) }),
    z.object({
      isSameAsInvoiceAddress: z.literal(false),
      ...AddressFormSchema.shape,
    }),
  ]
)

export type DeliveryAddressFormSchema = z.TypeOf<
  typeof DeliveryAddressFormSchema
>

export const AddressesFormSchema = z.object({
  invoiceAddress: InvoiceAddressFormSchema,
  deliveryAddress: DeliveryAddressFormSchema,
})

export type AddressesFormSchema = z.TypeOf<typeof AddressesFormSchema>
