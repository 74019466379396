import * as D from "io-ts/Decoder"
import { useRouter } from "next/router"
import { useQuery } from "react-query"
import { z } from "zod"

import { Century } from "@/domains/centuries/model"
import { CountryId } from "@/domains/countries"
import { PaginationParams, PaginationResult } from "@/domains/pagination/model"
import { useSession } from "@/domains/session/store"
import { SimpleId } from "@/domains/simple-id"
import { UrlSlug } from "@/domains/url-slug"
import { createParams, expectJson, useHttpClient } from "@/lib/http"
import { useParsedQuery } from "@/lib/router"
import { Uuid } from "@/lib/uuid"

import {
  AuctionDetail,
  AuctionPreview,
  AuthAuctionDetail,
  AuthAuctionPreview,
  Sorting,
  auctionParticipantStatusOf,
} from "./model"

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------

type AuctionFieldset = "auction_preview"

const defaultFieldset: AuctionFieldset = "auction_preview"

type GetAuctionsParams = {
  fieldset?: AuctionFieldset
  currentAuctionBlock?: boolean
  auctionBlock?: Uuid | Array<Uuid>
  tag?: Uuid | UrlSlug
  country?: CountryId | UrlSlug
  century?: Century
  isFinished?: boolean
  author?: Uuid | SimpleId
  noAuthor?: boolean
}

type GetAuctionSortingParams = {
  sortField?: "currentPrice"
  sortDescending?: boolean
}

const getSortParams = (sorting?: Sorting): GetAuctionSortingParams => {
  if (sorting === "priceAsc") {
    return { sortField: "currentPrice", sortDescending: false }
  }

  if (sorting === "priceDesc") {
    return { sortField: "currentPrice", sortDescending: true }
  }

  return {}
}

function useAuctionsApi() {
  const client = useHttpClient()
  const { authenticated } = useSession()

  return {
    getAll: ({
      fieldset = defaultFieldset,
      auctionBlock,
      ...params
    }: GetAuctionsParams & GetAuctionSortingParams = {}) =>
      client
        .get(`aig/auctions`, {
          searchParams: createParams({
            fieldset,
            auctionBlock: Array.isArray(auctionBlock)
              ? auctionBlock.join(",")
              : auctionBlock,
            ...params,
          }),
        })
        .then(expectJson(D.array(AuctionPreview))),

    getAllPaginated: ({
      fieldset = defaultFieldset,
      auctionBlock,
      ...params
    }: GetAuctionsParams & GetAuctionSortingParams & PaginationParams) =>
      client
        .get(`aig/auctions`, {
          searchParams: createParams({
            fieldset,
            auctionBlock: Array.isArray(auctionBlock)
              ? auctionBlock.join(",")
              : auctionBlock,
            ...params,
          }),
        })
        .then(expectJson(PaginationResult(AuctionPreview))),

    getRecords: () =>
      client
        .get(`aig/auctions/records`)
        .then(expectJson(D.array(AuctionPreview))),

    getRecommended: ({
      fieldset = defaultFieldset,
    }: {
      fieldset?: AuctionFieldset
    } = {}) =>
      client
        .get(`aig/auctions/recommended`, {
          searchParams: createParams({ fieldset }),
        })
        .then(expectJson(D.array(AuctionPreview))),

    getMine: () =>
      client
        .get(`aig/auctions/mine`)
        .then(expectJson(D.array(AuthAuctionPreview))),

    get: (id: Uuid | SimpleId) =>
      client
        .get(`aig/auctions/${id}`)
        .then(expectJson(authenticated ? AuthAuctionDetail : AuctionDetail)),
  }
}

// -----------------------------------------------------------------------------
// Query hooks
// -----------------------------------------------------------------------------

type AuctionQueryOptions = GetAuctionsParams & {
  sorting: Sorting
  page: number
  itemsPerPage?: number
}

export function useAuctionsQuery(options: AuctionQueryOptions) {
  const { page, itemsPerPage = 24, sorting, ...params } = options
  const { token } = useSession()
  const api = useAuctionsApi()

  return useQuery<PaginationResult<AuctionPreview>>({
    queryKey: ["auctions", "list", { token, ...options }],
    queryFn: () =>
      api.getAllPaginated({
        ...params,
        ...getSortParams(sorting),
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      }),
  })
}

export function useAuctionsByTagQuery(tagId: Uuid | UrlSlug | undefined) {
  const { token } = useSession()
  const api = useAuctionsApi()

  return useQuery<Array<AuctionPreview>>({
    queryKey: ["auctions", "list", { token, tagId }],
    queryFn: () =>
      tagId === undefined ? Promise.reject() : api.getAll({ tag: tagId }),
    enabled: tagId !== undefined,
  })
}

export function useAuctionRecordsQuery() {
  const api = useAuctionsApi()

  return useQuery<Array<AuctionPreview>>({
    queryKey: ["auctions", "list", "records"],
    queryFn: api.getRecords,
  })
}

export function useRecommendedAuctionsQuery() {
  const api = useAuctionsApi()

  return useQuery<Array<AuctionPreview>>({
    queryKey: ["auctions", "list", "recommended"],
    queryFn: () => api.getRecommended(),
    staleTime: 0,
    refetchInterval: 10 * 1000,
  })
}

export function useAuctionsOfAuthorQuery(
  authorId: SimpleId | Uuid | undefined
) {
  const api = useAuctionsApi()

  return useQuery<Array<AuctionPreview>>({
    queryKey: ["auctions", "author", authorId],
    queryFn: () => api.getAll({ author: authorId }),
    enabled: !!authorId,
  })
}

export function useAuctionsOfUknownAuthorQuery() {
  const api = useAuctionsApi()

  return useQuery<Array<AuctionPreview>>({
    queryKey: ["auctions", "author", "uknown"],
    queryFn: () => api.getAll({ noAuthor: true }),
  })
}

export function useMyAuctionsQuery({
  filter,
}: {
  filter?: (auction: AuthAuctionPreview) => boolean
}) {
  const { authenticated, token } = useSession()
  const api = useAuctionsApi()

  return useQuery<Array<AuthAuctionPreview>>({
    queryKey: ["auctions", "list", "mine", { token }],
    queryFn: api.getMine,
    enabled: authenticated,
    select: filter ? (data) => data.filter(filter) : undefined,
  })
}

export function useMyWonAuctionsQuery() {
  const session = useSession()

  return useMyAuctionsQuery({
    filter: (auction) =>
      session.authenticated
        ? auction.isFinished &&
          !auction.isOrdered &&
          auctionParticipantStatusOf(session.userId)(auction) === "leading"
        : false,
  })
}

export function useAuctionDetailQuery() {
  const { query } = useRouter()
  const { token } = useSession()
  const { id } = z.object({ id: z.string().optional() }).parse(query)
  const api = useAuctionsApi()

  const parsedQuery = useParsedQuery(
    D.struct({
      id: D.union(Uuid, SimpleId),
    })
  )

  return useQuery<AuctionDetail | AuthAuctionDetail>({
    queryKey: ["auctions", "detail", { id, token }],
    queryFn: () =>
      parsedQuery === null ? Promise.reject() : api.get(parsedQuery.id),
    enabled: parsedQuery !== null,
    staleTime: 0,
    refetchInterval: 10 * 1000,
  })
}
