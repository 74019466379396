import * as D from "io-ts/Decoder"

export type PaginationParams = {
  offset: number
  limit: number
}

export const PaginationResult = <A>(data: D.Decoder<unknown, A>) =>
  D.struct({
    totalItems: D.number,
    data: D.array(data),
  })

export type PaginationResult<A> = {
  totalItems: number
  data: Array<A>
}
