/**
 * Reference implementation: https://github.com/vercel/next.js/tree/master/examples/with-lingui
 */

module.exports = {
  locales: ["en", "cs", "de"],
  sourceLocale: "cs",
  catalogs: [
    {
      path: "locales/{locale}/messages",
      include: ["src"],
    },
  ],
  format: "po",
}
