import { i18n } from "@lingui/core"
import { t } from "@lingui/macro"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { ArtworkImage, displayImageType } from "@/domains/artwork-images/model"
import { Centuries } from "@/domains/centuries/model"
import { Country } from "@/domains/countries"
import { Dimensions } from "@/domains/dimensions/model"
import { UploadedImageFile } from "@/domains/files/model"
import { LocalizedString } from "@/domains/localized-strings/model"
import { SimpleId } from "@/domains/simple-id"
import { Tag } from "@/domains/tags/model"
import { Technique } from "@/domains/techniques/model"
import { UrlSlug } from "@/domains/url-slug"
import { Uuid } from "@/lib/uuid"

export const Signature = D.struct({
  id: Uuid,
  name: LocalizedString,
})

export const ArtworkProps = D.struct({
  name: LocalizedString,
  originalName: D.string,
  techniques: D.array(Technique),
  dimensions: Dimensions,
  images: D.array(ArtworkImage),
  previewImage: D.nullable(UploadedImageFile),
  urlSlug: UrlSlug,
})

export const ArtworkPreview = pipe(
  ArtworkProps,
  D.intersect(
    D.struct({
      author: D.nullable(
        D.struct({
          name: D.string,
        })
      ),
    })
  )
)

export type ArtworkPreview = D.TypeOf<typeof ArtworkPreview>

export const AuthorProps = D.struct({
  id: D.string,
  name: D.string,
})

export const ArtworkDetail = pipe(
  ArtworkProps,
  D.intersect(
    D.struct({
      artNumber: D.number,
      author: D.nullable(
        D.struct({
          id: Uuid,
          name: D.string,
          country: D.nullable(Country),
          centuries: D.nullable(Centuries),
          urlSlug: UrlSlug,
          simpleId: SimpleId,
        })
      ),
      tags: D.array(Tag),
      year: D.nullable(D.string),
      description: LocalizedString,
      id: Uuid,
      signature: D.nullable(Signature),
      certificate: D.nullable(D.string),
    })
  )
)

export type ArtworkDetail = D.TypeOf<typeof ArtworkDetail>

export const nameOf = (artwork: {
  name: LocalizedString
  originalName: string
}) => artwork.name[i18n.locale] ?? artwork.originalName

export const previewImageOf = (artwork: {
  previewImage: UploadedImageFile | null
  images: Array<{ file: UploadedImageFile }>
}): UploadedImageFile | undefined =>
  artwork.previewImage ?? artwork.images[0]?.file

export const imageAltOf = (
  artwork: {
    name: LocalizedString
    originalName: string
    author: { name: string } | null
  },
  image?: ArtworkImage
): string => {
  const artworkName = nameOf(artwork)
  const authorName = artwork.author?.name ?? t`author.unknown`
  const imageType = image ? displayImageType(image.type) : undefined

  return [`${authorName}: ${artworkName}`, imageType]
    .filter(Boolean)
    .join(" – ")
}
