import * as Either from "fp-ts/Either"
import { HTTPError } from "ky"
import { UseMutationResult, useMutation } from "react-query"

import { useHttpClient } from "@/lib/http"

import { openErrorToast } from "../toasts/store"

export type Token = string

export type EmailVerificationFailure = { type: "already_verified" }

export const verificationUrl = () =>
  `${window.location.origin}?verifyEmailToken={token}`

function useVerifyEmailApi() {
  const client = useHttpClient()

  return {
    verifyEmail: (
      token: Token
    ): Promise<Either.Either<EmailVerificationFailure, void>> =>
      client
        .post(`verify-email-token/${token}`)
        .then(() => Either.right(undefined))
        .catch((error) => {
          if (error instanceof HTTPError && error.response.status === 404) {
            return Either.left({ type: "already_verified" })
          }

          return Promise.reject(error)
        }),
  }
}

export function useVerifyEmailMutation(): UseMutationResult<
  Either.Either<EmailVerificationFailure, void>,
  unknown,
  Token
> {
  const api = useVerifyEmailApi()

  return useMutation(api.verifyEmail, {
    onError(error) {
      openErrorToast(error)
    },
  })
}
