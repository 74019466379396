import { useRouter } from "next/router"

import {
  useMeQuery,
  useUpdateUserSettingsMutation,
} from "@/domains/users/query"

export function useSetLocale() {
  const router = useRouter()

  return (locale: string): void => {
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale })
  }
}

export function useUpdateLocale() {
  const { data: me } = useMeQuery()
  const { mutate } = useUpdateUserSettingsMutation()
  const setLocale = useSetLocale()

  return (locale: string): void => {
    setLocale(locale)

    if (me) {
      mutate({
        ...me.settings,
        locale,
      })
    }
  }
}
