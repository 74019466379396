import { ComponentProps, ReactNode } from "react"

import { styled } from "@/stitches"
import { Text } from "@/ui/text"

import { Box } from "./box"
import { Stack } from "./stack"

const Root = styled("article", {
  borderLeft: "3px solid transparent",
  padding: "$4",
  variants: {
    variant: {
      neutral: {
        bg: "$grayA3",
        borderColor: "$grayA6",
        color: "$slate11",
      },
      accent: {
        bg: "$accentA3",
        borderColor: "$accentA6",
        color: "$accent10",
        "--text-color-hover": "$colors$accent12",
        "--underline-color": "$colors$accentA6",
      },
      gold: {
        bg: "$amberA3",
        borderColor: "$amberA6",
        color: "$amber11",
        "--text-color-hover": "$colors$amber12",
        "--underline-color": "$colors$amberA6",
      },
      success: {
        bg: "$tealA3",
        borderColor: "$tealA6",
        color: "$tealA11",
      },
      danger: {
        bg: "$redA3",
        borderColor: "$redA6",
        color: "$redA11",
      },
      info: {
        bg: "hsl(228 100% 51% / 20%)",
        borderColor: "hsl(228 100% 51% / 50%)",
        color: "hsl(228 100% 10% / 100%)",
        "--text-color-hover": "hsl(228 100% 90% / 80%)",
        "--underline-color": "hsl(228 100% 90% / 60%)",

        ".dark-theme &": {
          color: "hsl(228 100% 90% / 100%)",
        },
      },
    },
  },
  defaultVariants: {
    variant: "accent",
  },
})

type CalloutProps = Omit<ComponentProps<typeof Root>, "title"> & {
  title?: ReactNode
  icon?: ReactNode
  children?: ReactNode
  actions?: ReactNode
}

export function Callout({
  title,
  icon,
  children,
  actions,
  ...variantProps
}: CalloutProps) {
  return (
    <Root {...variantProps}>
      <Stack gap={6} align="center">
        {icon && <Box css={{ flexShrink: 0 }}>{icon}</Box>}
        <Stack direction="column" gap={1}>
          {title && (
            <Text as="header" weight="bold" size="lg">
              {title}
            </Text>
          )}
          {children && (
            <Text as="div" weight="medium" size="sm" className="content">
              {children}
            </Text>
          )}
        </Stack>
      </Stack>
      {actions && (
        <Stack gap={2} css={{ mt: "$6" }}>
          {actions}
        </Stack>
      )}
    </Root>
  )
}
