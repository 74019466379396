import { t } from "@lingui/macro"

import { CountrySelect } from "@/domains/country-options/view/select"
import { Form } from "@/lib/form"
import { CSS } from "@/stitches"
import { Field } from "@/ui/form/field2"
import { Grid } from "@/ui/grid"

import { AddressesFormSchema } from "../form"

export function DeliveryAddressForm({
  form,
  css,
  allowedCountries,
}: {
  form: Form<AddressesFormSchema>
  css?: CSS
  allowedCountries?: Array<string>
}) {
  return (
    <Grid gap={4} columns={{ "@initial": 1, "@md": 6 }} css={css}>
      <Field
        label={t({
          id: "deliveryAddressForm.recipient",
          message: "Příjemce (jméno, firma)",
        })}
        inputProps={{ ...form.register("deliveryAddress.recipient") }}
        error={form.errors.deliveryAddress?.recipient?.message}
        css={{ "@md": { gridColumn: "span 6" } }}
      />
      <Field
        label={t({
          id: "deliveryAddressForm.street",
          message: "Ulice",
        })}
        inputProps={{ ...form.register("deliveryAddress.street") }}
        error={form.errors.deliveryAddress?.street?.message}
        css={{ "@md": { gridColumn: "span 4" } }}
      />
      <Field
        label={t({
          id: "deliveryAddressForm.houseNumber",
          message: "Číslo popisné",
        })}
        inputProps={{
          ...form.register("deliveryAddress.houseNumber"),
        }}
        error={form.errors.deliveryAddress?.houseNumber?.message}
        css={{ "@md": { gridColumn: "span 2" } }}
      />
      <Field
        label={t({
          id: "deliveryAddressForm.city",
          message: "Město",
        })}
        inputProps={{ ...form.register("deliveryAddress.city") }}
        error={form.errors.deliveryAddress?.city?.message}
        css={{ "@md": { gridColumn: "span 4" } }}
      />
      <Field
        label={t({
          id: "deliveryAddressForm.postalCode",
          message: "PSČ",
        })}
        inputProps={{ ...form.register("deliveryAddress.zip") }}
        error={form.errors.deliveryAddress?.zip?.message}
        css={{ "@md": { gridColumn: "span 2" } }}
      />
      <Field
        label={t({
          id: "deliveryAddressForm.country",
          message: "Země",
        })}
        inputProps={{ ...form.register("deliveryAddress.country") }}
        renderInput={(inputProps) => (
          <CountrySelect {...inputProps} allowedCountries={allowedCountries} />
        )}
        error={form.errors.deliveryAddress?.country?.message}
        css={{ "@md": { gridColumn: "span 6" } }}
      />
    </Grid>
  )
}
