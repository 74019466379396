import { Ref } from "react"
import ChevronDownIcon from "remixicon-react/ArrowDownSLineIcon"

import { CountryOption } from "@/domains/country-options/model"
import { useCountriesQuery } from "@/domains/country-options/query"
import { CountryCode, countryCodes } from "@/domains/phone/country-codes"
import { Phone } from "@/domains/phone/model"
import { styled } from "@/stitches"
import * as Popover from "@/ui/popover"
import { Text } from "@/ui/text"

import * as Autocomplete from "./autocomplete"
import * as Input from "./input"
import { Stack } from "./stack"

const CountryCodeTriggerButton = styled("button", {
  display: "flex",
  alignItems: "center",
  gap: "$1",
  pl: "$4",
  pr: "$2",
  mr: "-$5",
  whiteSpace: "nowrap",
  "@animation": {
    transition: "background-color 120ms",
  },
  "&:hover": {
    backgroundColor: "$gray4",
  },
  "&:disabled": {
    pointerEvents: "none",
  },
})

const countryByCode = (item: CountryCode) => (country: CountryOption) =>
  item.country === country.alpha2

type CountryCodeSelectProps = {
  disabled: boolean
  value: string
  onValueChange: (value: string) => void
}

function CountryCodeSelect(props: CountryCodeSelectProps) {
  const { disabled } = props
  const countriesQuery = useCountriesQuery()

  return (
    <Autocomplete.Root
      multiselect={false}
      items={countryCodes}
      value={countryCodes.find((item) => item.code === props.value) ?? null}
      onValueChange={(item) => props.onValueChange(item.code)}
      keyOf={(item) => item.country}
      filterPredicate={(search, item) => {
        const country = countriesQuery.data?.find(countryByCode(item))

        return (
          item.code?.includes(search) ||
          item.country.includes(search) ||
          (country?.name
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ??
            false)
        )
      }}
      viewOf={(item) => {
        const country = countriesQuery.data?.find(countryByCode(item))

        return (
          <Stack css={{ width: "100%" }}>
            <Text size="sm" color="muted" css={{ flexBasis: "4rem" }}>
              {item.code}
            </Text>
            {country && (
              <Text
                size="sm"
                css={{
                  flexGrow: 0,
                  flex: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {country.name}
              </Text>
            )}
          </Stack>
        )
      }}
    >
      <Popover.Trigger asChild>
        <CountryCodeTriggerButton disabled={disabled}>
          {props.value}
          <ChevronDownIcon size="1.25em" />
        </CountryCodeTriggerButton>
      </Popover.Trigger>
    </Autocomplete.Root>
  )
}

type PhoneInputProps = {
  id?: string
  value: Phone
  onChange: (value: Phone) => void
  disabled?: boolean
  innerRef?: Ref<HTMLInputElement>
}

export function PhoneInput(props: PhoneInputProps) {
  const { disabled = false } = props

  return (
    <Input.Root disabled={disabled}>
      <CountryCodeSelect
        disabled={disabled}
        value={props.value.countryCallingCode}
        onValueChange={(countryCallingCode) =>
          props.onChange({ ...props.value, countryCallingCode })
        }
      />
      <input
        {...{ disabled }}
        id={props.id}
        value={props.value.nationalNumber}
        ref={props.innerRef}
        autoComplete="tel"
        onChange={(event) =>
          props.onChange({ ...props.value, nationalNumber: event.target.value })
        }
      />
    </Input.Root>
  )
}
