import { Trans, t } from "@lingui/macro"
import { useStore } from "effector-react"
import { either, option } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { useRouter } from "next/router"
import { ReactNode, useEffect } from "react"
import CheckIcon from "remixicon-react/CheckLineIcon"
import LockIcon from "remixicon-react/LockLineIcon"
import MailIcon from "remixicon-react/MailLineIcon"
import MailUnreadIcon from "remixicon-react/MailUnreadLineIcon"
import { z } from "zod"

import { useLoginMutation } from "@/domains/auth/login"
import { useResendVerificationEmailMutation } from "@/domains/auth/resend-verification-email"
import { useVerifyEmailMutation } from "@/domains/auth/verify-email"
import { useForm } from "@/lib/form"
import { Box } from "@/ui/box"
import { Button } from "@/ui/button"
import { Callout } from "@/ui/callout"
import * as Dialog from "@/ui/dialog"
import { FormField } from "@/ui/form/field"
import { Input } from "@/ui/input"
import { Stack } from "@/ui/stack"
import { Text, TextButton } from "@/ui/text"
import { Throbber } from "@/ui/throbber"

import { $dialog, closeDialog, openDialog } from "./dialog-store"

const LoginForm = z.object({
  email: z.string().nonempty().email(),
  password: z.string().nonempty(),
})

function useDialogState() {
  const isOpen = useStore($dialog.map((dialog) => dialog === "login"))
  const onOpenChange = (open: boolean) =>
    open ? openDialog("login") : closeDialog()

  return { isOpen, onOpenChange }
}

function useEmailVerification() {
  const router = useRouter()
  const mutation = useVerifyEmailMutation()

  const token = pipe(
    D.string.decode(router.query.verifyEmailToken),
    option.fromEither,
    option.toNullable
  )

  useEffect(() => {
    if (token) {
      mutation.mutate(token)
    }
  }, [token])

  return { mutation }
}

function VerifyEmailStatus({
  mutation,
}: {
  mutation: ReturnType<typeof useVerifyEmailMutation>
}) {
  if (mutation.isLoading) {
    return (
      <Callout variant="neutral" icon={<Throbber />}>
        <Trans id="loginDialog.verifyingEmail">Ověřuji email...</Trans>
      </Callout>
    )
  }

  if (
    mutation.isSuccess &&
    (either.isRight(mutation.data) ||
      mutation.data.left.type === "already_verified")
  ) {
    return (
      <Callout
        title={
          <Trans id="loginDialog.emailVerified.title">
            Váš e-mail byl ověřen.
          </Trans>
        }
        icon={<CheckIcon size="1.5em" />}
      >
        <p>
          <Trans id="loginDialog.emailVerified.content.youCanLogin">
            Nyní se můžete přihlásit.
          </Trans>
        </p>
        <p>
          <Trans id="loginDialog.emailVerified.content.accountVerificationIsRequiredForBidding">
            Pro účast v dražbě si nezapomeňte ověřit vaši identitu v detailu
            uživatelského účtu.
          </Trans>
        </p>
      </Callout>
    )
  }

  return null
}

export function LoginDialog({ children }: { children: ReactNode }) {
  const { isOpen, onOpenChange } = useDialogState()
  const form = useForm(LoginForm)
  const verifyEmail = useEmailVerification()

  const loginMutation = useLoginMutation({
    onFailure(error) {
      if (error.type === "wrong_credentials") {
        form.setError("password", {})
        form.setError("email", {
          message: t({
            id: "loginDialog.wrongCredentials",
            message: "Uživatel neexistuje nebo jste zadali špatné heslo.",
          }),
        })
      }
      if (error.type === "not_verified") {
        form.setError("email", {
          message: t({
            id: "loginDialog.emailNotVerified",
            message: "Váš e-mail zatím není ověřený.",
          }),
        })
      }
    },
  })

  const resendVerificationEmailMutation = useResendVerificationEmailMutation()

  const onSubmit = form.handleSubmit((data) => {
    loginMutation.mutate({
      username: data.email,
      password: data.password,
    })
  })

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content size="xs" position="right" asChild>
        <form onSubmit={onSubmit}>
          <Dialog.Header>
            <Trans id="loginDialog.dialogTitle">Přihlášení</Trans>
          </Dialog.Header>
          <Dialog.Body>
            <Stack direction="column" gap={4}>
              {loginMutation.isSuccess &&
                either.isLeft(loginMutation.data) &&
                loginMutation.data.left.type === "not_verified" && (
                  <Callout
                    variant="accent"
                    icon={
                      resendVerificationEmailMutation.isSuccess ? (
                        <CheckIcon size="1.25em" />
                      ) : (
                        <MailUnreadIcon size="1.25em" />
                      )
                    }
                    title={
                      resendVerificationEmailMutation.isSuccess
                        ? t({
                            id: "loginDialog.verificationEmailResent",
                            message: "Ověřovací e-mail odeslán",
                          })
                        : t({
                            id: "loginDialog.emailNotVerifiedCallout.title",
                            message: "Váš e-mail zatím není ověřený",
                          })
                    }
                  >
                    {!resendVerificationEmailMutation.isSuccess && (
                      <Stack inline align="center" gap={2}>
                        <TextButton
                          as="button"
                          type="button"
                          disabled={resendVerificationEmailMutation.isLoading}
                          onClick={() =>
                            resendVerificationEmailMutation.mutate({
                              email: form.getValues("email"),
                            })
                          }
                        >
                          <Trans id="loginDialog.resendVerificationEmailButton.label">
                            Znovu poslat ověřovací e-mail
                          </Trans>
                        </TextButton>
                        {resendVerificationEmailMutation.isLoading && (
                          <Throbber />
                        )}
                      </Stack>
                    )}
                  </Callout>
                )}
              <VerifyEmailStatus mutation={verifyEmail.mutation} />
              <FormField
                form={form}
                name="email"
                label={t({
                  id: "loginDialog.email",
                  message: "E-mail",
                })}
                input={(props) => (
                  <Input
                    {...props}
                    type="email"
                    autoComplete="email"
                    addonBefore={<MailIcon size="1.25em" />}
                  />
                )}
              />
              <FormField
                form={form}
                name="password"
                label={t({
                  id: "loginDialog.password",
                  message: "Heslo",
                })}
                input={(props) => (
                  <Input
                    {...props}
                    type="password"
                    autoComplete="current-password"
                    addonBefore={<LockIcon size="1.25em" />}
                  />
                )}
              />
            </Stack>
            <Stack align="center" gap={4} css={{ mt: "$8" }}>
              <Button
                type="submit"
                css={{ alignSelf: "start" }}
                loading={loginMutation.isLoading}
              >
                <Trans id="loginDialog.submitButton.title">Přihlásit se</Trans>
              </Button>
              <Text
                as="button"
                type="button"
                color="muted"
                size="sm"
                link
                underline
                tabIndex={-1}
                onClick={() => openDialog("resetPassword")}
              >
                <Trans id="loginDialog.forgotPassword">
                  Zapomněli jste heslo?
                </Trans>
              </Text>
            </Stack>
            <Box as="p" css={{ mt: "$4" }}>
              <Trans id="loginDialog.registerInstead">
                Ještě nemáte účet?{" "}
                <Text
                  as="button"
                  color="accent"
                  link
                  underline
                  onClick={() => openDialog("register")}
                >
                  Zaregistrujte se
                </Text>
              </Trans>
            </Box>
          </Dialog.Body>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}
