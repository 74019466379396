import { forwardRef } from "react"

import { Select, SelectProps } from "@/ui/select-native"

import { useCountriesQuery } from "../query"

type CountrySelectProps = SelectProps & {
  allowedCountries?: Array<string>
}

const defaultOption = {
  display: "",
  value: "",
}

export const CountrySelect = forwardRef<HTMLSelectElement, CountrySelectProps>(
  function CountrySelectForwarded(props, ref) {
    const countriesQuery = useCountriesQuery()

    if (!countriesQuery.isSuccess) {
      return null
    }

    return (
      <Select
        ref={ref}
        options={[
          defaultOption,
          ...countriesQuery.data
            .filter(
              (country) =>
                props.allowedCountries === undefined ||
                props.allowedCountries.includes(country.alpha3)
            )
            .map((country) => ({
              display: country.name,
              value: country.alpha3,
            })),
        ]}
        {...props}
      />
    )
  }
)
