import { i18n } from "@lingui/core"
import { t } from "@lingui/macro"

import { Field } from "@/ui/form/field2"
import * as Select from "@/ui/select"

import { ColorScheme, colorSchemeOptions } from "../model"

export function ColorSchemeSelect({
  value,
  onValueChange,
}: {
  value: ColorScheme
  onValueChange: (scheme: ColorScheme) => void
}) {
  const selectedOption = colorSchemeOptions.find(
    (option) => option.value === value
  )

  return (
    <Field
      label={t({
        id: "settings.colorScheme",
        message: "Barevný motiv",
      })}
      inputProps={{ name: "colorScheme" }}
      renderInput={(props) => (
        <Select.Root
          {...props}
          value={value}
          onValueChange={(value) => onValueChange(value as ColorScheme)}
        >
          <Select.Trigger
            addonBefore={
              selectedOption ? <selectedOption.icon size="1.25em" /> : undefined
            }
          />
          <Select.Content>
            {colorSchemeOptions.map((option) => (
              <Select.Item key={option.value} value={option.value}>
                {i18n._(option.display)}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      )}
    />
  )
}
