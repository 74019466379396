import { createDomain } from "effector"

import { AddressesFormSchema } from "@/domains/addresses/form"
import { createSequentialNav } from "@/lib/store/sequential-nav"

import {
  ContactDetailsFormSchema,
  CredentialsFormSchema,
  RegisterFormSchema,
} from "./form"

// -----------------------------------------------------------------------------
// Step navigation
// -----------------------------------------------------------------------------

export const nav = createSequentialNav({
  length: 4,
  boundaryBehavior: "stop",
  domainName: "register/steps",
})

// -----------------------------------------------------------------------------
// Form data
// -----------------------------------------------------------------------------

const domain = createDomain("register/form")

export const setCredentials = domain.createEvent<CredentialsFormSchema>()
export const setContactDetails = domain.createEvent<ContactDetailsFormSchema>()
export const setAddresses = domain.createEvent<AddressesFormSchema>()

export const $form = domain
  .createStore<Partial<RegisterFormSchema>>({})
  .on(setCredentials, (state, credentials) => ({ ...state, credentials }))
  .on(setContactDetails, (state, contactDetails) => ({
    ...state,
    contactDetails,
  }))
  .on(setAddresses, (state, { invoiceAddress, deliveryAddress }) => ({
    ...state,
    invoiceAddress,
    deliveryAddress,
  }))
