import Head from "next/head"
import { ReactNode } from "react"

import { getTitle } from "@/lib/meta"
import { Box } from "@/ui/box"
import { Breadcrumbs } from "@/ui/breadcrumbs"
import { Container } from "@/ui/container"
import { Stack } from "@/ui/stack"

import { FetchingIndicator } from "./fetching-indicator"
import { Footer } from "./footer"
import { Header } from "./header"
import { ResetPasswordDialog } from "./reset-password-dialog"

export type LayoutProps = {
  title?: string
  children: ReactNode
  breadcrumbs?: Breadcrumbs
  absoluteHeader?: boolean
}

export function Layout({
  title,
  children,
  breadcrumbs,
  absoluteHeader = false,
}: LayoutProps) {
  return (
    <>
      {title && (
        <Head>
          <title key="title">{getTitle(title)}</title>
        </Head>
      )}
      <Stack direction="column" css={{ minHeight: "100vh" }}>
        <Header absolute={absoluteHeader} />
        <Box
          css={{
            flexGrow: 1,
            position: "relative",
            "@3xl": {
              ml: "calc(var(--left-column-width) - 1px)",
              borderLeft: "1px solid $divider",
            },
          }}
        >
          {breadcrumbs && (
            <Container
              css={{
                py: "$3",
                "@gallery1": {
                  py: "$4",
                  borderBottom: "none",
                },
                "@lg": {
                  py: "$6",
                },
              }}
            >
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </Container>
          )}
          {children}
        </Box>
        <Footer />
        <FetchingIndicator />
        <ResetPasswordDialog />
      </Stack>
    </>
  )
}
