import { i18n } from "@lingui/core"
import { defineMessage } from "@lingui/macro"
import { z } from "zod"

import { hasAtLeastOneDigit, hasLowerCase, hasUpperCase } from "@/lib/string"

// -----------------------------------------------------------------------------
// Messages
// -----------------------------------------------------------------------------

const messages = {
  mustContainAtLeastOneLowercaseLetter: defineMessage({
    id: "validation.password.mustContainAtLeastOneLowercaseLetter",
    message: "Heslo musí obsahovat alespoň jedno malé písmeno",
  }),
  mustContainAtLeastOneUppercaseLetter: defineMessage({
    id: "validation.password.mustContainAtLeastOneUppercaseLetter",
    message: "Heslo musí obsahovat alespoň jedno velké písmeno",
  }),
  mustContainAtLeastOneDigit: defineMessage({
    id: "validation.password.mustContainAtLeastOneDigit",
    message: "Heslo musí obsahovat alespoň jednu číslici",
  }),
  passwordsMustBeSame: defineMessage({
    id: "validation.password.passwordsMustBeSame",
    message: "Hesla se musí shodovat",
  }),
}

// -----------------------------------------------------------------------------
// Validation schema
// -----------------------------------------------------------------------------

export const PasswordSchema = z
  .string()
  .min(8)
  .refine(hasLowerCase, {
    message: i18n._(messages.mustContainAtLeastOneLowercaseLetter),
  })
  .refine(hasUpperCase, {
    message: i18n._(messages.mustContainAtLeastOneUppercaseLetter),
  })
  .refine(hasAtLeastOneDigit, {
    message: i18n._(messages.mustContainAtLeastOneDigit),
  })

export const requirePasswordsSame =
  <O>(
    passwordsAreEqual: (data: O) => boolean,
    { path }: { path: Array<string> }
  ) =>
  (schema: z.ZodType<O>) =>
    schema.refine(passwordsAreEqual, {
      message: i18n._(messages.passwordsMustBeSame),
      path,
    })
