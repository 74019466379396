import { ReactNode } from "react"
import UserIcon from "remixicon-react/UserLineIcon"

import { Box } from "@/ui/box"

export function UserBadge({
  icon,
  badge,
}: {
  icon?: ReactNode
  badge?: ReactNode
}) {
  return (
    <Box
      position="relative"
      css={{
        width: "3.25rem",
        height: "3.25rem",
        display: "grid",
        placeItems: "center",
        bgColor: "$gray3",
        borderRadius: 1000,
      }}
    >
      {icon ?? <UserIcon size="1.5em" />}
      {badge && (
        <Box position="absolute" css={{ right: 4, bottom: 4 }}>
          {badge}
        </Box>
      )}
    </Box>
  )
}
