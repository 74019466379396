import { either } from "fp-ts"
import { Either } from "fp-ts/Either"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { HTTPError } from "ky"
import { UseMutationResult, useMutation, useQueryClient } from "react-query"

import { env } from "@/config/env"
import { useSetLocale } from "@/domains/locale"
import { authenticate } from "@/domains/session/store"
import { openErrorToast } from "@/domains/toasts/store"
import { User } from "@/domains/users/model"
import { useUpdateUserSettingsMutation } from "@/domains/users/query"
import { setSettings } from "@/domains/website-settings/store"
import { expectJson, useHttpClient } from "@/lib/http"
import { useI18n } from "@/lib/i18n"
import { closeDialog } from "@/views/_layout/dialog-store"

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------

export type LoginData = {
  username: string
  password: string
}

const LoginResponse = pipe(
  D.struct({
    user: User,
    access_token: D.string,
  }),
  D.map(({ user, access_token }) => ({
    user,
    token: access_token,
  }))
)

export type LoginResponse = {
  user: User
  token: string
}

export type LoginFailure =
  | { type: "wrong_credentials" }
  | { type: "not_verified" }

function useLoginApi() {
  const client = useHttpClient()

  return {
    login: ({
      username,
      password,
    }: LoginData): Promise<Either<LoginFailure, LoginResponse>> =>
      client
        .post("oauth2/token", {
          json: {
            username,
            password,
            client_id: env.CLIENT_ID,
            client_secret: env.CLIENT_SECRET,
            grant_type: "password",
          },
        })
        .then(expectJson(LoginResponse))
        .then(either.right)
        .catch((error: unknown) => {
          if (error instanceof HTTPError) {
            switch (error.response.status) {
              case 403:
                return either.left({ type: "not_verified" })
              case 404:
                return either.left({ type: "wrong_credentials" })
            }
          }

          return Promise.reject(error)
        }),
  }
}

// -----------------------------------------------------------------------------
// Query
// -----------------------------------------------------------------------------

type UseLoginOptions = {
  onFailure: (error: LoginFailure) => void
}

export function useLoginMutation({
  onFailure,
}: UseLoginOptions): UseMutationResult<
  Either<LoginFailure, LoginResponse>,
  unknown,
  LoginData
> {
  const client = useQueryClient()
  const api = useLoginApi()
  const { mutate } = useUpdateUserSettingsMutation({ showToast: false })
  const { i18n } = useI18n()
  const setLocale = useSetLocale()

  return useMutation(api.login, {
    onError(error) {
      openErrorToast(error)
    },
    onSuccess(result) {
      if (either.isRight(result)) {
        const { user, token } = result.right
        client.invalidateQueries()
        client.setQueryData(["me", token], user)

        authenticate({ token, user })
        setSettings(user.settings)

        if (user.settings.locale) {
          setLocale(user.settings.locale)
        } else {
          mutate({
            ...user.settings,
            locale: i18n.locale,
          })
        }

        closeDialog()
      } else {
        onFailure(result.left)
      }
    },
  })
}
