import { readonlyNonEmptyArray } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

export type Century = number & { readonly Century: unique symbol }

export const Century = pipe(
  D.number,
  D.refine((number): number is Century => true, "Century")
)

export const Centuries = pipe(
  D.array(Century),
  D.map((centuries) => centuries.sort())
)

export const pastCenturies: readonlyNonEmptyArray.ReadonlyNonEmptyArray<Century> =
  readonlyNonEmptyArray.range(
    1,
    21
  ) as readonlyNonEmptyArray.ReadonlyNonEmptyArray<Century>

export const hrefOf = (century: Century) => `/centuries/${century}`
