export type CountryCode = {
  country: string
  code: string
}

// prettier-ignore
export const countryCodes: Array<CountryCode> = [
  { code: "+93", country: "af" },
  { code: "+355", country: "al" },
  { code: "+213", country: "dz" },
  { code: "+1 684", country: "as" },
  { code: "+376", country: "ad" },
  { code: "+244", country: "ao" },
  { code: "+1 264", country: "ai" },
  { code: "+1268", country: "ag" },
  { code: "+54", country: "ar" },
  { code: "+374", country: "am" },
  { code: "+297", country: "aw" },
  { code: "+61", country: "au" },
  { code: "+43", country: "at" },
  { code: "+994", country: "az" },
  { code: "+1 242", country: "bs" },
  { code: "+973", country: "bh" },
  { code: "+880", country: "bd" },
  { code: "+1 246", country: "bb" },
  { code: "+375", country: "by" },
  { code: "+32", country: "be" },
  { code: "+501", country: "bz" },
  { code: "+229", country: "bj" },
  { code: "+1 441", country: "bm" },
  { code: "+975", country: "bt" },
  { code: "+387", country: "ba" },
  { code: "+267", country: "bw" },
  { code: "+55", country: "br" },
  { code: "+246", country: "io" },
  { code: "+359", country: "bg" },
  { code: "+226", country: "bf" },
  { code: "+257", country: "bi" },
  { code: "+855", country: "kh" },
  { code: "+237", country: "cm" },
  { code: "+1", country: "ca" },
  { code: "+238", country: "cv" },
  { code: "+ 345", country: "ky" },
  { code: "+236", country: "cf" },
  { code: "+235", country: "td" },
  { code: "+56", country: "cl" },
  { code: "+86", country: "cn" },
  { code: "+61", country: "cx" },
  { code: "+57", country: "co" },
  { code: "+269", country: "km" },
  { code: "+242", country: "cg" },
  { code: "+682", country: "ck" },
  { code: "+506", country: "cr" },
  { code: "+385", country: "hr" },
  { code: "+53", country: "cu" },
  { code: "+537", country: "cy" },
  { code: "+420", country: "cz" },
  { code: "+45", country: "dk" },
  { code: "+253", country: "dj" },
  { code: "+1 767", country: "dm" },
  { code: "+1 849", country: "do" },
  { code: "+593", country: "ec" },
  { code: "+20", country: "eg" },
  { code: "+503", country: "sv" },
  { code: "+240", country: "gq" },
  { code: "+291", country: "er" },
  { code: "+372", country: "ee" },
  { code: "+251", country: "et" },
  { code: "+298", country: "fo" },
  { code: "+679", country: "fj" },
  { code: "+358", country: "fi" },
  { code: "+33", country: "fr" },
  { code: "+594", country: "gf" },
  { code: "+689", country: "pf" },
  { code: "+241", country: "ga" },
  { code: "+220", country: "gm" },
  { code: "+995", country: "ge" },
  { code: "+49", country: "de" },
  { code: "+233", country: "gh" },
  { code: "+350", country: "gi" },
  { code: "+30", country: "gr" },
  { code: "+299", country: "gl" },
  { code: "+1 473", country: "gd" },
  { code: "+590", country: "gp" },
  { code: "+1 671", country: "gu" },
  { code: "+502", country: "gt" },
  { code: "+224", country: "gn" },
  { code: "+245", country: "gw" },
  { code: "+595", country: "gy" },
  { code: "+509", country: "ht" },
  { code: "+504", country: "hn" },
  { code: "+36", country: "hu" },
  { code: "+354", country: "is" },
  { code: "+91", country: "in" },
  { code: "+62", country: "id" },
  { code: "+964", country: "iq" },
  { code: "+353", country: "ie" },
  { code: "+972", country: "il" },
  { code: "+39", country: "it" },
  { code: "+1 876", country: "jm" },
  { code: "+81", country: "jp" },
  { code: "+962", country: "jo" },
  { code: "+7 7", country: "kz" },
  { code: "+254", country: "ke" },
  { code: "+686", country: "ki" },
  { code: "+965", country: "kw" },
  { code: "+996", country: "kg" },
  { code: "+371", country: "lv" },
  { code: "+961", country: "lb" },
  { code: "+266", country: "ls" },
  { code: "+231", country: "lr" },
  { code: "+423", country: "li" },
  { code: "+370", country: "lt" },
  { code: "+352", country: "lu" },
  { code: "+261", country: "mg" },
  { code: "+265", country: "mw" },
  { code: "+60", country: "my" },
  { code: "+960", country: "mv" },
  { code: "+223", country: "ml" },
  { code: "+356", country: "mt" },
  { code: "+692", country: "mh" },
  { code: "+596", country: "mq" },
  { code: "+222", country: "mr" },
  { code: "+230", country: "mu" },
  { code: "+262", country: "yt" },
  { code: "+52", country: "mx" },
  { code: "+377", country: "mc" },
  { code: "+976", country: "mn" },
  { code: "+382", country: "me" },
  { code: "+1664", country: "ms" },
  { code: "+212", country: "ma" },
  { code: "+95", country: "mm" },
  { code: "+264", country: "na" },
  { code: "+674", country: "nr" },
  { code: "+977", country: "np" },
  { code: "+31", country: "nl" },
  { code: "+599", country: "an" },
  { code: "+687", country: "nc" },
  { code: "+64", country: "nz" },
  { code: "+505", country: "ni" },
  { code: "+227", country: "ne" },
  { code: "+234", country: "ng" },
  { code: "+683", country: "nu" },
  { code: "+672", country: "nf" },
  { code: "+1 670", country: "mp" },
  { code: "+47", country: "no" },
  { code: "+968", country: "om" },
  { code: "+92", country: "pk" },
  { code: "+680", country: "pw" },
  { code: "+507", country: "pa" },
  { code: "+675", country: "pg" },
  { code: "+595", country: "py" },
  { code: "+51", country: "pe" },
  { code: "+63", country: "ph" },
  { code: "+48", country: "pl" },
  { code: "+351", country: "pt" },
  { code: "+1 939", country: "pr" },
  { code: "+974", country: "qa" },
  { code: "+40", country: "ro" },
  { code: "+250", country: "rw" },
  { code: "+685", country: "ws" },
  { code: "+378", country: "sm" },
  { code: "+966", country: "sa" },
  { code: "+221", country: "sn" },
  { code: "+381", country: "rs" },
  { code: "+248", country: "sc" },
  { code: "+232", country: "sl" },
  { code: "+65", country: "sg" },
  { code: "+421", country: "sk" },
  { code: "+386", country: "si" },
  { code: "+677", country: "sb" },
  { code: "+27", country: "za" },
  { code: "+500", country: "gs" },
  { code: "+34", country: "es" },
  { code: "+94", country: "lk" },
  { code: "+249", country: "sd" },
  { code: "+597", country: "sr" },
  { code: "+268", country: "sz" },
  { code: "+46", country: "se" },
  { code: "+41", country: "ch" },
  { code: "+992", country: "tj" },
  { code: "+66", country: "th" },
  { code: "+228", country: "tg" },
  { code: "+690", country: "tk" },
  { code: "+676", country: "to" },
  { code: "+1 868", country: "tt" },
  { code: "+216", country: "tn" },
  { code: "+90", country: "tr" },
  { code: "+993", country: "tm" },
  { code: "+1 649", country: "tc" },
  { code: "+688", country: "tv" },
  { code: "+256", country: "ug" },
  { code: "+380", country: "ua" },
  { code: "+971", country: "ae" },
  { code: "+44", country: "gb" },
  { code: "+1", country: "us" },
  { code: "+598", country: "uy" },
  { code: "+998", country: "uz" },
  { code: "+678", country: "vu" },
  { code: "+681", country: "wf" },
  { code: "+967", country: "ye" },
  { code: "+260", country: "zm" },
  { code: "+263", country: "zw" },
  { code: "", country: "ax" },
  { code: "", country: "aq" },
  { code: "+591", country: "bo" },
  { code: "+673", country: "bn" },
  { code: "+61", country: "cc" },
  { code: "+243", country: "cd" },
  { code: "+225", country: "ci" },
  { code: "+500", country: "fk" },
  { code: "+44", country: "gg" },
  { code: "+379", country: "va" },
  { code: "+852", country: "hk" },
  { code: "+98", country: "ir" },
  { code: "+44", country: "im" },
  { code: "+44", country: "je" },
  { code: "+850", country: "kp" },
  { code: "+82", country: "kr" },
  { code: "+856", country: "la" },
  { code: "+218", country: "ly" },
  { code: "+853", country: "mo" },
  { code: "+389", country: "mk" },
  { code: "+691", country: "fm" },
  { code: "+373", country: "md" },
  { code: "+258", country: "mz" },
  { code: "+970", country: "ps" },
  { code: "+872", country: "pn" },
  { code: "+262", country: "re" },
  { code: "+7", country: "ru" },
  { code: "+590", country: "bl" },
  { code: "+290", country: "sh" },
  { code: "+1 869", country: "kn" },
  { code: "+1 758", country: "lc" },
  { code: "+590", country: "mf" },
  { code: "+508", country: "pm" },
  { code: "+1 784", country: "vc" },
  { code: "+239", country: "st" },
  { code: "+252", country: "so" },
  { code: "+47", country: "sj" },
  { code: "+963", country: "sy" },
  { code: "+886", country: "tw" },
  { code: "+255", country: "tz" },
  { code: "+670", country: "tl" },
  { code: "+58", country: "ve" },
  { code: "+84", country: "vn" },
  { code: "+1 284", country: "vg" },
  { code: "+1 340", country: "vi" },
]
