import { MessageDescriptor } from "@lingui/core"
import { defineMessage } from "@lingui/macro"
import { useRouter } from "next/router"

export interface MenuItem {
  title: MessageDescriptor
  href: string
}

export const menuItems: Array<MenuItem> = [
  {
    title: defineMessage({
      id: "header.menu.home",
      message: "Domů",
    }),
    href: "/",
  },
  {
    title: defineMessage({
      id: "header.menu.auctions",
      message: "Aukce",
    }),
    href: "/auctions",
  },
  {
    title: defineMessage({
      id: "header.menu.results",
      message: "Výsledky",
    }),
    href: "/results",
  },
  {
    title: defineMessage({
      id: "header.menu.authors",
      message: "Autoři",
    }),
    href: "/authors",
  },
  {
    title: defineMessage({
      id: "header.menu.services",
      message: "Služby",
    }),
    href: "/services",
  },
  {
    title: defineMessage({
      id: "header.menu.contact",
      message: "Kontakt",
    }),
    href: "/contact",
  },
]

export interface FooterItem {
  title: MessageDescriptor
  href: string
  type: "link" | "doc"
}

export const footerItems: Array<FooterItem> = [
  {
    title: defineMessage({
      id: "footer.links.faq",
      message: "Časté dotazy",
    }),
    href: "/faq",
    type: "link",
  },
  {
    title: defineMessage({
      id: "footer.links.complaintsCode",
      message: "Reklamační řád",
    }),
    href: "/reklamacni-rad",
    type: "link",
  },
  {
    title: defineMessage({
      id: "footer.links.serviceComplaintsCode",
      message: "Reklamační řád služby",
    }),
    href: "/reklamacni-rad-pro-reklamaci-sluzeb",
    type: "link",
  },
  {
    title: defineMessage({
      id: "footer.links.usageTerms",
      message: "Podmínky užití",
    }),
    href: "/podminky-uziti-weboveho-rozhrani",
    type: "link",
  },
  {
    title: defineMessage({
      id: "footer.links.auctionRules",
      message: "Řád aukcí",
    }),
    href: "/rad-aukci",
    type: "link",
  },
  {
    title: defineMessage({
      id: "footer.links.termsAndConditions",
      message: "Obchodní podmínky",
    }),
    href: "/obchodni-podminky",
    type: "link",
  },
]

export function useItemIsActive() {
  const { asPath } = useRouter()

  return (path: string) =>
    path === "/" ? asPath === "/" : asPath.startsWith(path)
}
