import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { DeliveryAddress, InvoiceAddress } from "@/domains/addresses/model"
import { ColorScheme } from "@/domains/color-scheme/model"
import { CurrencyCode, defaultCurrencyCode } from "@/domains/currencies/model"
import { LengthUnit, defaultLengthUnit } from "@/domains/units/model"
import { Verification, noVerification } from "@/domains/verifications/model"
import { Uuid } from "@/lib/uuid"

// -----------------------------------------------------------------------------
// Model
// -----------------------------------------------------------------------------

export const NotificationChannelSetting = D.partial({
  email: D.boolean,
  web: D.boolean,
})

export type NotificationChannelSettings = D.TypeOf<
  typeof NotificationChannelSetting
>

export type NotificationChannel = keyof NotificationChannelSettings

export const NotificationEventSettings = D.partial({
  userWinsAuction: NotificationChannelSetting,
  userIsOverbidden: NotificationChannelSetting,
})

export type NotificationEventSettings = D.TypeOf<
  typeof NotificationEventSettings
>

export type NotificationEvent = keyof NotificationEventSettings

export const defaultNotificationSettings: NotificationEventSettings = {
  userWinsAuction: { email: true },
  userIsOverbidden: { email: true },
}

export const UserSettings = D.partial({
  locale: D.string,
  currency: CurrencyCode,
  lengthUnit: LengthUnit,
  colorScheme: ColorScheme,
  notifications: NotificationEventSettings,
})

export type UserSettings = D.TypeOf<typeof UserSettings>

export const defaultSettings: UserSettings = {
  currency: defaultCurrencyCode,
  lengthUnit: defaultLengthUnit,
  notifications: defaultNotificationSettings,
}

export const UserCustomData = D.partial({
  deliveryAddress: DeliveryAddress,
  invoiceAddress: D.nullable(InvoiceAddress),
  verification: Verification,
})

export type UserCustomData = D.TypeOf<typeof UserCustomData>

export const User = D.struct({
  id: Uuid,
  firstName: D.nullable(D.string),
  lastName: D.nullable(D.string),
  email: D.nullable(D.string),
  phone: D.nullable(D.string),
  customData: pipe(
    UserCustomData,
    D.map((customData) => ({
      ...customData,
      verification: customData.verification ?? noVerification,
    }))
  ),
  settings: UserSettings,
})

export type User = D.TypeOf<typeof User>

export type Name = {
  firstName?: string | null
  lastName?: string | null
}

export function fullNameOf(name: Name): string {
  return [name.firstName, name.lastName]
    .map((s) => s?.trim())
    .filter((s) => Boolean(s))
    .join(" ")
}

export type UserPatch = {
  firstname?: string
  lastname?: string
  password?: string
  phone?: string
  customData?: UserCustomData
  settings?: UserSettings
}
