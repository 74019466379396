import { Trans, t } from "@lingui/macro"
import { useState } from "react"
import { FieldValues, Path, UseControllerReturn } from "react-hook-form"
import EyeIcon from "remixicon-react/EyeLineIcon"
import EyeOffIcon from "remixicon-react/EyeOffLineIcon"
import LockIcon from "remixicon-react/LockLineIcon"

import { PasswordSchema } from "@/domains/password/form"
import { styled } from "@/stitches"

import { IconButton } from "./button"
import { FieldBase } from "./field-base"
import { Input } from "./input"
import { Text } from "./text"

export const IssueList = styled("ol", {})

export const IssueItem = styled("li", {
  fontSize: "$sm",
  fontWeight: "$medium",
  color: "$danger11",

  "&::before": {
    content: "—",
    mr: "$2",
  },
})

// TODO: reuse this component in other places where new password input is used.
export function NewPasswordInput<
  T extends FieldValues,
  N extends Path<T>
>(props: { controller: UseControllerReturn<T, N> }) {
  const { controller } = props
  const [showPassword, setShowPassword] = useState(false)

  const validation = PasswordSchema.safeParse(controller.field.value)

  return (
    <FieldBase
      label={t({
        id: "registerDialog.credentials.password",
        message: "Heslo",
      })}
    >
      {(fieldProps) => (
        <>
          <Input
            {...fieldProps}
            {...controller.field}
            type={showPassword ? "text" : "password"}
            autoComplete="new-password"
            addonBefore={<LockIcon size="1.25em" />}
            addonAfter={
              <IconButton
                size="sm"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOffIcon size="1.5em" />
                ) : (
                  <EyeIcon size="1.5em" />
                )}
              </IconButton>
            }
            state={
              !controller.fieldState.isDirty
                ? "default"
                : validation.success
                ? "valid"
                : "invalid"
            }
          />
          {controller.fieldState.isDirty &&
            (validation.success ? (
              <Text size="sm" weight="medium" color="success">
                <Trans id="validation.password.isStrongEnough">
                  Heslo je v dostatečně silné
                </Trans>
              </Text>
            ) : (
              <IssueList key="issues">
                {validation.error.issues.map((issue) => (
                  <IssueItem key={issue.message}>{issue.message}</IssueItem>
                ))}
              </IssueList>
            ))}
        </>
      )}
    </FieldBase>
  )
}
