import { t } from "@lingui/macro"

import { useI18n } from "@/lib/i18n"
import { CSS } from "@/stitches"
import * as Autocomplete from "@/ui/autocomplete"

import { Country } from "../model"
import { useCountriesQuery } from "../query"

function useCountriesAutocomplete({
  value,
  onValueChange,
}: {
  value: Country | undefined
  onValueChange: (value: Country) => void
}): Autocomplete.AutocompleteProps<Country> {
  const countriesQuery = useCountriesQuery()
  const { i18n } = useI18n()

  return {
    multiselect: false,
    items: countriesQuery.data ?? [],
    keyOf: (item) => item.id,
    filterPredicate: (search, item) =>
      (item.name[i18n.locale] ?? "")
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase()),
    value: value ?? null,
    onValueChange,
    viewOf: (item) => item.name[i18n.locale] ?? "",
  }
}

export function CountrySelect({
  value,
  onValueChange,
  disabled = false,
  css,
}: {
  value: Country | undefined
  onValueChange: (value: Country) => void
  disabled?: boolean
  css?: CSS
}) {
  const { i18n } = useI18n()
  const countriesAutocompleteProps = useCountriesAutocomplete({
    value,
    onValueChange,
  })

  return (
    <Autocomplete.Root {...countriesAutocompleteProps}>
      <Autocomplete.Trigger
        disabled={disabled}
        css={css}
        placeholder={t({
          id: "auctionList.filters.country.placeholder",
          message: "Vyberte zemi",
        })}
      >
        {value?.name[i18n.locale]}
      </Autocomplete.Trigger>
    </Autocomplete.Root>
  )
}
