import { t } from "@lingui/macro"

import { Form } from "@/lib/form"
import { CSS } from "@/stitches"
import { Field } from "@/ui/form/field2"
import { Grid } from "@/ui/grid"

import { AddressesFormSchema } from "../form"

export function FirmDataForm({
  form,
  css,
}: {
  form: Form<AddressesFormSchema>
  css?: CSS
}) {
  return (
    <Grid gap={4} columns={{ "@initial": 1, "@md": 6 }} css={css}>
      <Field
        label={t({
          id: "companyDetails.name",
          message: "Firma",
        })}
        inputProps={{
          ...form.register("invoiceAddress.company"),
          autoComplete: "organization",
        }}
        error={form.errors.invoiceAddress?.company?.message}
        css={{ "@md": { gridColumn: "span 6" } }}
      />
      <Field
        label={t({
          id: "companyDetails.cin",
          message: "IČO",
        })}
        inputProps={{ ...form.register("invoiceAddress.cin") }}
        error={form.errors.invoiceAddress?.cin?.message}
        css={{ "@md": { gridColumn: "1 / span 3" } }}
      />
      <Field
        label={t({
          id: "companyDetails.vat",
          message: "DIČ",
        })}
        inputProps={{ ...form.register("invoiceAddress.vat") }}
        error={form.errors.invoiceAddress?.vat?.message}
        css={{ "@md": { gridColumn: "span 3" } }}
      />
    </Grid>
  )
}
