import { VariantProps } from "@stitches/react"
import { ComponentProps, ReactNode, createElement, forwardRef } from "react"

import { CSS, styled } from "@/stitches"

export const Addon = styled("div", {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  variants: {
    position: {
      start: {
        left: "1rem",
      },
      end: {
        right: "1rem",
      },
    },
  },
})

export const disabledStyle: CSS = {
  bgColor: "$gray4",
  borderColor: "transparent !important",
  color: "$gray8",
  "&::placeholder": {
    color: "$gray11",
  },
}

export const Root = styled("div", {
  position: "relative",
  display: "inline-flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  fill: "currentColor",
  transition: "background-color 120ms, border-color 120ms, box-shadow 120ms",
  "--addon-before-space": "0rem",
  "--addon-after-space": "0rem",

  "& input, & select, & textarea": {
    appearance: "none",
    outline: "none",
    border: "none",
    color: "inherit",
    bgColor: "transparent",
    font: "inherit",
    flexGrow: 1,
    py: "var(--spacing-y)",
    pl: "calc(var(--spacing-x) + var(--addon-before-space))",
    pr: "calc(var(--spacing-x) + var(--addon-after-space))",
  },

  "& ::placeholder": {
    color: "$gray8",
  },

  "label + &": {
    mt: "var(--input-label-spacing, $space$2)",
  },

  variants: {
    variant: {
      outline: {
        border: "1px solid var(--input-border-color, $gray8)",
        ".hi-contrast-dark-theme &, .hi-contrast-light-theme &": {
          borderColor: "$gray11",
        },
        "&:focus-within": {
          borderColor: "$focus",
          boxShadow: "$ring",
        },
      },
      underline: {
        borderBottom: "1px solid var(--input-border-color, $colors$gray12)",
        "--spacing-x": "0.5rem !important",
        "--spacing-y": "0.5rem !important",
        "--input-label-spacing": 0,
        "&:focus-within": {
          boxShadow: "0 2px 0 0 $colors$focus",
          borderColor: "$focus",
        },
      },
      solid: {
        bgColor: "$gray4",
        border: "1px solid $gray4",
        "&:focus-within": {
          borderColor: "$focus",
          boxShadow: "$ring",
        },
      },
    },
    size: {
      sm: {
        "--spacing-x": "1.2rem",
        "--spacing-y": "0.8rem",
      },
      md: {
        "--spacing-x": "1.5rem",
        "--spacing-y": "1rem",
      },
    },
    state: {
      default: {},
      invalid: {
        "--input-border-color": "$colors$danger11",
      },
      valid: {
        "--input-border-color": "$colors$success11",
      },
    },
    disabled: {
      true: disabledStyle,
    },
    hasAddonBefore: {
      true: {
        "--addon-before-space": "2rem",
      },
    },
    hasAddonAfter: {
      true: {
        "--addon-after-space": "2rem",
      },
    },
  },
  defaultVariants: {
    variant: "outline",
    size: "md",
    state: "default",
  },
})

export type InputRootProps = VariantProps<typeof Root>

export type InputState = InputRootProps["state"]

export type InputProps = Omit<ComponentProps<"input">, "size"> &
  InputRootProps & {
    css?: CSS
    addonBefore?: ReactNode
    addonAfter?: ReactNode
    component?: "input" | "select" | "textarea"
  }

export const Input = forwardRef<HTMLInputElement, InputProps>(
  function InputForwarded(props, ref) {
    const {
      variant,
      size,
      state,
      component = "input",
      css,
      addonBefore,
      addonAfter,
      ...inputProps
    } = props

    return (
      <Root
        {...{
          variant,
          size,
          state,
          css,
          disabled: inputProps.disabled,
          hasAddonBefore: !!addonBefore,
          hasAddonAfter: !!addonAfter,
        }}
      >
        {addonBefore && <Addon position="start">{addonBefore}</Addon>}
        {createElement(component, { ...inputProps, ref })}
        {addonAfter && <Addon position="end">{addonAfter}</Addon>}
      </Root>
    )
  }
)
