import { createDomain } from "effector"
import { useStore } from "effector-react"
import { persist } from "effector-storage/local"

import { Uuid } from "@/lib/uuid"

import { User } from "../users/model"

export type Session =
  | { authenticated: false; token?: never; userId?: never }
  | { authenticated: true; token: string; userId: Uuid }

const domain = createDomain("session")

export const authenticate =
  domain.createEvent<{ token: string; user: User }>("authenticate")

export const reset = domain.createEvent<void>("reset")

const $session = domain
  .createStore<Session>({ authenticated: false }, { name: "$session" })
  .on(authenticate, (_, { token, user }) => ({
    authenticated: true,
    token,
    userId: user.id,
  }))
  .reset(reset)

persist({ store: $session })

export function useSession() {
  return useStore($session)
}
