import { useStore } from "effector-react"

import { ColorSchemeSelect } from "@/domains/color-scheme/view/select"
import { CurrencySelect } from "@/domains/currencies/view/select"
import { LengthUnitSelect } from "@/domains/units/view/select"
import {
  $settings,
  setColorScheme,
  setCurrency,
  setLengthUnit,
} from "@/domains/website-settings/store"
import { Stack } from "@/ui/stack"

export function PreferencesView() {
  const settings = useStore($settings)

  return (
    <Stack direction="column" gap={4}>
      <CurrencySelect value={settings.currency} onValueChange={setCurrency} />
      <LengthUnitSelect
        value={settings.lengthUnit}
        onValueChange={setLengthUnit}
      />
      <ColorSchemeSelect
        value={settings.colorScheme}
        onValueChange={setColorScheme}
      />
    </Stack>
  )
}
