import { Trans, t } from "@lingui/macro"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { ReactNode } from "react"
import UserIcon from "remixicon-react/UserLineIcon"

import { useLogout } from "@/domains/auth/logout"
import { User } from "@/domains/users/model"
import { fullNameOf } from "@/domains/users/model"
import { useMeQuery } from "@/domains/users/query"
import * as Verification from "@/domains/verifications/model"
import { QueryView } from "@/lib/query"
import { Badge } from "@/ui/badge"
import { Box } from "@/ui/box"
import { ButtonBase, IconButton } from "@/ui/button"
import { Callout } from "@/ui/callout"
import * as DropdownMenu from "@/ui/dropdown-menu"
import { CheckThickIcon } from "@/ui/icons/check-thick"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"
import { Throbber } from "@/ui/throbber"
import { Tooltip } from "@/ui/tooltip"
import { UserBadge } from "@/ui/user-badge"

import { useNavItems } from "../my/navigation"

function VerificationCallout() {
  return (
    <Callout>
      <Trans id="header.userMenu.unverifiedAccountAlert.content">
        Váš profil není oveřený.
      </Trans>{" "}
      <Link href="/my/account" passHref>
        <Text as="a" size="sm" color="accent" link underline arrow>
          <Trans id="header.userMenu.unverifiedAccountAlert.verifyAccountButton.label">
            Ověřit profil
          </Trans>
        </Text>
      </Link>
    </Callout>
  )
}

function Dropdown({ children, me }: { children: ReactNode; me: User }) {
  const logout = useLogout()
  const navItems = useNavItems({ badgeSize: "xs" })
  const verification = me.customData.verification

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {!Verification.isPending(verification) &&
          !Verification.isApproved(verification) && (
            <>
              <VerificationCallout />
              <DropdownMenu.SeparatorStyled />
            </>
          )}
        {navItems.map((item) => (
          <Link key={item.href} href={item.href} passHref>
            <DropdownMenu.Item as="a" css={{ gap: "$2" }}>
              {item.label}
              <AnimatePresence>
                {item.badge && item.badge.status === "success" && (
                  <motion.span
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                  >
                    {item.badge.content}
                  </motion.span>
                )}
              </AnimatePresence>
            </DropdownMenu.Item>
          </Link>
        ))}
        <DropdownMenu.SeparatorStyled />
        <DropdownMenu.Item as="button" onClick={logout}>
          <Text color="accent">
            <Trans id="logout">Odhlásit se</Trans>
          </Text>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

export function UserMenu() {
  const meQuery = useMeQuery()

  return (
    <QueryView
      query={meQuery}
      loading={
        <Box css={{ px: "$4" }}>
          <Throbber />
        </Box>
      }
      success={(user) => (
        <>
          <Box css={{ "@menu1": { display: "none" } }}>
            <Dropdown me={user}>
              <IconButton>
                <UserIcon size="1.5em" />
              </IconButton>
            </Dropdown>
          </Box>
          <Box css={{ display: "none", "@menu1": { display: "flex" } }}>
            <Dropdown me={user}>
              <ButtonBase variant="none">
                <Stack css={{ px: "$2", py: "$1" }} align="center" gap={3}>
                  <Box
                    position="relative"
                    css={{
                      width: 52,
                      height: 52,
                      display: "grid",
                      placeItems: "center",
                      bgColor: "$gray3",
                      borderRadius: 1000,
                      color: "$gray11",
                    }}
                  >
                    {Verification.isApproved(user.customData.verification) ? (
                      <UserBadge
                        badge={
                          <Tooltip asChild content="Váš profil je ověřený">
                            <Badge size="xs" color="info" border>
                              <CheckThickIcon />
                            </Badge>
                          </Tooltip>
                        }
                      />
                    ) : Verification.isPending(user.customData.verification) ? (
                      <UserBadge
                        badge={
                          <Tooltip
                            asChild
                            content={t({
                              id: "userMenu.badge.verificationInProgress.tooltip",
                              message: "Probíhá ověřování vašeho profilu",
                            })}
                          >
                            <Badge size="xs" color="muted" border />
                          </Tooltip>
                        }
                      />
                    ) : (
                      <UserBadge
                        badge={
                          <Tooltip
                            asChild
                            content={t({
                              id: "userMenu.badge.accountNotVerified.tooltip",
                              message: "Váš profil není ověřený",
                            })}
                          >
                            <Badge size="xs" color="accent" border>
                              !
                            </Badge>
                          </Tooltip>
                        }
                      />
                    )}
                  </Box>
                  <Box css={{ maxWidth: 200, overflow: "hidden" }}>
                    <Text as="p" size="sm" weight="bold" color="accent">
                      {fullNameOf(user)}
                    </Text>
                    <Text as="p" size="sm" color="muted">
                      {user.email}
                    </Text>
                  </Box>
                  <Text color="muted">
                    <DropdownMenu.Caret size="1.25em" />
                  </Text>
                </Stack>
              </ButtonBase>
            </Dropdown>
          </Box>
        </>
      )}
    />
  )
}
