import { i18n } from "@lingui/core"
import { record } from "fp-ts"
import FacebookIcon from "remixicon-react/FacebookBoxFillIcon"
import InstagramIcon from "remixicon-react/InstagramFillIcon"
import MailLineIcon from "remixicon-react/MailLineIcon"
import PhoneIcon from "remixicon-react/PhoneLineIcon"

import { Box } from "@/ui/box"
import { Container } from "@/ui/container"
import { Grid } from "@/ui/grid"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"

import { footerItems } from "./items"
import { LinkOrDownload } from "./link-or-download"

const footerData = {
  phone: "+420 702 008 008",
  email: "info@artinvestmentgallery.com",
  socialMedia: {
    facebook: {
      icon: FacebookIcon,
      href: "https://www.facebook.com/ArtInvestmentGalleryOfficial",
    },
    instagram: {
      icon: InstagramIcon,
      href: "https://www.instagram.com/artinvestmentgallery/",
    },
  },
}

export function Footer() {
  return (
    <Box
      css={{
        mt: "$12",
        pt: "$12",
        borderTop: "1px solid $divider",
      }}
    >
      <Container as={Grid} columns={12} gap={6}>
        <Stack
          direction="column"
          gap={4}
          css={{
            gridColumn: "1 / 13",
            "@md": {
              gridColumn: "1 / 6",
            },
          }}
        >
          <Stack as="p" gap={4} align="center">
            <Text color="muted">
              <PhoneIcon size="1.5em" />
            </Text>
            <Text
              as="a"
              color="accent"
              href={`tel:${footerData.phone.replaceAll(" ", "")}`}
              link
              underline
            >
              {footerData.phone}
            </Text>
          </Stack>
          <Stack as="p" gap={4} align="center">
            <Text color="muted">
              <MailLineIcon size="1.5em" />
            </Text>
            <Text
              as="a"
              color="muted"
              href={`mailto:${footerData.email}`}
              link
              underline
            >
              {footerData.email}
            </Text>
          </Stack>
          <Stack gap={4} css={{ mt: "$2" }}>
            {record
              .toArray(footerData.socialMedia)
              .map(([key, { href, icon: Icon }]) => (
                <Text
                  key={key}
                  as="a"
                  link
                  href={href}
                  color="muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon size="2em" />
                </Text>
              ))}
          </Stack>
        </Stack>
        <Box
          as="ul"
          css={{
            gridColumn: "1 / 13",
            "@md": {
              mt: "$2",
              columns: "2",
              gridColumn: "7 / 13",
              gap: "$6",
            },
          }}
        >
          {footerItems.map((item) => (
            <Box
              as="li"
              key={item.href}
              css={{
                "&:not(:first-of-type)": {
                  mt: "$4",
                },
              }}
            >
              <LinkOrDownload
                {...item}
                textProps={{
                  variant: "label",
                  color: "muted",
                  link: true,
                  underline: true,
                  nowrap: true,
                }}
              >
                {i18n._(item.title)}
              </LinkOrDownload>
            </Box>
          ))}
        </Box>
      </Container>
      <Container css={{ pt: "$8", pb: "$8" }}>
        <Text as="p" size="sm" color="muted">
          © {new Date().getFullYear()} Art Investment Gallery
        </Text>
      </Container>
    </Box>
  )
}
