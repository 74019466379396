import { ReactNode } from "react"

import { Input, InputProps } from "@/ui/input"

import { FieldBase, FieldBaseProps } from "../field-base"

type Field2Props<P> = Omit<FieldBaseProps, "id" | "children"> & {
  renderInput?: (props: P) => ReactNode
  inputProps: P
}

export function Field<P extends { name?: string } = InputProps>(
  props: Field2Props<P>
) {
  const {
    renderInput = (props) => <Input {...props} />,
    inputProps,
    ...fieldProps
  } = props

  return (
    <FieldBase {...fieldProps}>
      {(fieldInputProps) =>
        renderInput({
          state: props.error === undefined ? "default" : "invalid",
          ...fieldInputProps,
          ...inputProps,
        })
      }
    </FieldBase>
  )
}
