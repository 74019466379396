import { AnimatePresence, motion } from "framer-motion"
import { useIsFetching } from "react-query"

import { keyframes, styled } from "@/stitches"

const animation = keyframes({
  "0%": { transform: "translateX(-100%)" },
  "100%": { transform: "translateX(100%)" },
})

const Indicator = styled(motion.div, {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: 3,
  bg: "$accentA4",
  zIndex: 100,
})

const IndicatorContent = styled("div", {
  width: "100%",
  height: "100%",
  bg: "linear-gradient(to right, $accentA1, $accentA9, $accentA1)",
  animation: `${animation} 1s infinite linear`,
  transformOrigin: "0% 50%",
})

export function FetchingIndicator() {
  const isFetching = useIsFetching()

  return (
    <AnimatePresence>
      {isFetching && (
        <Indicator
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <IndicatorContent />
        </Indicator>
      )}
    </AnimatePresence>
  )
}
