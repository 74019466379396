import { defineMessage } from "@lingui/macro"
import { HTTPError } from "ky"
import { useRouter } from "next/router"
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"

import * as SessionStore from "@/domains/session/store"
import { useSession } from "@/domains/session/store"
import { openErrorToast, openToast } from "@/domains/toasts/store"
import { setSettings } from "@/domains/website-settings/store"
import { expectJson, useHttpClient } from "@/lib/http"
import { useI18n } from "@/lib/i18n"

import {
  NotificationChannel,
  NotificationEvent,
  User,
  UserPatch,
  UserSettings,
} from "./model"

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------

function useMeApi() {
  const client = useHttpClient()

  return {
    get: (): Promise<User> => client.get(`me`).then(expectJson(User)),
    patch: (data: UserPatch): Promise<User> =>
      client.patch(`me`, { json: data }).then(expectJson(User)),
    delete: (): Promise<void> => client.delete(`me`).json(),
  }
}

// -----------------------------------------------------------------------------
// Queries
// -----------------------------------------------------------------------------

export function useMeQuery(): UseQueryResult<User> {
  const session = useSession()
  const api = useMeApi()
  const { mutate } = useUpdateUserSettingsMutation({ showToast: false })
  const { i18n } = useI18n()

  return useQuery({
    queryKey: ["me", session.token],
    queryFn: api.get,
    enabled: session.authenticated,
    onSuccess(user) {
      setSettings(user.settings)

      if (!user.settings.locale) {
        mutate({
          ...user.settings,
          locale: i18n.locale,
        })
      }
    },
    onError(error) {
      if (error instanceof HTTPError && error.response.status === 401) {
        // Session token is probably expired or bad, so we log user out.
        SessionStore.reset()
      } else {
        openErrorToast(error)
      }
    },
  })
}

export function useUpdateMeMutation(): UseMutationResult<
  User,
  Error,
  UserPatch
> {
  const client = useQueryClient()
  const api = useMeApi()
  const { token } = useSession()

  return useMutation({
    mutationFn: (data) => api.patch(data),
    onSuccess(user) {
      client.setQueryData(["me", token], user)

      openToast({
        severity: "success",
        message: defineMessage({
          id: "userData.updatedToast",
          message: "Uživatelská data byla aktualizována",
        }),
      })
    },
  })
}

export function useDeleteMeMutation() {
  const api = useMeApi()
  const router = useRouter()
  const client = useQueryClient()

  return useMutation({
    mutationFn: api.delete,
    async onMutate() {
      await router.replace("/")
    },
    onSuccess() {
      SessionStore.reset()
      client.invalidateQueries()
      openToast({
        severity: "success",
        message: defineMessage({
          id: "userData.accountDeletedToast",
          message: "Váš účet byl smazán",
        }),
      })
    },
    onError(error) {
      openErrorToast(error)
    },
  })
}

export function useUpdateUserSettingsMutation(
  options: { showToast?: boolean } = {}
) {
  const { showToast = true } = options
  const client = useQueryClient()
  const api = useMeApi()
  const { token } = useSession()

  const getSettings = () => client.getQueryData<User>(["me", token])?.settings

  const setSettings = (settings: UserSettings) => {
    client.setQueryData<User | undefined>(["me", token], (me) => {
      if (me) {
        return { ...me, settings }
      }
    })
  }

  return useMutation<UserSettings, Error, UserSettings, UserSettings>({
    mutationFn: (settings) => api.patch({ settings }).then((me) => me.settings),
    async onMutate(settings) {
      await client.cancelQueries("me")
      const previousSettings = getSettings()
      setSettings(settings)

      return previousSettings
    },
    onError(error, _newSettings, previousSettings) {
      if (previousSettings) {
        setSettings(previousSettings)
      }
      openErrorToast(error)
    },
    onSuccess(settings) {
      setSettings(settings)

      if (showToast) {
        openToast({
          severity: "success",
          message: defineMessage({
            id: "settings.updatedToast",
            message: "Nastavení účtu bylo aktualizováno",
          }),
        })
      }
    },
  })
}

export function useUpdateUserSettings() {
  const { data: me } = useMeQuery()
  const settingsMutation = useUpdateUserSettingsMutation()

  return {
    updateNotifications:
      (event: NotificationEvent, channel: NotificationChannel) =>
      (value: boolean) => {
        if (me) {
          settingsMutation.mutate({
            ...me.settings,
            notifications: {
              ...me.settings.notifications,
              [event]: {
                ...me.settings.notifications?.[event],
                [channel]: value,
              },
            },
          })
        }
      },
  }
}
