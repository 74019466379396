import { useMutation } from "react-query"

import { env } from "@/config/env"
import { verificationUrl } from "@/domains/auth/verify-email"
import { openErrorToast } from "@/domains/toasts/store"
import { useHttpClient } from "@/lib/http"

type ResendVerificationEmailParams = {
  email: string
}

export function useResendVerificationEmailMutation() {
  const client = useHttpClient()

  return useMutation({
    mutationFn: ({ email }: ResendVerificationEmailParams) =>
      client
        .post("resend-verification-email", {
          json: {
            email,
            verificationUrl: verificationUrl(),
            appCode: env.APP_CODE,
          },
        })
        .json(),
    onError(error) {
      openErrorToast(error)
    },
  })
}
