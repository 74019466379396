import * as D from "io-ts/Decoder"
import { useQuery } from "react-query"

import { UrlSlug } from "@/domains/url-slug"
import { expectJson, useHttpClient } from "@/lib/http"
import { Uuid } from "@/lib/uuid"

import { Country } from "./model"

function useApi() {
  const client = useHttpClient()

  return {
    getAll: () =>
      client.get(`aig/countries`).then(expectJson(D.array(Country))),
    get: (id: Uuid | UrlSlug) =>
      client.get(`aig/countries/${id}`).then(expectJson(Country)),
  }
}

export function useCountriesQuery() {
  const api = useApi()

  return useQuery({
    queryKey: ["countries"],
    queryFn: api.getAll,
  })
}

export function useCountryQuery(id: Uuid | UrlSlug) {
  const api = useApi()

  return useQuery<Country>({
    queryKey: ["countries", id],
    queryFn: () => api.get(id),
  })
}
