import { ReactNode } from "react"
import { FieldError, Path, UseFormRegisterReturn, get } from "react-hook-form"

import { Form } from "@/lib/form"

import { FieldBase, FieldBaseProps } from "../field-base"
import { InputState } from "../input"

type InputProps = UseFormRegisterReturn & {
  id: string
  state: InputState
}

type FormFieldProps<TFields> = Omit<FieldBaseProps, "id" | "children"> & {
  form: Form<TFields>
  name: Path<TFields>
  input: (props: InputProps) => ReactNode
}

export function FormField<TFields>(props: FormFieldProps<TFields>) {
  const { form, name, input, ...fieldProps } = props

  const { errors, isSubmitted } = form.formState
  const errorMessage =
    (get(errors, name) as FieldError)?.message ?? props.error ?? undefined
  const state: InputState =
    errorMessage !== undefined ? "invalid" : isSubmitted ? "valid" : "default"

  return (
    <FieldBase error={errorMessage} {...fieldProps}>
      {(inputProps) =>
        input({
          state,
          ...inputProps,
          ...form.register(name),
        })
      }
    </FieldBase>
  )
}
