import { Trans } from "@lingui/macro"

import { AddressesFormSchema } from "@/domains/addresses/form"
import { DeliveryAddressForm } from "@/domains/addresses/view/delivery-address"
import { FirmDataForm } from "@/domains/addresses/view/firm-data"
import { InvoiceAddressForm } from "@/domains/addresses/view/invoice-address"
import { Form } from "@/lib/form"
import { LabelledCheckbox } from "@/ui/checkbox"
import * as Collapsible from "@/ui/collapsible"
import { Stack } from "@/ui/stack"

export function AddressesView({ form }: { form: Form<AddressesFormSchema> }) {
  const isFirm = form.watch("invoiceAddress.isFirm")
  const deliveryAddressIsSameAsInvoice = form.watch(
    "deliveryAddress.isSameAsInvoiceAddress"
  )

  return (
    <Stack direction="column" gap={6}>
      <InvoiceAddressForm form={form} />
      <Collapsible.Root open={isFirm}>
        <LabelledCheckbox {...form.register("invoiceAddress.isFirm")}>
          <Trans id="invoiceAddressForm.fillCompanyData">
            Vyplnit firemní údaje
          </Trans>
        </LabelledCheckbox>
        <Collapsible.Content compensateOverflow>
          <FirmDataForm form={form} css={{ mt: "$6" }} />
        </Collapsible.Content>
      </Collapsible.Root>
      <Collapsible.Root open={!deliveryAddressIsSameAsInvoice}>
        <LabelledCheckbox
          {...form.register("deliveryAddress.isSameAsInvoiceAddress")}
        >
          <Trans id="deliveryAddressForm.deliveryAddressIsSameAsInvoice">
            Doručovací adresa je stejná jako fakturační
          </Trans>
        </LabelledCheckbox>
        <Collapsible.Content compensateOverflow>
          <DeliveryAddressForm form={form} css={{ mt: "$6" }} />
        </Collapsible.Content>
      </Collapsible.Root>
    </Stack>
  )
}
