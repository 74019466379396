import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

// -----------------------------------------------------------------------------
// Type
// -----------------------------------------------------------------------------

export type SimpleId = string & { readonly Uuid: unique symbol }

export const SimpleId = pipe(
  D.string,
  D.refine((value): value is SimpleId => true, "SimpleId")
)
