import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

const VerificationStatus = D.union(
  D.literal("none"),
  D.literal("created"),
  D.literal("started"),
  D.literal("submitted"),
  D.literal("approved"),
  D.literal("resubmission_requested"),
  D.literal("declined"),
  D.literal("expired"),
  D.literal("abandoned")
)

export const Verification = pipe(
  D.struct({
    status: VerificationStatus,
  }),
  D.intersect(
    D.partial({
      url: D.string,
      reason: D.nullable(D.string),
    })
  )
)

export type Verification = D.TypeOf<typeof Verification>

export const noVerification = {
  status: "none" as const,
}

export const isIdle = ({ status }: Verification) =>
  status === "none" || status === "expired" || status === "abandoned"

export const isPending = ({ status }: Verification) =>
  status === "created" || status === "started" || status === "submitted"

export const isApproved = ({ status }: Verification) => status === "approved"
