import { defineMessage } from "@lingui/macro"
import { array } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Uuid } from "src/lib/uuid"

import { useSession } from "@/domains/session/store"
import { openErrorToast, openToast } from "@/domains/toasts/store"
import { expectJson, useHttpClient } from "@/lib/http"
import { map } from "@/lib/query-result"

import { AuthorWatchdog, AuthorWatchdogDraft, byAuthorName } from "./model"

const KEY = ["watchdogs", "authors"] as const

function useAuthorWatchdogsApi() {
  const client = useHttpClient()

  return {
    getAll: () =>
      client
        .get(`aig/watchdogs/authors`)
        .then(expectJson(D.array(AuthorWatchdog)))
        .then(array.sort(byAuthorName)),

    post: ({ authorId }: { authorId: Uuid }) =>
      client
        .post(`aig/watchdogs/authors/${authorId}`)
        .then(expectJson(AuthorWatchdog)),

    delete: ({ authorId }: { authorId: Uuid }) =>
      client
        .delete(`aig/watchdogs/authors/${authorId}`)
        .then(expectJson(AuthorWatchdog)),
  }
}

export function useAuthorWatchdogsQuery() {
  const session = useSession()
  const api = useAuthorWatchdogsApi()

  return useQuery<Array<AuthorWatchdog | AuthorWatchdogDraft>>({
    queryKey: KEY,
    queryFn: api.getAll,
    enabled: session.authenticated,
  })
}

function useAuthorWatchdogQuery(authorId: Uuid) {
  const query = useAuthorWatchdogsQuery()

  return pipe(
    query,
    map(
      (watchdogs) =>
        watchdogs.find(({ author }) => author.id === authorId) ?? null
    )
  )
}

function useAddAuthorWatchdogMutation(authorId: Uuid) {
  const api = useAuthorWatchdogsApi()
  const client = useQueryClient()

  return useMutation<AuthorWatchdog, Error, void, Array<AuthorWatchdog>>({
    mutationFn: () => api.post({ authorId }),

    async onMutate() {
      await client.cancelQueries(KEY)
      const old = client.getQueryData<Array<AuthorWatchdog>>(KEY)
      const draft = { author: { id: authorId } }
      client.setQueryData<Array<AuthorWatchdogDraft>>(KEY, (data) =>
        data ? [...data, draft] : [draft]
      )

      openToast({
        severity: "success",
        message: defineMessage({
          id: "toast.authorWatchdogAdded",
          message: "Hlídání autora nastaveno",
        }),
      })

      return old
    },

    onError(error, _, old) {
      openErrorToast(error)

      if (old) {
        client.setQueryData<Array<AuthorWatchdog>>(KEY, old)
      }
    },

    onSettled() {
      client.invalidateQueries(KEY)
    },
  })
}

export function useRemoveAuthorWatchdogMutation(authorId: Uuid) {
  const api = useAuthorWatchdogsApi()
  const client = useQueryClient()

  return useMutation<AuthorWatchdog, Error, void, Array<AuthorWatchdog>>({
    mutationFn: () => api.delete({ authorId }),

    async onMutate() {
      await client.cancelQueries(KEY)
      const old = client.getQueryData<Array<AuthorWatchdog>>(KEY)
      client.setQueryData<Array<AuthorWatchdog>>(KEY, (data) =>
        data ? data.filter(({ author }) => author.id !== authorId) : []
      )

      openToast({
        severity: "success",
        message: defineMessage({
          id: "toast.authorWatchdogRemoved",
          message: "Hlídání autora zrušeno",
        }),
      })

      return old
    },

    onError(error, _, old) {
      openErrorToast(error)

      if (old) {
        client.setQueryData<Array<AuthorWatchdog>>(KEY, old)
      }
    },

    onSettled() {
      client.invalidateQueries(KEY)
    },
  })
}

type AuthorWatchdogState =
  | { status: "loading" }
  | {
      status: "success"
      isLoading: boolean
      isOn: boolean
      toggle: () => void
    }

export function useAuthorWatchdogState(artworkId: Uuid): AuthorWatchdogState {
  const watchdog = useAuthorWatchdogQuery(artworkId)
  const addMutation = useAddAuthorWatchdogMutation(artworkId)
  const removeMutation = useRemoveAuthorWatchdogMutation(artworkId)

  if (!watchdog.isSuccess) {
    return { status: "loading" }
  }

  return {
    status: "success",
    isLoading: addMutation.isLoading || removeMutation.isLoading,
    isOn: watchdog.data !== null,
    toggle: () => {
      if (watchdog.data === null) {
        addMutation.mutate()
      } else {
        removeMutation.mutate()
      }
    },
  }
}
