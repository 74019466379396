import ChevronRightIcon from "remixicon-react/ArrowRightSLineIcon"

import { styled } from "@/stitches"
import { Box } from "@/ui/box"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"
import { Throbber } from "@/ui/throbber"

export type Breadcrumb =
  | {
      label: string
      href?: string
    }
  | { loading: true }

export type Breadcrumbs = Array<Breadcrumb>

const Chevron = styled(ChevronRightIcon, {
  display: "inline",
  ml: "$2",
  "@sm": {
    ml: "$4",
  },
})

export function Breadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs: Array<Breadcrumb>
}) {
  return (
    <Stack
      as="ol"
      align="center"
      gap={{ "@initial": 2, "@sm": 4 }}
      css={{ color: "$gray11" }}
    >
      {breadcrumbs.map((crumb, index) =>
        "loading" in crumb ? (
          <Box as="li" key={`loading-${index}`}>
            <Throbber />
          </Box>
        ) : (
          <Box as="li" key={crumb.label}>
            <Text
              as={crumb.href ? "a" : "span"}
              link={!!crumb.href}
              underline={!!crumb.href}
              href={crumb.href ?? undefined}
              variant="uppercase"
              weight="medium"
              size="xs"
              color="muted"
            >
              {crumb.label}
            </Text>
            {index < breadcrumbs.length - 1 && <Chevron size="1em" />}
          </Box>
        )
      )}
    </Stack>
  )
}
