import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { useRouter } from "next/router"

import { getOrThrow } from "@/lib/either"

export class UnexpectedQueryError extends Error {
  constructor(decodeError: D.DecodeError) {
    super(`Failed to parse query:
${D.draw(decodeError)}`)
    this.name = "UnexpecedQueryError"
  }
}

export function useParsedQuery<A>(decoder: D.Decoder<unknown, A>): A | null {
  const router = useRouter()

  // Query object is empty on server-side, so we'll return null instead
  if (Object.keys(router.query).length === 0) {
    return null
  }

  return pipe(
    decoder.decode(router.query),
    getOrThrow((error) => new UnexpectedQueryError(error))
  )
}
