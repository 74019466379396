import { Trans, t } from "@lingui/macro"
import { Controller } from "react-hook-form"
import LockIcon from "remixicon-react/LockLineIcon"
import MailIcon from "remixicon-react/MailLineIcon"

import { CredentialsFormSchema } from "@/domains/auth/register/form"
import { Form } from "@/lib/form"
import { LabelledCheckbox } from "@/ui/checkbox"
import { FormField } from "@/ui/form/field"
import { Input } from "@/ui/input"
import { NewPasswordInput } from "@/ui/new-password-input"
import { Prose } from "@/ui/prose"
import { Stack } from "@/ui/stack"

export function CredentialsView({
  form,
}: {
  form: Form<CredentialsFormSchema>
}) {
  return (
    <Stack direction="column" gap={6}>
      <Stack direction="column" gap={4}>
        <FormField
          form={form}
          name="email"
          label={t({
            id: "registerDialog.credentials.email",
            message: "E-mail",
          })}
          input={(props) => (
            <Input
              {...props}
              addonBefore={<MailIcon size="1.25em" />}
              autoComplete="email"
            />
          )}
        />
        <Controller
          control={form.control}
          name="password"
          defaultValue=""
          render={(controller) => <NewPasswordInput controller={controller} />}
        />
        <FormField
          form={form}
          name="passwordConfirm"
          label={t({
            id: "registerDialog.credentials.passwordConfirm",
            message: "Heslo znovu",
          })}
          input={(props) => (
            <Input
              {...props}
              type="password"
              autoComplete="new-password"
              addonBefore={<LockIcon size="1.25em" />}
            />
          )}
        />
      </Stack>
      <Stack direction="column" gap={4}>
        <LabelledCheckbox
          {...form.register("acceptTermsAndConditions")}
          error={form.formState.errors.acceptTermsAndConditions?.message}
        >
          <Prose>
            <Trans id="registerDialog.acceptTermsAndConditions">
              Přečetl jsem a souhlasím s{" "}
              <a href="/obchodni-podminky" target="_blank">
                všeobecnými obchodními podmínkami
              </a>
              .
            </Trans>
          </Prose>
        </LabelledCheckbox>
        <LabelledCheckbox
          {...form.register("acceptAuctionRules")}
          error={form.formState.errors.acceptAuctionRules?.message}
        >
          <Prose>
            <Trans id="registerDialog.acceptAuctionRules">
              Přečetl jsem a souhlasím s{" "}
              <a href="/rad-aukci" target="_blank">
                řádem aukcí
              </a>
              .
            </Trans>
          </Prose>
        </LabelledCheckbox>
        <LabelledCheckbox
          {...form.register("acceptTermsOfUse")}
          error={form.formState.errors.acceptTermsOfUse?.message}
        >
          <Prose>
            <Trans id="registerDialog.acceptTermsOfUse">
              Přečetl jsem a souhlasím s{" "}
              <a href="/podminky-uziti-weboveho-rozhrani" target="_blank">
                podmínkami užití
              </a>
              .
            </Trans>
          </Prose>
        </LabelledCheckbox>
      </Stack>
    </Stack>
  )
}
