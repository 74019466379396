import { useCallback, useState } from "react"

/**
 * React hook to manage boolean (on - off) states
 *
 * @param initialState the initial boolean state value
 */
export function useBooleanState(initialState = false) {
  const [isOn, setValue] = useState(initialState)

  const on = useCallback(() => {
    setValue(true)
  }, [])

  const off = useCallback(() => {
    setValue(false)
  }, [])

  const toggle = useCallback(() => {
    setValue((prev) => !prev)
  }, [])

  const set = useCallback((value: boolean) => {
    setValue(value)
  }, [])

  return {
    isOn,
    isOff: !isOn,
    on,
    off,
    toggle,
    set,
  } as const
}
