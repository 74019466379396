import { t } from "@lingui/macro"
import { ord } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { Uuid } from "src/lib/uuid"

import { AuctionPreview } from "@/domains/auctions/model"
import { Centuries } from "@/domains/centuries/model"
import { Country } from "@/domains/countries"
import { UploadedImageFile } from "@/domains/files/model"
import { LocalizedString } from "@/domains/localized-strings/model"
import { SimpleId } from "@/domains/simple-id"
import { Tag } from "@/domains/tags/model"
import { UrlSlug } from "@/domains/url-slug"
import { LocaleOrd } from "@/lib/string"

export const AuthorProps = D.struct({
  id: Uuid,
  name: D.string,
  surname: D.string,
  urlSlug: UrlSlug,
  simpleId: SimpleId,
})

export const AuthorPreview = pipe(
  AuthorProps,
  D.intersect(
    D.struct({
      description: LocalizedString,
      centuries: D.nullable(Centuries),
      country: D.nullable(Country),
      tags: D.array(Tag),
      images: D.array(
        D.struct({
          file: UploadedImageFile,
        })
      ),
    })
  )
)

export interface AuthorPreview extends D.TypeOf<typeof AuthorPreview> {}

export const AuthorDetail = pipe(
  AuthorProps,
  D.intersect(
    D.struct({
      description: LocalizedString,
      centuries: D.nullable(Centuries),
      country: D.nullable(Country),
      tags: D.array(Tag),
      images: D.array(
        D.struct({
          file: UploadedImageFile,
        })
      ),
      auctions: D.array(AuctionPreview),
    })
  )
)

export interface AuthorDetail extends D.TypeOf<typeof AuthorDetail> {}

export const byFullName = pipe(
  LocaleOrd,
  ord.contramap((author: { name: string; surname: string }) => author.name)
)

export const bySurname = pipe(
  LocaleOrd,
  ord.contramap((author: { name: string; surname: string }) => author.surname)
)

/**
 * Order by surname and then by full name.
 */
export const byName = ord
  .getMonoid<{ name: string; surname: string }>()
  .concat(bySurname, byFullName)

export const nameOf = (author: { name: string } | null | undefined) =>
  author?.name ?? t`author.unknown`

export const hrefOf = (
  author?: { simpleId: SimpleId; urlSlug: UrlSlug } | null
) => `/authors/${author ? `${author.simpleId}/${author.urlSlug}` : "unknown"}`
