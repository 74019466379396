import { t } from "@lingui/macro"
import MoneyIcon from "remixicon-react/MoneyEuroCircleLineIcon"

import { Field } from "@/ui/form/field2"
import * as Select from "@/ui/select"

import { CurrencyCode } from "../model"
import { useCurrenciesQuery } from "../query"

export function CurrencySelect({
  value,
  onValueChange,
}: {
  value: CurrencyCode
  onValueChange: (value: CurrencyCode) => void
}) {
  const currenciesQuery = useCurrenciesQuery()

  if (!currenciesQuery.data) {
    return null
  }

  return (
    <Field
      label={t({
        id: "settings.currency",
        message: "Měna",
      })}
      inputProps={{ name: "currency" }}
      renderInput={(props) => (
        <Select.Root {...props} value={value} onValueChange={onValueChange}>
          <Select.Trigger addonBefore={<MoneyIcon size="1.25em" />} />
          <Select.Content>
            {currenciesQuery.data.map((option) => (
              <Select.Item key={option.isoCode4217} value={option.isoCode4217}>
                {option.isoCode4217}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      )}
    />
  )
}
