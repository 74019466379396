import { i18n } from "@lingui/core"
import { z } from "zod"

import * as ValidationMessages from "@/lib/validation/messages"

export const phoneRegex =
  /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9} *$/

export const phoneNumber = () =>
  z
    .string()
    .nonempty()
    .regex(phoneRegex, {
      message: i18n._(ValidationMessages.invalidPhone),
    })

export const phone = () =>
  z.object({
    countryCallingCode: z.string(),
    nationalNumber: z
      .string()
      .nonempty()
      .regex(phoneRegex, {
        message: i18n._(ValidationMessages.invalidPhone),
      }),
  })

export const phoneNumberOptional = () =>
  z.union([z.undefined(), z.literal(""), phoneNumber()])
