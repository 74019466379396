import { array, date, number, ord } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { ArtworkDetail, ArtworkPreview } from "@/domains/artworks/model"
import { Bid } from "@/domains/bids/model"
import { Eur } from "@/domains/money"
import { SimpleId } from "@/domains/simple-id"
import { UrlSlug } from "@/domains/url-slug"
import { DateFromUnknown } from "@/lib/date"
import { Uuid } from "@/lib/uuid"

import { LocalizedString } from "../localized-strings/model"

// -----------------------------------------------------------------------------
// Auction detail
// -----------------------------------------------------------------------------

const AuctionBlockPreview = D.struct({
  name: LocalizedString,
  dateStart: DateFromUnknown,
  dateLastAuctionEnd: D.nullable(DateFromUnknown),
})

export interface AuctionProps extends D.TypeOf<typeof AuctionProps> {}

export const AuctionProps = D.struct({
  startingPrice: Eur,
  currentPrice: D.nullable(Eur),
  dateEnd: DateFromUnknown,
  id: Uuid,
  simpleId: SimpleId,
  isRunning: D.boolean,
  isFinished: D.boolean,
  finishedWithCurrencyRates: D.record(D.number),
})

export interface AuctionProps extends D.TypeOf<typeof AuctionProps> {}

export const AuthAuctionProps = D.struct({
  bids: pipe(
    D.array(Bid),
    D.map((bids) => {
      bids.reverse() // mutates original array
      return pipe(
        bids,
        array.sortBy([
          pipe(
            date.Ord,
            ord.contramap((bid: Bid) => bid.createdAt),
            ord.reverse
          ),
          pipe(
            number.Ord,
            ord.contramap((bid: Bid) => bid.currentPrice),
            ord.reverse
          ),
        ])
      )
    })
  ),
})

export interface AuthAuctionProps extends D.TypeOf<typeof AuthAuctionProps> {}

export const AuctionDetail = pipe(
  AuctionProps,
  D.intersect(
    D.struct({
      artwork: ArtworkDetail,
      isSold: D.boolean,
      auctionBlock: D.nullable(AuctionBlockPreview),
      hasMinimumPrice: D.boolean,
      hasReachedMinimumPrice: D.boolean,
    })
  )
)

export interface AuctionDetail extends D.TypeOf<typeof AuctionDetail> {
  bids?: undefined
}

export const AuthAuctionDetail = pipe(
  AuctionDetail,
  D.intersect(AuthAuctionProps)
)

export interface AuthAuctionDetail extends D.TypeOf<typeof AuthAuctionDetail> {}

export const AuctionPreview = pipe(
  AuctionProps,
  D.intersect(
    D.struct({
      artwork: ArtworkPreview,
      isOrdered: D.boolean,
    })
  )
)

export interface AuctionPreview extends D.TypeOf<typeof AuctionPreview> {}

export const AuthAuctionPreview = pipe(
  AuctionPreview,
  D.intersect(AuthAuctionProps)
)

export interface AuthAuctionPreview
  extends D.TypeOf<typeof AuthAuctionPreview> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAuthAuction(a: any): a is AuthAuctionProps {
  return a.bids !== undefined
}

export type RunningAuctionParticipantStatus =
  | "leading"
  | "losing"
  | "notParticipating"

export type SoldAuctionParticipantStatus = "won" | "lost" | "notParticipating"

export type AuctionNotSoldReason = "minimumPriceNotReached" | "other"

export type AuctionState =
  | { status: "running"; participantStatus: RunningAuctionParticipantStatus }
  | { status: "sold"; participantStatus: SoldAuctionParticipantStatus }
  | { status: "notSold"; reason: AuctionNotSoldReason }

// -----------------------------------------------------------------------------
// Helpers
// -----------------------------------------------------------------------------

export const priceOf = (auction: AuctionProps): Eur =>
  auction.currentPrice ?? auction.startingPrice

export const auctionParticipantStatusOf =
  (userId: Uuid) =>
  (auction: AuctionProps & AuthAuctionProps): RunningAuctionParticipantStatus =>
    array.isNonEmpty(auction.bids) &&
    auction.bids.some((bid) => bid.userId === userId)
      ? auction.bids[0].userId === userId
        ? "leading"
        : "losing"
      : "notParticipating"

export const auctionStateOf =
  (userId: Uuid) =>
  (auction: AuthAuctionDetail): AuctionState => {
    const participantStatus = pipe(auction, auctionParticipantStatusOf(userId))
    if (auction.isRunning) {
      return {
        status: "running",
        participantStatus,
      }
    }

    if (auction.isSold) {
      return {
        status: "sold",
        participantStatus:
          participantStatus === "leading"
            ? "won"
            : participantStatus === "losing"
            ? "lost"
            : "notParticipating",
      }
    }

    return {
      status: "notSold",
      reason: !auction.hasReachedMinimumPrice
        ? "minimumPriceNotReached"
        : "other",
    }
  }

// -----------------------------------------------------------------------------
// Sorting
// -----------------------------------------------------------------------------

export const byPrice: ord.Ord<AuctionProps> = pipe(
  number.Ord,
  ord.contramap(priceOf)
)

export const byDateEnd: ord.Ord<AuctionProps> = pipe(
  number.Ord,
  ord.contramap((auction: AuctionProps) => auction.dateEnd.getTime())
)

export const Sorting = D.union(
  D.literal("default"),
  D.literal("priceAsc"),
  D.literal("priceDesc")
)

export type Sorting = D.TypeOf<typeof Sorting>

/**
 * @deprecated Use API's sort parameters instead.
 */
export function sortAuctions<T extends AuctionProps>(
  auctions: Array<T>,
  sorting: Sorting
): Array<T> {
  if (sorting === "priceDesc") {
    return pipe(auctions, array.sort(ord.reverse(byPrice)))
  }

  if (sorting === "priceAsc") {
    return pipe(auctions, array.sort(byPrice))
  }

  if (sorting === "default") {
    const now = new Date()
    const { left: ended, right: ongoing } = pipe(
      auctions,
      array.sort(byDateEnd),
      array.partition((auction) => auction.dateEnd.getTime() > now.getTime())
    )
    return [...ongoing, ...ended]
  }

  return auctions
}

// -----------------------------------------------------------------------------
// View
// -----------------------------------------------------------------------------

export type View = "grid" | "gridCompact" | "list"

// -----------------------------------------------------------------------------
// Links
// -----------------------------------------------------------------------------

export const hrefOf = (auction: {
  simpleId: SimpleId
  artwork: { urlSlug: UrlSlug }
}): string => `/auctions/${auction.simpleId}/${auction.artwork.urlSlug}`
