import { useStore } from "effector-react"
import { AnimatePresence, motion } from "framer-motion"
import { ReactNode } from "react"

import { nav } from "@/domains/auth/register/store"
import { styled } from "@/stitches"

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const SlideStyled = styled(motion.div, {
  position: "absolute",
  inset: 0,
  px: "var(--dialog-spacing)",
})

export function Slide({ children }: { children: ReactNode }) {
  const stepIndex = useStore(nav.$currentIndex)
  const direction = useStore(nav.$lastDirection)

  return (
    <AnimatePresence initial={false} custom={direction}>
      <SlideStyled
        key={stepIndex}
        variants={variants}
        custom={direction}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
      >
        {children}
      </SlideStyled>
    </AnimatePresence>
  )
}
