import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { env } from "@/config/env"

export const UploadedFile = pipe(
  D.struct({
    description: D.nullable(D.string),
    extension: D.string,
    name: D.string,
    mimeType: D.string,
    path: D.string,
    id: D.string,
  }),
  D.map((file) => ({
    ...file,
    url: [env.API_URL, file.path].join(""),
  }))
)

export type UploadedFile = D.TypeOf<typeof UploadedFile>

export const UploadedImageFile = pipe(
  UploadedFile,
  D.intersect(
    D.struct({
      metadata: pipe(
        D.struct({
          imageBlurHash: D.string,
          imageWidth: D.number,
          imageHeight: D.number,
        }),
        D.map((meta) => ({
          blurHash: meta.imageBlurHash,
          width: meta.imageWidth,
          height: meta.imageHeight,
        }))
      ),
    })
  )
)

export type UploadedImageFile = D.TypeOf<typeof UploadedImageFile>
