import { defineMessage } from "@lingui/macro"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Uuid } from "src/lib/uuid"

import { useSession } from "@/domains/session/store"
import { openErrorToast, openToast } from "@/domains/toasts/store"
import { expectJson, useHttpClient } from "@/lib/http"
import { map } from "@/lib/query-result"

import { ArtworkWatchdog, ArtworkWatchdogDraft } from "./model"

const KEY = ["watchdogs", "artworks"] as const

function useArtworkWatchdogsApi() {
  const client = useHttpClient()

  return {
    getAll: () =>
      client
        .get(`aig/watchdogs/artworks`)
        .then(expectJson(D.array(ArtworkWatchdog))),

    post: ({ artworkId }: { artworkId: Uuid }) =>
      client
        .post(`aig/watchdogs/artworks/${artworkId}`)
        .then(expectJson(ArtworkWatchdog)),

    delete: ({ artworkId }: { artworkId: Uuid }) =>
      client
        .delete(`aig/watchdogs/artworks/${artworkId}`)
        .then(expectJson(ArtworkWatchdog)),
  }
}

export function useArtworkWatchdogsQuery() {
  const session = useSession()
  const api = useArtworkWatchdogsApi()

  return useQuery<Array<ArtworkWatchdog | ArtworkWatchdogDraft>>({
    queryKey: KEY,
    queryFn: api.getAll,
    enabled: session.authenticated,
  })
}

function useArtworkWatchdogQuery(artworkId: Uuid) {
  const query = useArtworkWatchdogsQuery()

  return pipe(
    query,
    map(
      (watchdogs) =>
        watchdogs.find(({ artwork }) => artwork.id === artworkId) ?? null
    )
  )
}

function useAddArtworkWatchdogMutation(artworkId: Uuid) {
  const api = useArtworkWatchdogsApi()
  const client = useQueryClient()

  return useMutation<ArtworkWatchdog, Error, void, Array<ArtworkWatchdog>>({
    mutationFn: () => api.post({ artworkId }),

    async onMutate() {
      await client.cancelQueries(KEY)
      const old = client.getQueryData<Array<ArtworkWatchdog>>(KEY)
      const draft: ArtworkWatchdogDraft = { artwork: { id: artworkId } }
      client.setQueryData<Array<ArtworkWatchdogDraft>>(KEY, (data) =>
        data ? [...data, draft] : [draft]
      )

      openToast({
        severity: "success",
        message: defineMessage({
          id: "toast.artworkWatchdogAdded",
          message: "Hlídání díla nastaveno",
        }),
      })

      return old
    },

    onError(error, _, old) {
      openErrorToast(error)

      if (old) {
        client.setQueryData<Array<ArtworkWatchdog>>(KEY, old)
      }
    },

    onSettled() {
      client.invalidateQueries(KEY)
    },
  })
}

export function useRemoveArtworkWatchdogMutation(artworkId: Uuid) {
  const api = useArtworkWatchdogsApi()
  const client = useQueryClient()

  return useMutation<ArtworkWatchdog, Error, void, Array<ArtworkWatchdog>>({
    mutationFn: () => api.delete({ artworkId }),

    async onMutate() {
      await client.cancelQueries(KEY)
      const old = client.getQueryData<Array<ArtworkWatchdog>>(KEY)
      client.setQueryData<Array<ArtworkWatchdog>>(KEY, (data) =>
        data ? data.filter(({ artwork }) => artwork.id !== artworkId) : []
      )

      openToast({
        severity: "success",
        message: defineMessage({
          id: "toast.artworkWatchdogRemoved",
          message: "Hlídání díla zrušeno",
        }),
      })

      return old
    },

    onError(error, _, old) {
      openErrorToast(error)

      if (old) {
        client.setQueryData<Array<ArtworkWatchdog>>(KEY, old)
      }
    },

    onSettled() {
      client.invalidateQueries(KEY)
    },
  })
}

type ArtworkWatchdogState =
  | { status: "loading" }
  | {
      status: "success"
      isLoading: boolean
      isOn: boolean
      toggle: () => void
    }

export function useArtworkWatchdogState(artworkId: Uuid): ArtworkWatchdogState {
  const watchdog = useArtworkWatchdogQuery(artworkId)
  const addMutation = useAddArtworkWatchdogMutation(artworkId)
  const removeMutation = useRemoveArtworkWatchdogMutation(artworkId)

  if (!watchdog.isSuccess) {
    return { status: "loading" }
  }

  return {
    status: "success",
    isLoading: addMutation.isLoading || removeMutation.isLoading,
    isOn: watchdog.data !== null,
    toggle: () => {
      if (watchdog.data === null) {
        addMutation.mutate()
      } else {
        removeMutation.mutate()
      }
    },
  }
}
