import * as D from "io-ts/Decoder"

import { UploadedImageFile } from "@/domains/files/model"
import { LocalizedString } from "@/domains/localized-strings/model"
import { Uuid } from "@/lib/uuid"

export const ArtworkWatchdog = D.struct({
  id: Uuid,
  artwork: D.struct({
    id: Uuid,
    name: LocalizedString,
    originalName: D.string,
    author: D.struct({
      name: D.string,
      surname: D.string,
    }),
    images: D.array(
      D.struct({
        file: UploadedImageFile,
      })
    ),
    previewImage: D.nullable(UploadedImageFile),
  }),
})

export interface ArtworkWatchdog extends D.TypeOf<typeof ArtworkWatchdog> {}

export type ArtworkWatchdogDraft = {
  artwork: { id: Uuid }
}

export const isNotDraft = (
  item: ArtworkWatchdog | ArtworkWatchdogDraft
): item is ArtworkWatchdog => "id" in item
