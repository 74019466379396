import { styled } from "@/stitches"

export const MenuItemView = styled("div", {
  textTransform: "uppercase",
  letterSpacing: "$uppercase",
  transition: "color 120ms",
  whiteSpace: "nowrap",

  variants: {
    active: {
      true: {
        fontWeight: "$bold",
        color: "$accent9",
      },
      false: {
        fontWeight: "$medium",
        color: "$gray11",
        "&:hover": {
          color: "$gray12",
        },
      },
    },
    border: {
      true: {
        display: "flex",
        flexDirection: "column",
        "&::after": {
          content: "",
          height: 2,
        },
      },
    },
    size: {
      md: { fontSize: "$sm" },
      sm: { fontSize: "$xs" },
    },
  },

  compoundVariants: [
    {
      active: true,
      border: true,
      css: {
        "&::after": {
          bgColor: "currentColor",
        },
      },
    },
  ],

  defaultVariants: {
    active: false,
    size: "md",
  },
})
