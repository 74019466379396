import { createDomain } from "effector"

export type Dialog = "login" | "register" | "resetPassword"

const domain = createDomain("ui/dialog")

export const openDialog = domain.createEvent<Dialog>("openDialog")
export const closeDialog = domain.createEvent<void>("closeDialog")

export const $dialog = domain
  .createStore<null | Dialog>(null, {
    name: "$dialog",
  })
  .on(openDialog, (_, dialog) => dialog)
  .reset(closeDialog)
