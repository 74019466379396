import { Trans } from "@lingui/macro"
import { useLingui } from "@lingui/react"
import { locales } from "lingui.config"
import { ReactNode } from "react"

import { colorSchemeOptions } from "@/domains/color-scheme/model"
import { useCurrenciesQuery } from "@/domains/currencies/query"
import { useUpdateLocale } from "@/domains/locale"
import { lengthUnitOptions } from "@/domains/units/model"
import { useSettings } from "@/domains/website-settings/lib"
import { Button } from "@/ui/button"
import { Caret } from "@/ui/dropdown-menu"
import * as Popover from "@/ui/popover"
import * as Select from "@/ui/select"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"

function Row({ children }: { children: ReactNode }) {
  return (
    <Stack direction="column" gap={2}>
      {children}
    </Stack>
  )
}

export function SettingsMenu() {
  const { i18n } = useLingui()
  const settings = useSettings()
  const updateLocale = useUpdateLocale()
  const currenciesQuery = useCurrenciesQuery()

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button variant="ghost" density="dense" color="muted">
          <Text variant="uppercase">
            {i18n.locale.toUpperCase()}/{settings.currency}
          </Text>
          <Caret size="1.25em" />
        </Button>
      </Popover.Trigger>
      <Popover.Content hideClose>
        <Stack direction="column" gap={4}>
          <Row>
            <Text variant="label">
              <Trans id="settings.language">Jazyk</Trans>
            </Text>
            <Select.Root value={i18n.locale} onValueChange={updateLocale}>
              <Select.Trigger size="sm" />
              <Select.Content>
                {locales.map((locale) => (
                  <Select.Item key={locale} value={locale}>
                    {locale.toUpperCase()}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Row>
          <Row>
            <Text variant="label">
              <Trans id="settings.currency">Měna</Trans>
            </Text>
            {currenciesQuery.data && (
              <Select.Root
                value={settings.currency}
                onValueChange={settings.changeCurrency}
              >
                <Select.Trigger size="sm" />
                <Select.Content>
                  {currenciesQuery.data.map((currency) => (
                    <Select.Item
                      key={currency.isoCode4217}
                      value={currency.isoCode4217}
                    >
                      {currency.isoCode4217}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            )}
          </Row>
          <Row>
            <Text variant="label">
              <Trans id="settings.lengthUnits">Jednotky</Trans>
            </Text>
            <Select.Root
              value={settings.lengthUnit}
              onValueChange={settings.changeLengthUnit}
            >
              <Select.Trigger size="sm" />
              <Select.Content>
                {lengthUnitOptions.map((option) => (
                  <Select.Item key={option.value} value={option.value}>
                    {i18n._(option.display)}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Row>
          <Row>
            <Text variant="label">
              <Trans id="settings.colorScheme">Barevný motiv</Trans>
            </Text>
            {currenciesQuery.data && (
              <Select.Root
                value={settings.colorScheme}
                onValueChange={settings.changeColorScheme}
              >
                <Select.Trigger size="sm" />
                <Select.Content>
                  {colorSchemeOptions.map((option) => (
                    <Select.Item key={option.value} value={option.value}>
                      <Stack gap={4}>
                        <option.icon size="1.25em" />
                        {i18n._(option.display)}
                      </Stack>
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            )}
          </Row>
        </Stack>
      </Popover.Content>
    </Popover.Root>
  )
}
