import { defineMessage } from "@lingui/macro"
import * as D from "io-ts/Decoder"
import { HTTPError } from "ky"
import { useMutation, useQuery } from "react-query"

import { expectJson, useHttpClient } from "@/lib/http"

import { openErrorToast, openToast } from "../toasts/store"

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------

type GetResetTokenParams = {
  email: string
}

const GetResetTokenResponse = D.struct({
  token: D.string,
})

type SetPasswordParams = {
  token: string
  password: string
}

function useResetPasswordApi() {
  const client = useHttpClient()
  return {
    getResetToken: ({ email }: GetResetTokenParams) =>
      client
        .post(`users/reset-password`, {
          json: {
            email,
            redirectTo: `${window.location.origin}/reset-password`,
          },
        })
        .then(expectJson(GetResetTokenResponse)),

    verifyToken: (token: string) =>
      client
        .get(`users/verify-reset-password-token/${token}`)
        .catch((error) => {
          if (error instanceof HTTPError && error.response.status < 500) {
            return error.response
          }

          return Promise.reject(error)
        }),

    setPassword: (params: SetPasswordParams) =>
      client
        .patch(`users/set-password`, {
          json: params,
        })
        .then(() => undefined),
  }
}

// -----------------------------------------------------------------------------
// Queries
// -----------------------------------------------------------------------------

export function useVerifyTokenQuery(token?: string) {
  const api = useResetPasswordApi()

  return useQuery({
    queryKey: ["reset-password"],
    queryFn: () => (token ? api.verifyToken(token) : Promise.reject()),
    enabled: !!token,
    staleTime: Infinity,
    retry: false,
    onError(error) {
      openErrorToast(error)
    },
  })
}

export function useGetResetTokenMutation() {
  const api = useResetPasswordApi()

  return useMutation(api.getResetToken, {
    onError(error) {
      openErrorToast(error)
    },
  })
}

export function useSetPasswordMutation() {
  const api = useResetPasswordApi()

  return useMutation(api.setPassword, {
    onError(error) {
      if (error instanceof HTTPError && error.response.status === 404) {
        openToast({
          message: defineMessage({
            id: "resetPassword.linkAlreadyUsed",
            message: "Tento odkaz pro obnovu hesla byl již použit",
          }),
          severity: "danger",
        })
      } else {
        openErrorToast(error)
      }
    },
  })
}
