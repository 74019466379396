import { array, string } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

// -----------------------------------------------------------------------------
// Model
// -----------------------------------------------------------------------------

export const Address = D.partial({
  recipient: D.string,
  company: D.string,
  street: D.string,
  houseNumber: D.string,
  city: D.string,
  zip: D.string,
  country: D.string,
})

export type Address = D.TypeOf<typeof Address>

export const InvoiceAddress = pipe(
  Address,
  D.intersect(
    D.partial({
      isFirm: D.boolean,
      cin: D.string,
      vat: D.string,
    })
  )
)

export type InvoiceAddress = D.TypeOf<typeof InvoiceAddress>

export const DeliveryAddress = pipe(
  Address,
  D.intersect(
    D.partial({
      isSameAsInvoiceAddress: D.boolean,
    })
  )
)

export type DeliveryAddress = D.TypeOf<typeof DeliveryAddress>

// -----------------------------------------------------------------------------
// Functions
// -----------------------------------------------------------------------------

export const formatAddress = (address: Address): Array<string> =>
  pipe(
    [
      address.recipient,
      address.company,
      [address.street, address.houseNumber].filter(Boolean).join(" "),
      [address.city, address.zip].filter(Boolean).join(" "),
      address.country,
    ],
    array.filter(
      (value): value is string => string.isString(value) && value.length > 0
    )
  )
