import { Trans, t } from "@lingui/macro"
import { pipe } from "fp-ts/lib/function"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { useRouter } from "next/router"
import { ReactNode } from "react"

import { useArtworkWatchdogsQuery } from "@/domains/artwork-watchdogs/query"
import { useMyWonAuctionsQuery } from "@/domains/auctions/query"
import { useLogout } from "@/domains/auth/logout"
import { useAuthorWatchdogsQuery } from "@/domains/author-watchdogs/query"
import { fullNameOf } from "@/domains/users/model"
import { useMeQuery } from "@/domains/users/query"
import * as Verification from "@/domains/verifications/model"
import { QueryView } from "@/lib/query"
import { Badge, BadgeProps } from "@/ui/badge"
import { Box } from "@/ui/box"
import { Skeleton } from "@/ui/skeleton"
import { Stack } from "@/ui/stack"
import { Text } from "@/ui/text"
import { Tooltip } from "@/ui/tooltip"

export type NavItem = {
  label: string
  href: string
  badge?: { status: "loading" } | { status: "success"; content: ReactNode }
}

export function useNavItems({
  badgeSize = "sm",
}: {
  badgeSize?: BadgeProps["size"]
} = {}): Array<NavItem> {
  const wonAuctionsQuery = useMyWonAuctionsQuery()
  const meQuery = useMeQuery()
  const artworkWatchdogsQuery = useArtworkWatchdogsQuery()
  const authorWatchdogsQuery = useAuthorWatchdogsQuery()

  return [
    {
      label: t({
        id: "userMenu.links.wonAuctions",
        message: "Vydražené položky",
      }),
      href: "/my/won-auctions",
      badge:
        wonAuctionsQuery.isSuccess && wonAuctionsQuery.data.length > 0
          ? {
              status: "success",
              content: (
                <Badge size={badgeSize}>{wonAuctionsQuery.data.length}</Badge>
              ),
            }
          : { status: "loading" },
    },
    {
      label: t({
        id: "userMenu.links.orders",
        message: "Objednávky",
      }),
      href: "/my/orders",
    },
    {
      label: t({
        id: "userMenu.links.auctionsInProgress",
        message: "Probíhající aukce",
      }),
      href: "/my/auctions",
    },
    {
      label: t({
        id: "userMenu.links.auctionHistory",
        message: "Historie aukcí",
      }),
      href: "/my/history",
    },
    {
      label: t({
        id: "userMenu.links.watchdogs",
        message: "Hlídané položky",
      }),
      href: "/my/watchdogs",
      badge:
        artworkWatchdogsQuery.isSuccess && authorWatchdogsQuery.isSuccess
          ? pipe(
              artworkWatchdogsQuery.data.length +
                authorWatchdogsQuery.data.length,
              (watchdogCount) =>
                watchdogCount > 0
                  ? {
                      status: "success",
                      content: <Badge size={badgeSize}>{watchdogCount}</Badge>,
                    }
                  : undefined
            )
          : { status: "loading" },
    },
    {
      label: t({
        id: "userMenu.links.userDetails",
        message: "Uživatelské údaje",
      }),
      href: "/my/account",
      badge: meQuery.isSuccess
        ? !Verification.isPending(meQuery.data.customData.verification) &&
          !Verification.isApproved(meQuery.data.customData.verification)
          ? {
              status: "success",
              content: (
                <Tooltip content="Váš profil zatím není ověřený">
                  <Badge size={badgeSize}>!</Badge>
                </Tooltip>
              ),
            }
          : undefined
        : { status: "loading" },
    },
    {
      label: t({
        id: "userMenu.links.accountSettings",
        message: "Nastavení účtu",
      }),
      href: "/my/settings",
    },
  ]
}

function NavItem({ item, active }: { item: NavItem; active: boolean }) {
  return (
    <Link href={item.href} passHref>
      <Stack
        as="a"
        align="center"
        gap={2}
        css={{
          borderRight: "2px solid",
          mr: -1,
          zIndex: 10,
          borderColor: active ? "$accent9" : "transparent",
        }}
      >
        <Text
          size="lg"
          weight="medium"
          link
          color={active ? "accent" : "muted"}
        >
          {item.label}
        </Text>
        <AnimatePresence>
          {item.badge && item.badge.status === "success" && (
            <motion.span
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              {item.badge.content}
            </motion.span>
          )}
        </AnimatePresence>
      </Stack>
    </Link>
  )
}

function UserView() {
  const meQuery = useMeQuery()
  const logout = useLogout()

  return (
    <Box>
      <QueryView
        query={meQuery}
        loading={<Skeleton text css={{ height: 26, mb: 4, width: 180 }} />}
        success={(user) => (
          <Text as="p" size="xl" weight="bold">
            {fullNameOf(user)}
          </Text>
        )}
      />
      <Text
        link
        as="button"
        color="accent"
        variant="uppercase"
        weight="medium"
        size="xs"
        onClick={logout}
      >
        <Trans id="logout">Odhlásit se</Trans>
      </Text>
    </Box>
  )
}

export function Nav() {
  const router = useRouter()
  const navItems = useNavItems()

  const isActive = (item: NavItem) => router.asPath.includes(item.href)

  return (
    <Stack direction="column" gap={12}>
      <UserView />
      <Stack
        direction="column"
        gap={8}
        css={{
          display: "none",
          "@lg": { display: "flex" },
        }}
      >
        {navItems.map((item) => (
          <NavItem key={item.href} item={item} active={isActive(item)} />
        ))}
      </Stack>
    </Stack>
  )
}
