import * as PopoverPrimitive from "@radix-ui/react-popover"
import { ComponentProps } from "react"
import CloseLineIcon from "remixicon-react/CloseLineIcon"

import { keyframes, styled } from "@/stitches"

import { IconButton } from "./button"

// -----------------------------------------------------------------------------
// Primitives
// -----------------------------------------------------------------------------

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(1em)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
})

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-1em)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
})

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-1em)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
})

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(1em)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
})

const StyledContent = styled(PopoverPrimitive.Content, {
  bgColor: "$popoverBg",
  border: "1px solid $popoverBorder",
  boxShadow: "$shadow1",
  maxHeight: "100vw",
  zIndex: "$popover",

  "@animation": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    animationFillMode: "forwards",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },

  "&:focus-visible": {
    boxShadow: "$ring",
  },

  variants: {
    padding: {
      none: {},
      padded: {
        p: "$6",
      },
    },
    size: {
      auto: {},
      md: {
        width: "min(100vw, 16rem)",
      },
      lg: {
        width: "min(100vw, 20rem)",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
})

const StyledArrow = styled(PopoverPrimitive.Arrow, {
  fill: "$popoverBg",
  width: "1.5rem",
  height: "0.75rem",
  "& polygon": {
    transform: "translateY(-2px)",
  },
  "body:not(.dark-theme) & polygon": {
    filter: "drop-shadow(0px 2px 2px $colors$gray6)",
  },
  ".dark-theme & polygon": {
    filter: "drop-shadow(0px 2px 0px $colors$gray6)",
  },
})

const StyledClose = styled(PopoverPrimitive.Close, {
  position: "absolute",
  top: "$4",
  right: "$4",
})

// -----------------------------------------------------------------------------
// Composites
// -----------------------------------------------------------------------------

type PopoverContentProps = ComponentProps<typeof StyledContent> & {
  hideArrow?: boolean
  hideClose?: boolean
}

function PopoverContent({
  children,
  hideClose = false,
  hideArrow = false,
  ...props
}: PopoverContentProps) {
  return (
    <PopoverPrimitive.Portal>
      <StyledContent sideOffset={5} padding="padded" {...props}>
        {children}
        {!hideArrow && <StyledArrow />}
        {!hideClose && (
          <StyledClose aria-label="Close" asChild>
            <IconButton size="sm">
              <CloseLineIcon />
            </IconButton>
          </StyledClose>
        )}
      </StyledContent>
    </PopoverPrimitive.Portal>
  )
}

// -----------------------------------------------------------------------------
// Exports
// -----------------------------------------------------------------------------

/**
 * Anatomy:
 *
 * - Root
 *   - Trigger
 *   - Content | ContentBase
 */
export const Root = PopoverPrimitive.Root
export const Trigger = PopoverPrimitive.Trigger
export const Content = PopoverContent
export const ContentBase = StyledContent
