/**
 * Check icon, but thicker. Optimum for small sizes.
 */
export function CheckThickIcon({ size = "1em" }: { size?: number | string }) {
  return (
    <svg viewBox="0 0 24 24" width={size} height={size}>
      <path d="m10 15-4-4-2 2 6 6L21 8l-2-2-9 9Z" />
    </svg>
  )
}
