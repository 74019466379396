import { ord } from "fp-ts"
import { pipe } from "fp-ts/function"
import * as D from "io-ts/Decoder"

import { byName } from "@/domains/authors/model"
import { UploadedImageFile } from "@/domains/files/model"
import { Uuid } from "@/lib/uuid"

export const AuthorWatchdog = D.struct({
  id: Uuid,
  author: D.struct({
    id: Uuid,
    name: D.string,
    surname: D.string,
    images: D.array(D.struct({ file: UploadedImageFile })),
  }),
})

export interface AuthorWatchdog extends D.TypeOf<typeof AuthorWatchdog> {}

export type AuthorWatchdogDraft = {
  author: { id: Uuid }
}

export const isNotDraft = (
  item: AuthorWatchdog | AuthorWatchdogDraft
): item is AuthorWatchdog => "id" in item

export const byAuthorName = pipe(
  byName,
  ord.contramap((watchdog: AuthorWatchdog) => watchdog.author)
)
