import Link from "next/link"
import { ReactNode } from "react"

import { Text, TextProps } from "@/ui/text"

export function LinkOrDownload(props: {
  type: "link" | "doc"
  href: string
  children: ReactNode
  textProps?: TextProps
}) {
  if (props.type === "link") {
    return (
      <Link href={props.href} passHref>
        <Text as="a" {...props.textProps}>
          {props.children}
        </Text>
      </Link>
    )
  }

  return (
    <Text
      as="a"
      href={encodeURI(props.href)}
      target="_blank"
      {...props.textProps}
    >
      {props.children}
    </Text>
  )
}
