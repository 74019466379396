import React, { ReactNode } from "react"
import { QueryObserverResult } from "react-query"

interface QueryViewProps<TData, TError> {
  query: QueryObserverResult<TData, TError>
  idle?: ReactNode
  loading?: ReactNode
  success: (data: TData) => ReactNode
  failure?: (error: TError) => ReactNode
}

export function QueryView<TData, TError>({
  query,
  loading,
  success,
  failure,
}: QueryViewProps<TData, TError>) {
  const view = (() => {
    switch (query.status) {
      case "idle":
        return null
      case "loading":
        return loading ?? null
      case "success":
        const { data } = query
        return success(data)
      case "error":
        const { error } = query
        return failure?.(error)
    }
  })()

  return <>{view}</>
}
